import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Carview from "../../CarView";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { translateInTwoLanguages } from "../../../../Reducer/api";
import { addForm } from "../../../../data/content";
import { paths } from "../../../../utils/paths";
export default function SecondView({
  data,
  setPage,
  title,
  page,
  setData,
  backpage,
}) {
  const { t, i18n } = useTranslation();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  // const [modal, setModal] = useState(false);
  // const handleCloseModal = () => {
  //   setModal(false);
  // };

  const handleNavigate = (found) => {
    if (found) {
      setPage("FirstFormWithoutVin");
    } else {
      setData({});
      setPage("FirstFormWithoutVin");
    }
  };

  // const navigateToAddCar = async (route) => {
  //   navigate("/".concat(route), {
  //     state: {
  //       data: data,
  //       startpage: "FirstFormWithoutVin",
  //       backpage: "SecondChildVIn",
  //     },
  //   });
  // };
  console.log(data)
  return (
    <div className="container">
      <h6 class="back">
        <a
          onClick={() => {
            if (backpage !== "home") {
              setData({});
              setPage("FirstChildVIn");
            } else {
              navigate(paths.home);
            }
          }}
        >
          <img src="../assets/images/backbt.png" /> {t("Go back")}
        </a>
      </h6>
      <h6 class="mt-5">{t("We found your car!")}</h6>
      <div class="set">
        <Carview data={data} />
        <div className="row">
          <div
            class="col-lg-6 text-center justify-content-center"
            onClick={() => {
              handleNavigate(true);
            }}
          >
            <Button style="btn btn2" title={t("This is my car")} />
          </div>
          <div class="col-lg-6 d-flex justify-content-start">
            <Button
              style="btn"
              onClick={() => {
                handleNavigate(false);
              }}
              title={t("This is not my car, change details")}
            />
          </div>
        </div>
      </div>
      {/* <Modal isOpen={modal} toggle={handleCloseModal} centered>
        <ModalHeader>{t("Continue")}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-6">
              <button
                onClick={() => navigateToAddCar('SellYourCar')}
                className="btn btn-outline-primary w-100 m-1 p-2 "
              >
                {t("Sell your car")}
              </button>
            </div>
            <div className="col-lg-6">
              <button
                onClick={() => navigateToAddCar('RentYourCar')}
                className="btn btn-outline-primary w-100 m-1 p-2 "
              >
                {t("Rent your car")}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
    </div>
  );
}
