import React from "react";
import { useTranslation } from "react-i18next";
import SelectOptions from "../../Tools/SelctOptions";
import i18n from "../../../i18n";
export default function Perfomance({
  data,
  handleChange,
  allFilters,
  setAllFilters,
  showFilteredValues
}) {
  const { t } = useTranslation();

  return (
    <div class="set fuel1">
      <h4>{t("Perfomance")}</h4>
      <div class="row">
        <div class="col-sm-6">
          <label>{t(data?.min_max_engine?.min?.name?.[i18n.language])}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.size?.min || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                size: {
                  ...allFilters.size,
                  min: e.target.value,
                },
              })
            }
            name={data?.min_max_engine?.min?.name?.[i18n.language]}
            values={showFilteredValues?.("min_max_engine", data?.min_max_engine?.min?.data, allFilters.size.max, "min")}
          />
        </div>
        <div class="col-sm-6">
          <label>{t(data?.min_max_engine?.max?.name?.[i18n.language])}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.size?.max || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                size: {
                  ...allFilters.size,
                  max: e.target.value,
                },
              })
            }
            name={data?.min_max_engine?.max?.name?.[i18n.language]}
            values={showFilteredValues?.("min_max_engine", data?.min_max_engine?.max?.data, allFilters.size.min, "max")}
          />
        </div>

        <div class="col-sm-6">
          <label>
            {t(data?.min_max_engine_power?.min?.name?.[i18n.language])}
          </label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.power?.min || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                power: {
                  ...allFilters.power,
                  min: e.target.value,
                },
              })
            }
            name={data?.min_max_engine_power?.min?.name?.[i18n.language]}
            values={showFilteredValues?.('min_max_engine_power', data?.min_max_engine_power?.min?.data, allFilters.power.max, "min")}
          />
        </div>
        <div class="col-sm-6">
          <label>
            {t(data?.min_max_engine_power?.max?.name?.[i18n.language])}
          </label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.power?.max || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                power: {
                  ...allFilters.power,
                  max: e.target.value,
                },
              })
            }
            name={data?.min_max_engine_power?.max?.name?.[i18n.language]}
            values={showFilteredValues?.('min_max_engine_power', data?.min_max_engine_power?.max?.data, allFilters.power.min, "max")}
          />
        </div>

        <div class="col-sm-6">
          <label>{t(data?.cylinders?.min?.name?.[i18n.language])}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.cylinders?.min || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                cylinders: { ...allFilters.cylinders, min: e.target.value },
              })
            }
            name={data?.cylinders?.min?.name?.[i18n.language]}
            values={showFilteredValues?.('cylinders', data?.cylinders?.min?.data, allFilters?.cylinders?.max, "min")}
          />
        </div>
        <div class="col-sm-6">
          <label>{t(data?.cylinders?.max?.name?.[i18n.language])}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.cylinders?.max || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                cylinders: { ...allFilters.cylinders, max: e.target.value },
              })
            }
            name={data?.cylinders?.max?.name?.[i18n.language]}
            values={showFilteredValues?.('cylinders', data?.cylinders?.max?.data, allFilters?.cylinders?.min, "max")}
          />
        </div>
      </div>
    </div>
  );
}
