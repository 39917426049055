import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAdvanceSearchDataSet } from './api';

// export const fetchSearched = createAsyncThunk('searched/fetchSearched', async (formData, { rejectWithValue }) => {
//   try {
//     const searched = await fetchSearchedData(formData);
//     return searched;
//   } catch (error) {
//     return rejectWithValue(error.message);
//   }
// });

export const advamceSearchDataSet = createAsyncThunk('advance_search/fetchDataSet', async (_, { rejectWithValue }) => {
  try {
    const selectedSearch = await fetchAdvanceSearchDataSet();
    return selectedSearch;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const advanceSearchSlice = createSlice({
  name: 'advance_search',
  initialState: {
    dataSet: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(advamceSearchDataSet.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(advamceSearchDataSet.fulfilled, (state, action) => {
        state.dataSet = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(advamceSearchDataSet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default advanceSearchSlice.reducer;
