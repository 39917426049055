import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { convertDigitsToEnglish } from "../../../utils/constants";

export default function CustomPhoneInput({ name, setValue, getValues }) {
  const options = [
    {
      name: "iraq",
      image: "/assets/images/flags/iq.png",
      value: "964",
      label: "Iraq",
    },
    {
      name: "india",
      image: "/assets/images/flags/in.png",
      value: "91",
      label: "India",
    },
  ];
  useEffect(() => {
    if (!code) {
      setCode(options[0]);
    }
  }, []);
  const [code, setCode] = useState();
  const [inp, setInp] = useState("");
  useEffect(() => {
    if (getValues) {
      let val = getValues(name);
      options.forEach((item) => {
        if (val.startsWith(item.value)) {
          val = val.replace(item.value, "");
          setCode(item);
        }
      });
      setInp(val);
    }
  }, [getValues]);
  const formatOptionLabel = ({ label, image }) => (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      className="w-100"
    >
      <div
        style={{ margin: "0px 0px 0px 2px" }}
        className="d-flex align-items-center"
      >
        <img src={image} width={20} alt="" />{" "}
      </div>
    </div>
  );
  useEffect(() => {
    // let val = getValues(name);
    // if (!val.startsWith(code?.value)) {
    let num = convertDigitsToEnglish(inp);
    num = num.replace(/^0+/, "");
    setValue(name, code?.value + num);
    // }
  }, [code, inp]);
  if (!code) {
    return (
      <div>
        <input type="text" className="form-control customPhoneInput" />
      </div>
    );
  }
  return (
    <div style={{ position: "relative" }} dir="ltr">
      <span style={{ position: " absolute", height: "100%" }}>
        <ReactSelect
          // menuIsOpen
          defaultValue={code}
          formatOptionLabel={formatOptionLabel}
          placeholder={""}
          options={options}
          onChange={(e) => setCode(e)}
          styles={{
            container: (base) => ({
              ...base,
              height: "100%",
            }),
            menu: (base) => ({
              ...base,
              width: `max-content`,
              zIndex: 999,
            }),
            control: (base) => ({
              ...base,
              width: "45px",
              height: "100%",
              padding: "0px 0px 0px 0px !important",
            }),
          }}
          isSearchable={false}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          classNames={{
            control: (state) =>
              state.isFocused ? "form-control" : "form-control",
          }}
        />
      </span>
      {/* <span
        style={{ position: "absolute", left: "50px" }}
        className="h-100 d-flex align-items-center"
      >
        <span style={{ padding: "1.5px 0px 0px 0px" }}>{code}</span>
      </span> */}
      <input
        type="text"
        disabled
        style={{
          position: "absolute",
          width: "75px",
          left: "40px",
          border: "none",
          background: "transparent",
        }}
        value={`+${code?.value}`}
        className="form-control"
      />
      <input
        type="text"
        className="form-control customPhoneInput"
        placeholder=""
        value={inp}
        onChange={(e) => setInp(e.target.value)}
        size={10}
        // {...event(name)}
      />
    </div>
  );
}
