import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { camelToNormal, numWithZero } from "../../../utils/constants";
import moment from "moment";

export default function Details({ details }) {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const { t, i18n } = useTranslation();
  console.log(details);
  const tax = numWithZero(details?.pack?.price * 0.15);
  const total = numWithZero(parseFloat(details?.pack?.price) + parseFloat(tax));
  return (
    <div class="col-lg-12">
      <div class=" mb-3">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <td className="border-bottom-0">{t("Price")}</td>
                <td className="border-bottom-0">
                  <b>
                    $
                    {(
                      details?.pack?.price -
                      details?.pack?.price * 0.15
                    ).toFixed(2)}
                  </b>
                </td>
              </tr>
              <tr>
                {lang === "en" ? (
                  <td>{t("Tax") + " (15%) "}</td>
                ) : (
                  <td>{" (15%) " + t("Tax")}</td>
                )}
                <td>
                  {details?.pack?.price ? (
                    <b>${(details?.pack?.price * 0.15).toFixed(2)}</b>
                  ) : (
                    <b>--</b>
                  )}
                </td>
              </tr>
              <tr>
                <td>{t("TOTAL")}</td>
                <td className="tb-cl">
                  <b>
                    {details?.pack?.price ? (
                      <b>${details?.pack?.price}</b>
                    ) : (
                      <b>--</b>
                    )}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p className="d-flex justify-content-between align-items-center">
            <span className="fw-semibold ">
              {t("Plan duration")}: {details?.pack?.duration} {t("days")}
            </span>
            <span className="" style={{ fontSize: "15px" }}>
              {t("Expires on")}:{" "}
              {details?.pack?.duration == -1
                ? t("until sold")
                : moment(new Date())
                    .add(details?.pack?.duration, "days")
                    .format("DD/MM/YY")}
            </span>
            {/* {details?.pack?.duration !== "until sold" ? (
              <span className="" style={{ fontSize: "15px" }}>
                {t("Expires on")}:{" "}
                {moment(new Date())
                  .add(details?.pack?.duration, "days")
                  .format("DD/MM/YY")}
              </span>
            ) : null} */}
          </p>
          <span>{t("purchase_helper_text")}</span>
        </div>
      </div>
    </div>
  );
}
