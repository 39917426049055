import React from 'react'
import TermsAndConditions from '../../MyComponents/TermsAndConditions'

export default function TermsAndCondition() {
  return (
    <div className='container my-5'>
        <TermsAndConditions />
    </div>
  )
}
