import { combineReducers } from '@reduxjs/toolkit';
import brandsReducer from './Reducer/brandsSlice';
import searchedReducer from './Reducer/searchedSlice';
import homeArticleReducer from './Reducer/homeArticleSlice';
import recentReducer from './Reducer/recentSlice';
import authReducer from './Reducer/authSlice';
import detailReducer from './Reducer/detailSlice';
import formReducer from './Reducer/formSlice';
import chaiceReducer from './Reducer/chaiceSlice';
import saveReducer from './Reducer/saveCarSlice';
import uploadFormReducer from './Reducer/uploadFormSlice';
import withoutVinReducer from './Reducer/withoutVinSlice';
import saveCarWithouVinReducer from './Reducer/saveCarWithouVinSlice';
import  newBrandsReducer  from './Reducer/newBrandsSlice';
import plansReducer from './Reducer/planSlice';
import paymentReducer from './Reducer/paymentSlice';
import savedReducer from './Reducer/savedSlice';
import newcar from './Reducer/newcarSlice';
import electricCarSlice from './Reducer/electricCarSlice';
import singleCarSlice from './Reducer/singleCarSlice';
import rentalCarSlice from './Reducer/rentalCarSlice';
import ConteactUsSilce from './Reducer/ConteactUsSilce';
import myvehicleSlice from './Reducer/myvehicleSlice';
import myprofileSlice from './Reducer/myprofileSlice';
import transactionsSlice from './Reducer/transactionsSlice';
import messageSlice from './Reducer/messageSlice';
import perfomanceSlice from './Reducer/perfomanceSlice';
import advanceSearchSlice from './Reducer/advanceSearchSlice';
import searchFilterSlice from './Reducer/searchFilterSlice';
import rentalSerchSlice from './Reducer/rentalSerchSlice';
import dealerRental from './Reducer/dealerRental';
import searchDealerPageSlice from './Reducer/searchDealerPageSlice';
import dealerFilterSilce from './Reducer/dealerFilterSilce';
import cookeesSlice from './Reducer/cookeesSlice';
const rootReducer = combineReducers({
    brands:brandsReducer,
    searched:searchedReducer,
    home_article:homeArticleReducer,
    recent:recentReducer,
    auth:authReducer,
    detail:detailReducer,
    fromsdata:formReducer,
    chacie:chaiceReducer,
    saveCar:saveReducer,
    upload_list:uploadFormReducer,
    withoutVin:withoutVinReducer,
    saveCarWithoutVin:saveCarWithouVinReducer,
    new_brands:newBrandsReducer,
    plans:plansReducer,
    payment:paymentReducer,
    saved:savedReducer,
    newcar:newcar,
    electric_car:electricCarSlice,
    singleCar:singleCarSlice,
    rentalCar:rentalCarSlice,
    contactus:ConteactUsSilce,
    myvehicle:myvehicleSlice,
    myprofile:myprofileSlice,
    transaction:transactionsSlice,
    mychat:messageSlice,
    performance:perfomanceSlice,
    advance_search:advanceSearchSlice,
    searching:searchFilterSlice,
    rental_search:rentalSerchSlice,
    dealer_rental:dealerRental,
    dealer:searchDealerPageSlice,
    dealersList:dealerFilterSilce,
    cookees:cookeesSlice
});

export default rootReducer;