import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMyvehicle,fetchEditVehicle} from './api';

export const vehicleMy = createAsyncThunk('detail/detailsForm', async (data, { rejectWithValue }) => {
  try {
    const response = await fetchMyvehicle(data);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const EditVehicledataFetch = createAsyncThunk('detail/fetcheditdata', async (formData, { rejectWithValue }) => {
  try {
    const response = await fetchEditVehicle(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const myvehicle = createSlice({
  name: 'myvehicle',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    myvehicleArr:[],
    editdata:[],
  },
  reducers: {},
  extraReducers: builder => {
    builder
    // detail myvehicle
      .addCase(vehicleMy.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(vehicleMy.fulfilled, (state,action) => {
        state.submitting = false;
        state.myvehicleArr = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(vehicleMy.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })


       // edit detail
       .addCase(EditVehicledataFetch.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(EditVehicledataFetch.fulfilled, (state,action) => {
        state.submitting = false;
        state.editdata = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(EditVehicledataFetch.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
   
  },
});
export default myvehicle.reducer;
