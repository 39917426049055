import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchmyprofileinfo } from "./api";

export const myprofilefetch = createAsyncThunk(
  "detail/fetchprofiledata",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchmyprofileinfo(rejectWithValue, formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const myprofile = createSlice({
  name: "myprofile",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    profilearr: [],
  },
  reducers: {
    clearStatus: (state) => {
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // profile data
      .addCase(myprofilefetch.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(myprofilefetch.fulfilled, (state, action) => {
        state.submitting = false;
        state.profilearr = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(myprofilefetch.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export const { clearStatus } = myprofile.actions;
export default myprofile.reducer;

