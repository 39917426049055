import React, { useEffect,useState } from 'react';
import { addForm } from '../../../../data/content';
import FirstView from '../../Forms/FirstView';
import UploadForm from '../../Forms/UploadForm';
import AddCar from '../../Forms/AddCar';
import Advert from '../../Advert';
import SecondView from '../../Forms/SecondView';
import UploadImages from '../../UploadImages';


export default function FirstStep({data,onClick,page,title, reset}) {
  
  const [firstVin, setpageVin] = useState(data.startpage);
  const [carDetail, setCarDetail] = useState(data.data);

  useEffect(() => {
    if(reset) {
      setpageVin?.('FirstChildVIn')
      setCarDetail({})
    }
  }, [reset])

  const mypage=()=>{
    switch(firstVin) {
      
      case 'FirstChildVIn':
        return <FirstView setPage={setpageVin} setData={setCarDetail} page={page} title={title} />
      case 'SecondChildVIn':
        return <SecondView backpage={data.backpage} setPage={setpageVin} setData={setCarDetail} data={carDetail} page={page} title={title}/>
      case 'UploadDetail':
        return <UploadForm back={'SecondChildVIn'} setPage={setpageVin} setData={setCarDetail} data={carDetail} page={page} />
      case 'uploadImages':
        return <UploadImages back={'FirstFormAdUploaded'} next={'WithoutAdvert'} setPage={setpageVin} setData={setCarDetail} data={carDetail} page={page} />

      case 'FirstFormWithoutVin':
        return <AddCar back={'FirstChildVIn'} setPage={setpageVin} next={'uploadImages'} setData={setCarDetail} data={carDetail} page={page} />
      case 'FirstFormAdUploaded':
        return <AddCar adUploaded back={'FirstChildVIn'} setPage={setpageVin} next={'uploadImages'} setData={setCarDetail} data={carDetail} page={page} />

      case 'WithAdvert':
        return <Advert back={'uploadImages'} edit={'EditWith'} next={'WithAdvert'} setPage={setpageVin} data={carDetail} onClick={onClick} page={page} />
      case 'WithoutAdvert':
        return <Advert back={'uploadImages'} edit={'EditWithout'} next={'WithAdvert'} setPage={setpageVin} data={carDetail} onClick={onClick} page={page} />
      case 'EditWith':
        return <UploadForm back={'WithAdvert'} edit={'EditWithout'} next={'WithAdvert'} setPage={setpageVin} setData={setCarDetail} data={carDetail} page={page}/>
      case 'EditWithout':
        return <AddCar back={'WithoutAdvert'} edit={'EditWithout'} next={'uploadImages'} setPage={setpageVin} setData={setCarDetail} data={carDetail} page={page} />
    }
  }
  
  return (
    <>{mypage()}</>
  );
}
