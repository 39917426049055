import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { sideBarNormal, sideBarRent } from "./content";
import { useDispatch, useSelector } from "react-redux";
import SortBy from "../../Tools/SortBy";
import Car from "./cars";
import Rental from "./rental";
import FilterSideBar from "../../Tools/FilterSideBar";
import { fetchDefaultDRFilterData } from "../../../Reducer/dealerRental";
import $ from "jquery";
import sortBy from "../../../data/sortBy.json";
import ReactSelectCustom from "../../Tools/ReactSelectCustom";
import { getModels } from "../../../Reducer/api";
import { itemLimit, setCourierFilters } from "../../../utils/constants";
import { useDidMountEffect } from "../../../utils/hooks";

export default function DealerRental({ page }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const sidebar = useSelector((state) => state.dealer_rental.sideBar);
  const cars = useSelector((state) => state.dealer_rental.cars);
  const rental = useSelector((state) => state.dealer_rental.rental);
  const loading = useSelector((state) => state.dealer_rental.loading);
  const error = useSelector((state) => state.dealer_rental.error);
  // const [currentPage, setCurrentPage] = useState(0);
  const sideBarRental = sideBarRent;
  const sideBarBuy = sideBarNormal;
  const [selectedContent, setSelectedContent] = useState(page);
  const [params] = useSearchParams();
  const ownerId = params.get("ownerId");
  const pageCount = cars?.totalPage || rental?.totalPage;
  const [sortVal, setSortVal] = useState({
    sort: "",
    order: "",
  });
  const defaultValues = {
    city: [],
    make: "",
    model: "",
    price: {
      min: "",
      max: "",
      finance: false,
    },
    rentType: "daily",
    sort: "",
    colour: [],
  };
  const [allFilters, setAllFilters] = useState(defaultValues);
  const filtersRef = useRef(allFilters);

  const currentPage = parseInt(params.get("page")) || 1;
  const setCurrentPage = (n) => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set("page", n);
    navigate(`${location.pathname}?${currentParams.toString()}`, {
      replace: true,
    });
  };

  //pagination page change (page number wise)
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  //pagination previous buttonpp
  const previous = () => {
    let page = currentPage;
    if (page > 1) setCurrentPage(page - 1);
  };

  //pagination next button
  const next = () => {
    let page = currentPage;
    if (page < pageCount) setCurrentPage(page + 1);
  };

  // // pagination page update content (page number wise)
  // useDidMountEffect(() => {
  //   if(currentPage !== 1) setCurrentPage(1);
  // }, [allFilters]);

  useEffect(() => {
    dispatch(
      fetchDefaultDRFilterData({
        page: selectedContent,
        currentPage: currentPage,
        ownerId: ownerId,
        filters: allFilters,
        limit: itemLimit,
      })
    );
  }, [currentPage, selectedContent, allFilters]);

  useEffect(() => {
    setAllFilters({
      ...allFilters,
      sort: sortVal.sort.concat(sortVal.order ? "," + sortVal.order : ""),
    });
  }, [sortVal]);

  const handleChange = (e) => {};

  const handleClick = (a, b, c) => {
    $(".switch-button-case").removeClass("left");
    $("#" + b).addClass("left");
  };

  // useEffect(() => {
  //   setCourierFilters(allFilters, "dealerAds")
  // }, [allFilters]);
  const [models, setModels] = useState(null);
  useEffect(() => {
    console.log(allFilters.make);
    // if (allFilters.make) {
    setModels([]);
    getModels({
      makeId: allFilters.make,
      live: true,
      ownerId: ownerId,
      page: selectedContent,
      filters: allFilters,
    }).then((res) => {
      if (res) {
        res = res?.map((v) => ({ label: v, value: v }));
        res = [{ label: "All", value: "All" }, ...res];
        setModels(res);
      } else {
        setModels([]);
      }
    });
    // }
  }, [allFilters, selectedContent]);

  const resetFilters = () => {
    setAllFilters(defaultValues);
  };

  return (
    <section class="advance_search car_search_results ">
      <div class="container">
        <h6 className="back back2">
          <a onClick={() => navigate(-1)}>
            <img src="/assets/images/backbt.png" /> {t("Go back")}
          </a>
        </h6>
        <div class="advance_search_in">
          <h4 className="d-sm-none d-flex align-items-center justify-content-between">
            <span
              class="d-flex flex-end align-items-center"
              style={{ fontSize: "16px" }}
            >
              <img src="assets/images/SortBy.png" height={50} alt="img" />
              <ReactSelectCustom
                values={sortBy.sortData}
                width={"180px"}
                // boxWidth={'140px'}
                placeholder={""}
                selectedOption={t("Relevance")}
                onChange={(e) =>
                  setSortVal({
                    sort: e.item.sort,
                    order: e.item.order,
                  })
                }
              />
            </span>
            <span
              class="filter_ic"
              style={{ cursor: "pointer" }}
              onClick={() => $(".sidebar").addClass("main")}
            >
              <img src="assets/images/filter_ic.png" />
              {t("Filters")}
            </span>
          </h4>
          {Object.keys(sidebar).length > 0 ? (
            <FilterSideBar
              dataset={
                selectedContent === "normal" ? sideBarNormal : sideBarRent
              }
              data={sidebar}
              handleClickevent={handleClick}
              handleChange={handleChange}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              page={page}
              resetFilters={resetFilters}
              models={models}
              ownerId={ownerId}
              selectedContent={selectedContent}
              filtersRef={filtersRef}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            ""
          )}
          {/* <h6 class="SortBy">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="Cars-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Cars-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="Cars-tab-pane"
                  aria-selected="true"
                >
                  {t("Cars")}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="Rentals-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Rentals-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="Rentals-tab-pane"
                  aria-selected="false"
                >
                  {t("Rentals")}
                </button>
              </li>
            </ul>
          </h6> */}

          <SortBy
            data={sortBy.sortData}
            selectFilter={handleChange}
            ownerId={ownerId}
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
            previous={previous}
            next={next}
            sortVal={sortVal}
            setSortVal={setSortVal}
          />
          {loading ? (
            <div
              class="d-flex justify-content-center"
              style={{ minHeight: "100vh", marginTop: "25vh" }}
            >
              <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div class="tab-content  min-vh-100" id="myTabContent">
              <Car
                data={cars.data}
                page={selectedContent}
                pageCount={cars.totalPage}
                currentPage={currentPage}
                handlePageClick={handlePageClick}
                allFilters={allFilters}
                allAdIds={cars.allAdIds}
              />
              <Rental
                data={rental.data}
                page={selectedContent}
                pageCount={rental.totalPage}
                currentPage={currentPage}
                handlePageClick={handlePageClick}
                allFilters={allFilters}
                allAdIds={rental.allAdIds}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
