import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveCarFormSubmit } from './api';


export const saveCarForm = createAsyncThunk('saveCar/saveCarForm', async (formData, { rejectWithValue }) => {
  try {
    const response = await saveCarFormSubmit(formData, rejectWithValue);
    if(response.status) {
      return response;
    }
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


const saveCarSlice = createSlice({
  name: 'saveCar',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    saveCar:{},
  },
  reducers: {
    clearStatus: state => {
      state.success=false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(saveCarForm.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(saveCarForm.fulfilled, (state,action) => {
        state.submitting = false;
        state.saveCar = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(saveCarForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
  },
});
export const { clearStatus } = saveCarSlice.actions;
export default saveCarSlice.reducer;
