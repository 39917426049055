import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDealerList } from './api';

export const dealerList= createAsyncThunk('details/dealerList', async (formData, { rejectWithValue }) => {
  try {
    const data = await fetchDealerList(formData);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const dealersList = createSlice({
  name: 'dealersList',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(dealerList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(dealerList.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(dealerList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dealersList.reducer;
