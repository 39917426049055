import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStats } from "../../../../../Reducer/api";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function TobBar({ refresh, setFilter }) {
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState();
  const [data, setData] = useState({});

  useEffect(() => {
    getStats().then((res) => {
      setData(res);
    });
  }, []);

  const changeSection = (val) => {
    if (val === active) {
      setActive("");
      setFilter({});
      return;
    }
    setActive(val);
    setFilter({ [`${val}`]: true });
  };

  return (
    <div style={{ color: "#4C7EE8" }} className="row px-2 stats">
      <div
        className={`col-6 col-lg-3 px-2 px-lg-0 mb-3 text-${
          i18n.language === "English" ? "start" : "end"
        }`}
      >
        <div
          style={{
            borderRadius:
              i18n.language === "English"
                ? "8px 0px 0px 8px"
                : "0px 8px 8px 0px",
            backgroundColor: active === "live" ? "#D1E0FF" : "",
          }}
        >
          <div
            style={{
              [`border${i18n.language === "English" ? "Right" : "Left"}`]:
                "2px solid #ebebeb",
              cursor: "pointer",
            }}
            onClick={() => {
              changeSection("live");
            }}
          >
            <span className="fs-4 fw-semibold">
              {data?.liveAds || data?.liveAds == 0 ? data?.liveAds : <Icon icon="eos-icons:three-dots-loading" style={{color: '#4c7ee8'}} />}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#0C0C0C",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {t("Live Adverts")}
            </span>
          </div>
        </div>
      </div>

      <div
        className={`col-6 col-lg-3 px-2 px-lg-0 mb-3 text-${
          i18n.language === "English" ? "start" : "end"
        }`}
      >
        <div
          style={{ backgroundColor: active === "soonExpire" ? "#D1E0FF" : "" }}
        >
          <div
            style={{
              [`border${i18n.language === "English" ? "Right" : "Left"}`]:
                "2px solid #ebebeb",
              cursor: "pointer",
            }}
            onClick={() => {
              changeSection("soonExpire");
            }}
          >
            <span className="fs-4 fw-semibold">
              {data?.expiringSoonAds || data?.expiringSoonAds == 0
                ? data?.expiringSoonAds
                : <Icon icon="eos-icons:three-dots-loading" style={{color: '#4c7ee8'}} />}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#0C0C0C",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {t("Soon to Expire")}
            </span>
          </div>
        </div>
      </div>

      <div
        className={`col-6 col-lg-3 px-2 px-lg-0 mb-3 text-${
          i18n.language === "English" ? "start" : "end"
        }`}
      >
        <div style={{ backgroundColor: active === "expired" ? "#D1E0FF" : "" }}>
          <div
            style={{
              [`border${i18n.language === "English" ? "Right" : "Left"}`]:
                "2px solid #ebebeb",
              cursor: "pointer",
            }}
            onClick={() => {
              changeSection("expired");
            }}
          >
            <span className="fs-4 fw-semibold">
              {data?.expiredAds || data?.expiredAds == 0
                ? data?.expiredAds
                : <Icon icon="eos-icons:three-dots-loading" style={{color: '#4c7ee8'}} />}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#0C0C0C",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {t("Expired")}
            </span>
          </div>
        </div>
      </div>

      <div
        className={`col-6 col-lg-3 px-2 px-lg-0 mb-3 text-${
          i18n.language === "English" ? "start" : "end"
        }`}
      >
        <div
          style={{
            borderRadius:
              i18n.language === "English"
                ? "0px 8px 8px 0px"
                : "8px 0px 0px 8px",
            backgroundColor: active === "sold" ? "#D1E0FF" : "",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeSection("sold");
            }}
          >
            <span className="fs-4 fw-semibold">
              {data?.soldAds || data?.soldAds == 0 ? data?.soldAds : <Icon icon="eos-icons:three-dots-loading" style={{color: '#4c7ee8'}} />}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#0C0C0C",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {t("Sold")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
