import React, { useEffect, useState } from "react";
import ListItem from "../ListItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { paths } from "../../../utils/paths";
export default function FindCar({ element, title, step }) {
  const { t, i18n } = useTranslation();
  const udata = useSelector((state) => state.myprofile.profilearr);
  const navigate = useNavigate();
  const fetchList = () => {
    let val = "";
    switch (step) {
      case 1:
        val = (
          <>
            <ListItem
              style={"active"}
              innerhtmlType="html"
              value={
                <>
                  <span>1</span>
                  {t("Details")}
                </>
              }
            />

            <ListItem
              style={""}
              innerhtmlType="html"
              value={
                <>
                  <span>2</span>
                  {t("Ad plan")}
                </>
              }
            />

            <ListItem
              style={""}
              innerhtmlType="html"
              value={
                <>
                  <span>3</span>
                  {t("Payment")}
                </>
              }
            />
          </>
        );

        break;
      case 2:
        val = (
          <>
            <ListItem
              style={"done"}
              innerhtmlType="html"
              value={
                <>
                  <span>
                    <img src="assets/images/ckd.png" alt="arrow" />
                  </span>
                  {t("Details")}
                </>
              }
            />
            <ListItem
              style={"active"}
              innerhtmlType="html"
              value={
                <>
                  <span>2</span>
                  {t("Ad plan")}
                </>
              }
            />
            <ListItem
              style={""}
              innerhtmlType="html"
              value={
                <>
                  <span>3</span>
                  {t("Payment")}
                </>
              }
            />
          </>
        );
        break;
      case 3:
        val = (
          <>
            <ListItem
              style={"done"}
              innerhtmlType="html"
              value={
                <>
                  <span>
                    <img src="assets/images/ckd.png" alt="arrow" />
                  </span>
                  {t("Details")}
                </>
              }
            />
            <ListItem
              style={"done"}
              innerhtmlType="html"
              value={
                <>
                  <span>
                    <img src="assets/images/ckd.png" alt="arrow" />
                  </span>
                  {t("Ad plan")}
                </>
              }
            />
            <ListItem
              style={"active"}
              innerhtmlType="html"
              value={
                <>
                  <span>3</span>
                  {t("Payment")}
                </>
              }
            />
          </>
        );
        break;
      case 4:
        val = (
          <>
            <ListItem
              style={"done"}
              innerhtmlType="html"
              value={
                <>
                  <span>
                    <img src="assets/images/ckd.png" alt="arrow" />
                  </span>
                  {t("Details")}
                </>
              }
            />
            <ListItem
              style={"done"}
              innerhtmlType="html"
              value={
                <>
                  <span>
                    <img src="assets/images/ckd.png" alt="arrow" />
                  </span>
                  {t("Ad plan")}
                </>
              }
            />
            <ListItem
              style={"done"}
              innerhtmlType="html"
              value={
                <>
                  <span>
                    <img src="assets/images/ckd.png" alt="arrow" />
                  </span>
                  {t("Payment")}
                </>
              }
            />
          </>
        );
        break;
    }
    return val;
  };
  return (
    <section class="sell_your">
      <div class="container">
        <div class="incell position-relative">
          {!udata?.phoneNumberVerified ? (
            <h5 className="d-flex justify-content-center w-100">
              <span className=" text-danger rounded-2 fs-6 p-2  bg-light d-flex justify-content-center flex-column">
                {t("verify phone to post ad")}{" "}
                <a
                  dir="ltr"
                  onClick={() => navigate(paths.myProfile)}
                  className={`mt-1 ${
                    i18n.language === "English" ? "text-start" : "text-end"
                  }`}
                >
                  {i18n.language !== "English" ? (
                    <span className="mx-1">{"<"}</span>
                  ) : null}
                  <span>{t("Verify now")}</span>
                  {i18n.language == "English" ? (
                    <span className="mx-1">{">"}</span>
                  ) : null}
                </a>
              </span>
            </h5>
          ) : (["dealer", "brand"].includes(udata?.userType) && !udata?.kyc?.en?.includes("verified")) ? (
            <h5 className="d-flex justify-content-center w-100">
              <span className=" text-danger rounded-2 fs-6 p-2  bg-light d-flex justify-content-center flex-column">
                {t("verify kyc to post ad")}{" "}
                <a
                  dir="ltr"
                  onClick={() => navigate(paths.myProfile)}
                  className={`mt-1 ${
                    i18n.language === "English" ? "text-start" : "text-end"
                  }`}
                >
                  {i18n.language !== "English" ? (
                    <span className="mx-1">{"<"}</span>
                  ) : null}
                  <span>{t("Verify now")}</span>
                  {i18n.language == "English" ? (
                    <span className="mx-1">{">"}</span>
                  ) : null}
                </a>
              </span>
            </h5>
          ) : null}
          <h2>{t(title)}</h2>
          <ul class="stap">{fetchList()}</ul>
          {element}
        </div>
      </div>
    </section>
  );
}
