import React, { useEffect, useState } from "react";

const NumberInput = ({ left, style, placeholder, event, name, value }) => {
  const [inputFile, setInput] = useState(value);

  return (
    <>
      {/* {left == true && (
        <span class="minus">
          <img
          height={18}
            src="../assets/images/minus-circle.png"
            onClick={() => {
              inputFile > 1 && setInput((prevActiveStep) => prevActiveStep - 1);
            }}
            alt="icon"
          />
        </span>
      )} */}
      <input
        class={style}
        // value={inputFile}
        type="text"
        {...event(name)}
        // onChange={(e) => {
        //   // if (!isNaN(e.target.value)) 
        //   setInput(e.target.value);
        // }}
        // style={{paddingLeft: '0px'}}
        placeholder={placeholder}
      />
      {/* <span
        onClick={() => {
          setInput((prevActiveStep) => prevActiveStep + 1);
        }}
      >
        <img height={18} src="../assets/images/plus-circle.png" alt="icon" />
      </span> */}
    </>
  );
};
export default NumberInput;
