import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTransactioneDetails } from './api';

export const transactionsDetails = createAsyncThunk('detail/fetchtranaction', async (formData, { rejectWithValue }) => {
  try {
    const response = await fetchTransactioneDetails(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const transaction = createSlice({
  name: 'transaction',
  initialState: {
    submitting: true,
    error: null,
    success: false,
    transactionArr:[],
  },
  reducers: {},
  extraReducers: builder => {
    builder
    // transactions data 
      .addCase(transactionsDetails.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(transactionsDetails.fulfilled, (state,action) => {
        console.log(action.payload)
        state.submitting = false;
        state.transactionArr = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(transactionsDetails.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      }) 
  },
});
export default transaction.reducer;
