import React from "react";
import RecentlyCarView from "../../../Tools/RecentCarView";
import { t } from "i18next";

export default function Recent({ rtl }) {
  return (
    <>
      <RecentlyCarView rtl={rtl} title={"Recently viewed car deals"} />
    </>
  );
}


