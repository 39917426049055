import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSearchedData, fetchBrandByPostData } from './api';

export const fetchSearched = createAsyncThunk('searched/fetchSearched', async (formData, { rejectWithValue }) => {
  try {
    const searched = await fetchSearchedData(formData, rejectWithValue);
    return searched;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchPostByBrand = createAsyncThunk('searched/fetchPostByBrand', async (formData, { rejectWithValue }) => {
  try {
    const selectedSearch = await fetchBrandByPostData(formData);
    return selectedSearch;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const searchedSlice = createSlice({
  name: 'searched',
  initialState: {
    searched: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSearched.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSearched.fulfilled, (state, action) => {
        state.searched = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSearched.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPostByBrand.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPostByBrand.fulfilled, (state, action) => {
        // state.searched.default_active = action.payload;
        state.searched = action.payload;
        state.loading = false;
        state.error = null;
      }).addCase(fetchPostByBrand.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default searchedSlice.reducer;
