import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchNewBrandsData,fetchpopularModalDetail} from './api';

export const fetchNewBrands= createAsyncThunk('new_brands/fetchNewBrands', async (formData, { rejectWithValue }) => {
  try {
    const searched = await fetchNewBrandsData(formData);
    return searched;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchpopularModal= createAsyncThunk('new_brands/fetchpopularModal', async (formData, { rejectWithValue }) => {
  try {
    const searched = await fetchpopularModalDetail(formData);
    return searched;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const newBrandsSlice = createSlice({
  name: 'new_brands',
  initialState: {
    new_brands: [],
    popularmodal: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
    // new brand section
      .addCase(fetchNewBrands.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNewBrands.fulfilled, (state, action) => {
        state.new_brands = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchNewBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // popoular modal
      .addCase(fetchpopularModal.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchpopularModal.fulfilled, (state, action) => {
        state.popularmodal = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchpopularModal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

      
  },
});

export default newBrandsSlice.reducer;
