import React, { useContext, useEffect, useState } from "react";
import Crousel from "../../Tools/Crousel";
import { useTranslation } from "react-i18next";
import ListItem from "../../Tools/ListItem";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtherCar from "./otherCar";
import CarDetailModel from "../../Tools/CarDetailModel";
import {
  fetchFilteredSearchResults,
  fetchSingleCar,
  initiateChat,
  likeDislikePost,
  userActivity,
} from "../../../Reducer/api";
import { fetchSaved } from "../../../Reducer/savedSlice";
import {
  beginWithImage,
  findUploads,
  formatPrice,
  getCourierFilters,
  getNameFromVal,
  isPositiveWholeNumber,
  itemLimit,
  numberWithCommas,
  userActivityLocal,
} from "../../../utils/constants";
import { Button, Modal, ModalBody } from "reactstrap";
import EnquiryForm from "../Enquiry/EnquiryForm";
import SinglePageRight from "./singlePageRight";
import { apiManager } from "../../../api/connectAPI";
import moment from "moment";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react/dist/iconify.js";
import { paths } from "../../../utils/paths";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
import adFormData from "../../../data/withoutVin.json";
import CompareComponent from "./compareComponent";

export default function SingleCar({ page }) {
  const [params] = useSearchParams();
  const adId = params.get("adId");
  const sold = params.get("sold");

  const { t, i18n } = useTranslation();
  const udata = useSelector((state) => state.auth);
  const profileData = useSelector((state) => state.myprofile.profilearr);
  const location = useLocation();
  const adsArray = JSON.parse(localStorage.getItem("adsArray"))?.adsArray || [];
  const posInArray = adsArray?.indexOf(adId);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [pageloading, setPageLoading] = useState(true);
  const error = useSelector((state) => state.singleCar.error);
  console.log(posInArray);
  useEffect(() => {
    const adIdToChat = localStorage.getItem("adIdToChat");
    if (adIdToChat) {
      const tmp = localStorage.getItem("chatInitiated");
      if (tmp == "true") {
        localStorage.setItem("chatInitiated", "false");
        localStorage.setItem("adIdToChat", "");
        handleInitiateChat(adIdToChat);
      }
    }
  }, [localStorage]);

  useEffect(() => {
    setPageLoading(true);
    // userActivityLocal(adId)
    // dispatch(fetchCarDetails(form));
    userActivity({ adId: adId, type: "view" });
    if (localStorage.getItem("token")) {
      dispatch(fetchSaved());
    }
    window.scrollTo(0, 0);
    fetchSingleCar({
      id: adId,
      page: page,
      sold: sold,
    }).then((res) => {
      let currAd = res?.data?.ads?.[0];
      setData(currAd);
      const title =
        t(currAd?.properties?.basicDetails?.make?.[lang]) +
        " " +
        t(currAd?.properties?.basicDetails?.model?.[lang]);
      document.title = title;
      setPageLoading(false);
    });
  }, [dispatch, params.get("adId")]);

  const savedCars = useSelector((state) => state.saved.saved)?.ads?.map(
    (v) => v._id
  );

  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const navigate = useNavigate();
  const [day, setDay] = useState(page);
  const [dealerCars, setDealerCars] = useState([]);

  const handleChange = (e) => {
    setRadio(e.target.value);
    setDay(e.target.value);
  };

  const [all_images, set_all_images] = useState([]);
  useEffect(() => {
    if (data) {
      const ad = data;
      let all_list = findUploads(ad);
      set_all_images(all_list);
    }
  }, [data]);

  const getDealerCars = () => {
    setDealerCars(null);
    fetchFilteredSearchResults({
      page: page,
      limit: itemLimit,
      currentPage: 0,
      brandId: null,
      ownerId: data?.owner?._id,
    }).then((res) => {
      const withoutCurrent = res?.data?.filter((v) => v._id !== adId);
      setDealerCars({
        data: withoutCurrent,
        adsArray: res?.allAdIds,
      });
    });
  };

  useEffect(() => {
    if (data?.owner?._id) {
      getDealerCars();
    }
  }, [params.get("adId"), data]);

  const getPrice = () => {
    switch (day) {
      case "normal":
        return `${formatPrice(lang, data?.askPrice, data?.dollorAskPrice)}`;
      case "daily":
        return `${formatPrice(lang, data?.askPrice, data?.dollorAskPrice)}/${t(
          "Daily"
        )}`;
      case "weekly":
        return `${formatPrice(
          lang,
          data?.askPrice1,
          data?.dollorAskPrice1
        )}/${t("Weekly")}`;
      case "monthly":
        return `${formatPrice(
          lang,
          data?.askPrice2,
          data?.dollorAskPrice2
        )}/${t("Monthly")}`;
      case "rental":
        return `${formatPrice(lang, data?.askPrice, data?.dollorAskPrice)}/${t(
          "Daily"
        )}`;
    }
  };

  const [radioState, setRadio] = useState("daily");

  const [readMore, setReadMore] = useState(false);

  const [enquireModal, setEnquireModal] = useState(false);
  const handleCloseEnquireModal = () => {
    setEnquireModal(false);
  };

  const [chatNow, setChatNow] = useState({
    loading: false,
    adId: "",
  });
  const [chatNowPop, setChatNowPop] = useState(false);
  const handleInitiateChat = (id) => {
    if (chatNow.loading) return;
    if (!udata.token) {
      navigate(paths.login, {
        state: {
          backPage: location.pathname + location.search,
          request: "chat",
          adIdToChat: id,
        },
      });
      return;
    }

    if (!profileData?.isProfileCompleted || !profileData?.phoneNumberVerified) {
      setChatNowPop(true);
      setTimeout(() => {
        setChatNowPop(false);
      }, 2000);
      return;
    }

    setChatNow({ ...chatNow, adId: id, loading: true });
    initiateChat(id)
      .then((res) => {
        if (res.status) {
          navigate(`${paths.messages}?id=${res.chatId}`);
        }
        setChatNow({ ...chatNow, loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStartEnquiry = () => {
    if (!udata.token) {
      setEnquireModal(true);
      return;
    }
    handleEnquireLoggedIn();
  };
  const [enquireLoading, setEnquireLoading] = useState(false);

  const handleEnquireLoggedIn = () => {
    setEnquireLoading(true);
    const formData = {
      adId: adId,
      messageMetadata: {
        type: "enquiry",
        enquiryType: "test drive",
        enquiryDate: moment(new Date()).format("YYYY-MM-DD"),
      },
      enquiry: false,
      fullName: udata?.auth?.fullName,
      email: udata?.auth?.email,
      mobile: udata?.auth?.phoneNumber,
      message: "",
    };
    // console.log(formData);

    apiManager("POST", `chat/init?lang=${lang}`, formData)
      .then((res) => {
        if (res.status) {
          navigate(paths.messages, {
            state: { chatId: res.chatId },
          });
        } else {
          console.log(res);
          toast.error(res.message);
        }
        setEnquireLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        setEnquireLoading(false);
      });
  };

  const displayData = {
    size:
      data?.properties?.specifications?.engineSize ||
      data?.properties?.specifications?.batterySize ||
      "",
    sizeUnit: data?.properties?.specifications?.engineSize
      ? t("Litre")
      : t("kWh"),
    fuel: data?.properties?.specifications?.fuelType?.[lang] || "",
    bodyType: data?.properties?.specifications?.bodyType?.[lang] || "",
    km:
      data?.properties?.specifications?.km ||
      data?.properties?.specifications?.km == 0
        ? data?.properties?.specifications?.km
        : "",
    gearbox: data?.properties?.specifications?.gearbox?.[lang] || "",
    doors: data?.properties?.specifications?.doors,
    seats: data?.properties?.specifications?.seats,
    userType: data?.owner?.userType,
    adLabel: data?.properties?.adLabel,
  };

  const moveAd = (dir) => {
    if (
      pageloading ||
      (dir === "prev" && posInArray === 0) ||
      (dir === "next" && posInArray === adsArray?.length - 1)
    ) {
      return;
    }
    const goToAd = (id) => {
      const currentParams = new URLSearchParams(location.search);
      currentParams.set("adId", id);
      navigate(`${location.pathname}?${currentParams.toString()}`, {
        replace: true,
      });
    };
    if (dir === "prev") {
      goToAd(adsArray[posInArray - 1]);
    } else {
      goToAd(adsArray[posInArray + 1]);
    }
  };

  return (
    <>
      {data && Object.keys(data).length > 0 ? (
        <section class="singlepage">
          <div class="container">
            <h6 class="back mb-3">
              <a onClick={() => navigate(-1)}>
                <img src="../assets/images/backbt.png" /> {t("Go back")}
              </a>
            </h6>

            {adsArray?.length > 1 ? (
              <h6 className="d-flex justify-content-between align-items-center mb-3">
                <button
                  onClick={(e) => {
                    moveAd("prev");
                  }}
                  disabled={posInArray === 0}
                  role="button"
                  className="btn btn-light border-0 shadow-sm rounded-pill d-flex align-items-center gap-2"
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    color: "#334660",
                    width: "fit-content",
                    padding: "3px 8px",
                  }}
                >
                  <Icon
                    fontSize={16}
                    icon={`gg:arrow-${lang === "en" ? "left" : "right"}-o`}
                    style={{ color: "#334660" }}
                  />
                  <span>{t("Previous")}</span>
                  <div></div>
                </button>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    color: "#334660",
                  }}
                >{`${posInArray + 1} / ${adsArray?.length}`}</span>
                <button
                  onClick={(e) => {
                    moveAd("next");
                  }}
                  disabled={posInArray === adsArray?.length - 1}
                  role="button"
                  className="btn btn-light border-0 shadow-sm rounded-pill d-flex align-items-center gap-2"
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    color: "#334660",
                    width: "fit-content",
                    padding: "3px 8px",
                  }}
                >
                  <div></div>
                  <span>{t("Next")}</span>
                  <Icon
                    fontSize={16}
                    icon={`gg:arrow-${lang !== "en" ? "left" : "right"}-o`}
                    style={{ color: "#334660" }}
                  />
                </button>
              </h6>
            ) : null}

            {pageloading ? (
              <div
                class="d-flex justify-content-center"
                style={{ minHeight: "100vh", marginTop: "25vh" }}
              >
                <div class="spinner-grow" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col-lg-6 col-xxl-7">
                  <div class="singlepage_left" style={{ position: "relative" }}>
                    <p
                      className="owl-item-badge"
                      style={{
                        zIndex: 9,
                        top: "0px",
                        [lang === "en" ? "left" : "right"]: "0px",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          borderRadius:
                            lang !== "en"
                              ? "0px 8px 0px 8px"
                              : "8px 0px 8px 0px",
                          fontSize: "11px",
                          backgroundColor: "#334660",
                        }}
                        class=" text-light float-start px-2"
                      >
                        {displayData?.userType === "user"
                          ? t("Private ad")
                          : ""}
                        {displayData?.userType === "brand" &&
                        displayData?.adLabel
                          ? t(displayData?.adLabel)
                          : ""}
                      </span>
                    </p>
                    <Crousel data={all_images ? all_images : []} />
                    <SinglePageRight
                      classNames={"singlepage_right d-lg-none d-block"}
                      page={page}
                      data={data}
                      radioState={radioState}
                      handleChange={handleChange}
                      getPrice={getPrice}
                      lang={lang}
                      params={params}
                      handleInitiateChat={handleInitiateChat}
                      chatNow={chatNow}
                      setChatNow={setChatNow}
                      chatNowPop={chatNowPop}
                    />

                    <h5
                      style={{ position: "unset" }}
                      className="d-flex gap-lg-4 gap-xl-0 justify-content-between align-items-xl-center flex-column-reverse flex-xl-row"
                    >
                      <div className="">{t("Car Details")}</div>
                      <CompareComponent
                        id={"web-view"}
                        data={data}
                        className={"d-none d-lg-flex justify-content-end "}
                      />
                    </h5>
                    <h6>
                      <img src="../assets/images/adt.png" align="icon" />{" "}
                      {`${numberWithCommas(displayData?.km || 0)} ${t("KMs")}`}
                    </h6>
                    <ul class="hatchback">
                      {displayData?.bodyType && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil1.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.bodyType)}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.fuel && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil5.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.fuel)}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.size && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil2.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {displayData?.size +
                                  " " +
                                  displayData?.sizeUnit}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.gearbox && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil3.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.gearbox)}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.doors && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil4.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.doors)} {t("Doors")}
                              </span>
                            </>
                          }
                        />
                      )}
                      {displayData?.seats && (
                        <ListItem
                          key={0}
                          innerhtmlType={"html"}
                          value={
                            <>
                              <img
                                src={"../assets/images/mil6.png"}
                                align="icon"
                              />
                              <span
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {t(displayData?.seats)} {t("Seats")}
                              </span>
                            </>
                          }
                        />
                      )}
                    </ul>
                    {data?.properties?.features?.carDescription?.[lang]?.charAt(
                      0
                    ) !== "["}
                    {data?.properties?.features?.carDescription?.[lang] &&
                    data?.properties?.features?.carDescription?.[lang]?.charAt(
                      0
                    ) !== "[" ? (
                      <>
                        <h5 class="mb-3">{t("Description")}</h5>
                        <p
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: readMore ? 100 : 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            // idth: "350px",
                          }}
                        >
                          {data?.properties?.features?.carDescription?.[lang]
                            ? data?.properties?.features?.carDescription?.[lang]
                            : "--"}
                        </p>
                        {data?.properties?.features?.carDescription?.[lang]
                          ?.length > 190 && (
                          <a
                            className="mb-3"
                            onClick={() => {
                              setReadMore(!readMore);
                            }}
                          >
                            {readMore ? t("See less") : t("See more")}
                          </a>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <h5
                      class="spe"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      {t("Specifications")}{" "}
                      <img src="../assets/images/aron.png" align="aro" />
                    </h5>
                  </div>
                </div>
                <div class="col-lg-6 col-xxl-5">
                  <SinglePageRight
                    classNames={"singlepage_right d-lg-block d-none"}
                    page={page}
                    data={data}
                    radioState={radioState}
                    handleChange={handleChange}
                    getPrice={getPrice}
                    lang={lang}
                    params={params}
                    handleInitiateChat={handleInitiateChat}
                    chatNow={chatNow}
                    setChatNow={setChatNow}
                    chatNowPop={chatNowPop}
                  />

                  {data?.owner?._id !== udata.auth.id && (
                    <div
                      className="singlepage_right"
                      style={{ marginTop: "-20px" }}
                    >
                      <div class="inset">
                        <h5>
                          {t(
                            `Want to ${
                              page == "normal" ? "buy" : "rent"
                            } this car?`
                          )}
                        </h5>
                        <p>
                          {t("Total Price")} <span>{getPrice()}</span>
                        </p>
                        <button
                          style={{ border: "none" }}
                          disabled={enquireLoading}
                          class="Enquire_Now"
                          onClick={() => handleStartEnquiry()}
                        >
                          {enquireLoading
                            ? t("Please wait...")
                            : t("Enquire Now")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      ) : (
        <div
          class="d-flex justify-content-center"
          style={{ minHeight: "100vh", marginTop: "25vh" }}
        >
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {data?.properties && (
        <CarDetailModel
          data={data?.properties ? data?.properties : []}
          title={t("Specifications")}
        />
      )}
      {dealerCars?.data?.length > 0 ? (
        <OtherCar page={page} dealerCars={dealerCars} carData={data} />
      ) : null}
      <Modal
        isOpen={enquireModal}
        toggle={handleCloseEnquireModal}
        size="lg"
        centered
      >
        <ModalBody>
          <a
            className="float-end p-2"
            onClick={() => handleCloseEnquireModal()}
          >
            <img src="../assets/images/close_img.png" alt="icon" height={22} />
          </a>
          <div>
            <section class="login enquiry" style={{ padding: "unset" }}>
              <div class="container">
                <EnquiryForm
                  adData={data}
                  adId={adId}
                  handleCloseEnquireModal={handleCloseEnquireModal}
                />
              </div>
            </section>
          </div>
        </ModalBody>
      </Modal>

      <JsonLDSchemaHandler
        data={page == "normal" ? ldSchemas?.singleCar : ldSchemas?.singleRental}
      />
    </>
  );
}
