import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { removeDealerAd } from "../../../Reducer/api";

export default function RemoveModal({ handleCloseModal, modalData, refresh }) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  console.log(modalData?.makeName);
  const [loading, setLoading] = useState(false)
  const removeData = async (id) => {
    setLoading(true)
    const res = await removeDealerAd(id);
    refresh();
    setLoading(false)
    handleCloseModal()
  };
  return (
    <div>
      <form class="mb-2 p-2">
        <div class="d-flex justify-content-between p-2 mb-3">
          <h5
            style={{ color: "#343434" }}
            class="modal-title"
            id="exampleModalLabel"
          >
            {t("Remove listing")}
          </h5>
          <a
            type="button"
            class="stclose"
            onClick={() => handleCloseModal()}
            // data-bs-dismiss="modal"
            // aria-label="Close"
            style={{ backgroundColor: "transparent" }}
          >
            <img src="../assets/images/close_img.png" alt="img" height={18} />{" "}
          </a>
        </div>
        <div class="modal-body pt-0">
          <p>
            {t("Remove")} {t(modalData?.properties?.basicDetails?.make?.[lang])}{" "}
            {t(modalData?.properties?.basicDetails?.model?.[lang])}
            {", "}
            {t("This will be a permanent action which can’t be undone!")}.
          </p>
        </div>
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-secondary ms-2"
            // data-bs-dismiss="modal"
            onClick={() => handleCloseModal()}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            class="btn btn-primary remove px-4 py-2 ms-2"
            onClick={() => removeData(modalData?._id)}
            data-bs-dismiss="modal"
            disabled={loading}
            style={{
              backgroundColor: "#EA1933",
              border: "none",
              borderRadius: "5px",
            }}
          >
            {loading ? t('Please wait...') : t("Remove listing")}
          </button>
        </div>
      </form>
    </div>
  );
}
