import { Icon } from "@iconify/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";
export default function ReactSelectCustom({
  values,
  placeholder,
  name,
  onChange,
  selectedOption,
  width,
  boxWidth,
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  let options = [];

  if (values) {
    options = values.map((v) => {
      const name = v.name?.[language] || v.name?.[lang] || v.name;
      return { label: t(name), value: v.value, item: v };
    });
  }
  // console.log([selectedOption?.map(v => ({label: v, value: v}))])
  const customStyles = {
    container: (base) => ({
      ...base,
      width: '150px'
    }),
    control: (base) => ({
      ...base,
      width: boxWidth,
      // border: "unset",
    }),
    menu: (base) => ({
      ...base,
      width: width,
    }),
  };
  const formatOptionLabel = ({label, item}) => {
    let l = t(label)
    if(label === t('Name') && item?.order === 'desc') {
      l = t(label) + " ↓"
    }
    if(label === t('Name') && item?.order === '') {
      l = t(label) + " ↑"
    }
    return (
      <span className="text-start w-100">
        {l}
      </span>
    )
  }
  return (
    <div>
      <Select
        defaultValue={{ label: t(selectedOption), value: selectedOption }}
        isSearchable={false}
        name="colors"
        formatOptionLabel={formatOptionLabel}
        options={options}
        // placeholder={placeholder}
        onChange={onChange}
        className="basic-multi-select"
        classNamePrefix="selectMulti "
        styles={customStyles}
        // menuPosition="fixed"
        // menuPlacement="auto"
        // classNames={{
        //   control: (state) =>
        //     state.isFocused ? "form-control" : "form-control",
        // }}
        // menuIsOpen
      />
    </div>
  );
}
