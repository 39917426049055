import React from 'react'
import PrivacyPolicies from '../../MyComponents/PrivacyPolicies'

export default function Policy() {
  return (
    <div>
    <PrivacyPolicies />
    </div>
  )
}
