import React from "react";
import { useTranslation } from "react-i18next";
import { title } from "../../data/content";
import { Privacy } from "../Pages/MyAccount/content";
import { getSiteContent } from "../../Reducer/api";
import { useState } from "react";
import { useEffect } from "react";
import { marked } from "marked";
import { useSelector } from "react-redux";
import { ldSchemas } from "../../utils/json-schemas";
import JsonLDSchemaHandler from "../Tools/JSONLDSchemaUtil";

export default function PrivacyPolicies() {
  const language = useSelector((state) => state.auth.language);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  //   const Privacys = Privacy;

  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [data, setData] = useState(null);

  const getData = async () => {
    setLoading(true);

    await getSiteContent("privacyPolicy").then((res) => {
      res.json().then((r) => {
        console.log(r);
        setData(r?.data?.[0]?.attributes);
      });
    });

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false,
  });

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div class="spinner-grow" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div class=" staticConditions container py-5 min-vh-100">
        <p
          dangerouslySetInnerHTML={{
            __html: marked.parse(data ? data?.[lang] : t("Please wait...")),
          }}
        ></p>

        {/* {data.map((item) => {
          return (
            <div className="container">
              <h4>{item.title[lang]}</h4>
              <p className="text-center">{item.description[lang]}</p>
            </div>
          );
        })} */}
      </div>


      <JsonLDSchemaHandler data={ldSchemas?.policies} />
    </div>
  );
}
