import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makePayment } from './api';

export const make_payment = createAsyncThunk('payment/make_payment', async (formData, { rejectWithValue }) => {
  try {
    const response = await makePayment(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    payment:{},
  },
  reducers: {
    clearStatus: state => {
      state.success=false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(make_payment.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(make_payment.fulfilled, (state,action) => {
        state.submitting = false;
        state.payment = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(make_payment.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
  },
});
export const { clearStatus } = paymentSlice.actions;
export default paymentSlice.reducer;
