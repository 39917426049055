import React, { useState } from "react";
import Chatview from "../../../../Tools/Chart";
import { object } from "yup";
import { t } from "i18next";

export default function AppexChart({ data }) {
  console.log("data", data);
  // const categories =
  //   data.options && data.options.xaxis ? data.options.xaxis.categories : "";
  // const series1 = data.series && data.series ? data.series[0].data : [];
  var data1 = {
    options: {
      chart: {
        // id: "basic-bar",
        toolbar: {
          show: false,
          tools: {
            selection: false,
            pan: false,
            zoom: false,
            download: false
          },
        },
      },
      xaxis: {
        categories: Object.keys(data),
      },
    },
    series: [
      {
        name: t('Car Views'),
        data: Object.values(data),
      },
    ],
  };
  const options = data1.options;
  const series = data1.series;
  console.log(data);
  return (
    <Chatview options={options} series={series} type="line" width="1000" />
  );
}
