import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../Tools/Button";
import ListItem from "../../Tools/ListItem";
import SignupForm from "./SignupForm";
import { title } from "../../../data/content";
import { paths } from "../../../utils/paths";

export default function Signup() {
  const [selectedOption, setSelectedOption] = useState("phone");

  useEffect(() => {
    localStorage.setItem("type", selectedOption);
  }, [selectedOption]);

  const { t, i18n } = useTranslation();

  

  return (
    <section class="login">
      <div class="container">
        <div class="form-signin">
          <NavLink className="logo d-none" to={paths.home}>
            <img src={`assets/images/${t("MainIcon")}`} />
          </NavLink>
          <h1>{t("Sign Up")}</h1>
          {/* <div class="row scl">
          <div class="col-4">
          <Button type="button" style="ic" title={<span><img src="assets/images/google.png" alt="icon"/> Google</span>}></Button>
          </div>
          <div class="col-4">
          <Button type="button" style="ic" title={<span><img src="assets/images/facebook.png" alt="icon"/>Facebook</span>}></Button>
          </div>
          <div class="col-4">
          <Button type="button" style="ic" title={<span><img src="assets/images/apple.png" alt="icon"/> Apple</span>}></Button>
          </div>
        </div>
        <h5><span>{t('Or')}</span></h5> */}
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <ListItem
              innerhtmlType={"button"}
              key={1}
              btnType={"button"}
              onClick={() => setSelectedOption("phone")}
              style={"nav-item"}
              buttonStyle={
                selectedOption == "phone" ? "nav-link active" : "nav-link"
              }
              buttonTitle={t("With Phone")}
            ></ListItem>
            <ListItem
              innerhtmlType={"button"}
              key={0}
              btnType={"button"}
              onClick={() => setSelectedOption("email")}
              style={"nav-item"}
              buttonStyle={
                selectedOption == "email" ? "nav-link active" : "nav-link"
              }
              buttonTitle={t("With Email")}
            ></ListItem>
          </ul>

          <div class="tab-content" id="myTabContent">
            <SignupForm type={selectedOption} />
          </div>
          <p>
            {t("Already having an account?")}{" "}
            <NavLink to={paths.login}>{t("Login")}</NavLink>
          </p>
          <p>
            <div className="w-100" style={{ fontSize: "12px" }}>
              {" "}{t("By clicking sign up, you agree to the")}{" "}
              <a href={paths.conditions} target="_blank" className="text-primary">
                {t("Tearms and Conditions")}
              </a>
              {" " + t("and confirm you have read our") + " "}{" "}
              <a href="/policies" target="_blank" className="text-primary">
                {t("Privacy policies")}
              </a>
            </div>
          </p>
        </div>
      </div>
    </section>
  );
}
