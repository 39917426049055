import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveCarWithoutVinFormSubmit } from './api';

export const uploadWithoutVinForm = createAsyncThunk('saveCarWithoutVin/saveCarWithoutVinFormSubmit', async (data, { rejectWithValue }) => {
  try {
    const response = await saveCarWithoutVinFormSubmit(data.formData, data.add, data.adId, data?.entityId, data?.setLoading, rejectWithValue);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


const saveCarWithouVinSlice = createSlice({
  name: 'saveCarWithoutVin',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    saveCarWithoutVin:{},
  },
  reducers: {
    clearStatus: state => {
        state.success=false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(uploadWithoutVinForm.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(uploadWithoutVinForm.fulfilled, (state,action) => {
        state.submitting = false;
        state.saveCarWithoutVin = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(uploadWithoutVinForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
  },
});
export const { clearStatus } = saveCarWithouVinSlice.actions;
export default saveCarWithouVinSlice.reducer;
