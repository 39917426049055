import React from "react";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

export default function CompleteStep({
  page,
  titlePage1,
  titlePage2,
  page2,
  h4tag,
  ptag,
  setReset,
  setStep
}) {
  const navigate = useNavigate();
  return (
    <div class="comp">
      <h4>{h4tag}</h4>
      <p>{ptag}</p>
      <div class="row">
        <div class="col-6 pe-1">
          <Button
            style="btn"
            onClick={() => {
              setStep(1)
              setReset(true)
            }}
            title={titlePage1}
          />
        </div>
        <div class="col-6 ps-1">
          <Button
            style="btn"
            onClick={() => navigate(`/${page2}`)}
            title={titlePage2}
          />
        </div>
      </div>
    </div>
  );
}
