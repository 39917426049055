import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
const Meta = ({content}) => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <meta name="description" content={content} />
    </Helmet>
  );
};
export default Meta;