import { Icon } from "@iconify/react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";
export default function SelectMultiple({
  values,
  placeholder,
  name,
  onChange,
  showDropdownIcon,
  selectedOption,
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  let options = [];

  if (values) {
    options = values.map((v) => {
      const name = v.name?.[language] || v.name?.[lang] || v.name;
      return { label: t(name), value: name };
    });
  }
  if(placeholder === t('National')) {
    options.unshift({label: t('National'), value: ''})
  }
  // console.log([selectedOption?.map(v => ({label: v, value: v}))])
  const customStyles = {
    menu: (base) => ({
      ...base,
      width: `100% !important`,
    }),
  };

  const selectInputRef = useRef();
  useEffect(() => {
    if (!selectedOption || selectedOption.length == 0) {
      selectInputRef?.current?.clearValue();
    }
  }, [selectedOption]);

  return (
    <div>
      <Select
        ref={selectInputRef}
        // defaultValue={selectedOption?.map((v) => ({ label: t(v), value: v }))}
        defaultValue={{label: t(selectedOption?.[0]), value: selectedOption?.[0]}}
        // value={{ label: t('Akre'), value: 'Akre' }}
        isSearchable={false}
        // isMulti
        // menuIsOpen
        name="colors"
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        className="basic-multi-select"
        classNamePrefix="selectMulti "
        styles={customStyles}
        // menuIsOpen
      />
    </div>
  );
}
