import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useTranslation } from "react-i18next";
import Adverts from "./Adverts";
import Searche from "./Searche";
import Recent from "./Recent";
import Button from "../../Tools/Button";
import { title } from "../../../data/content";

export default function Saved() {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  return (
    <section className="recently_viewed saved_car">
      <div className="container">
        <Tabs>
          <TabList className={"nav nav-tabs mb-4"}>
            <Tab>
              <Button title={t("Adverts")} />
            </Tab>
            {/* <Tab><Button title={t('Searches')}/></Tab> */}
            <Tab>
              <Button title={t("Recent")} />
            </Tab>
          </TabList>
          <TabPanel id="Adverts">
            <Adverts rtl={rtl} />
          </TabPanel>
          {/* <TabPanel id="Searches">
            <Searche rtl={rtl} />
          </TabPanel> */}
          <TabPanel id="Recent">
            <Recent rtl={rtl} />
          </TabPanel>
        </Tabs>
      </div>
    </section>
  );
}
