import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "./Banner";
import { title } from "../../../data/content";
import Rentaldeals from "./PopularModal";
// import PlaceSection from "../../Tools/PlaceSection/PlaceSection";
import SearchRentalType from "./SearchRentalType";
import ArticlesSection from "../../Tools/ArticleBlog/ArticlesSection";
import PlaceSection from "../Home/PlaceSection";
import Follow from "../Home/Follow";
import RecentlyCarView from "../../Tools/RecentCarView";
import { getEntityTypes } from "../../../Reducer/api";
import { useState } from "react";
import { useEffect } from "react";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
export default function RentalCar({ page }) {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState(null);
  const getCarTypes = async () => {
    const res = await getEntityTypes();
    setCarTypes(res?.data);
    // setSelectedCarType(res?.data?.[0]);
  };

  useEffect(() => {
    getCarTypes();
  }, []);
  
  return (
    <>
      <Banner page={page} carTypes={carTypes} />
      <Rentaldeals rtl={rtl} title1={t("Rental deals")} page={page} />
      <PlaceSection page={page} />
      <SearchRentalType rtl={rtl} page={page} />
      <ArticlesSection rtl={rtl} page={page} />
      <RecentlyCarView
        rtl={rtl}
        page={page}
        title={"Recently viewed rentals"}
      />
      <Follow />

      <JsonLDSchemaHandler data={ldSchemas?.rentPerfectCars} />
    </>
  );
}
