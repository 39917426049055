import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Personal from "./Personal";
import Dealer from "./Dealer";
import { title } from "../../../data/content";
import VerifyWithOtp from "../VerifyWithOtp";
import { sendWelcomeMail } from "../../../Reducer/api";
import { paths } from "../../../utils/paths";
import Brand from "./Brand";

export default function BasicDetail() {
  const [userType, setUserType] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem("userType");
    setUserType(user);
  }, [userType]);

  //   const [selectedOption, setSelectedOption] = useState('email');
  const { t, i18n } = useTranslation();
  const proceed = (type) => {
    switch (userType) {
      case "user":
        navigate(paths.home);
        break;
      case "dealer":
        navigate(paths.dealershipDocument);
        break;
      case "brand":
        navigate(paths.dealershipDocument);
        break;
      default:
        navigate(paths.home);
    }
  };

  return (
    <section class="login ">
      <div class="container">
        <div class="advance_search newt mt-0">
          <h6 class="back text-start mt-0"></h6>
        </div>
        <div class="form-signin signin2">
          <h1>{t("Basic Details")}</h1>
          {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
            <ListItem key={1} style={"nav-item"} innerhtmlType={'modalButton'} innerData={{"class":"nav-link","id":"Personal-tab","toggle":"tab","target":"#Personal","type":"button","role":"tab","controls":"Personal","selected":"false","title":t('Personal')}}/>
            <ListItem key={2} style={"nav-item"} innerhtmlType={'modalButton'} innerData={{"class":"nav-link active","id":"Dealer-tab","toggle":"tab","target":"#Dealer","type":"button","role":"tab","controls":"Dealer","selected":"true","title":t('Dealer')}}/>
            </ul> */}
          <div class="tab-content" id="myTabContent">
            <Dealer
              sx={userType === "dealer" ? "active show" : ""}
              proceed={proceed}
            />
            <Personal
              sx={userType === "user" ? "active show" : ""}
              proceed={proceed}
            />
            <Brand
              sx={userType === "brand" ? "active show" : ""}
              proceed={proceed}
            />
            {/* {console.log(userType === "User")}
            {userType === "User" ? <Personal /> : null}

            {userType === "Dealer" ? <Dealer /> : null} */}
          </div>
        </div>
      </div>
    </section>
  );
}
