import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import HomeArticle from "../../../data/homeArticle.json";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { title } from "../../../data/content";
import { useSelector } from "react-redux";
import ShareIcons from "./shareIcons";

export default function SingleArticle({ data, rawData }) {
  const navigate = useNavigate()
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const { t, i18n } = useTranslation();
  const params = useParams();

  const filter = HomeArticle.data.filter((item) => item.id === params.id);
  const arr = filter[0];
  var dt = new Date(arr.timeago * 1000);
  var momentDate = moment(dt);
  var formattedDate = momentDate.format("Do MMM YYYY");
  document.title = title.Blog[i18n.language] + "" + arr.title[i18n.language];
  console.log(rawData?.data?.attributes?.name?.[lang]);

  if (!rawData) {
    return (
      <div
        class="d-flex justify-content-center"
        style={{ minHeight: "100vh", marginTop: "25vh" }}
      >
        <div class="spinner-grow" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  
  return (
    <div>
      {arr ? (
        <section class="blog_page">
          <div class="container">
            <h6 class="back">
              <NavLink onClick={e => navigate(-1)}>
                <img src="../assets/images/backbt.png" /> {t("Go back")}
              </NavLink>
            </h6>
            <div class="set" style={{ maxWidth: "unset !important" }}>
              {/* <h6>
                {t("PUBLISHED ON ")} {formattedDate}
              </h6>
              <h2>{arr.title[i18n.language]}</h2>
              <p style={{ color: "#334660" }}>
                {arr.description[i18n.language]}{" "}
              </p>
              <div class="text-center">
                <img
                  class="img1"
                  src={`../assets/images/${arr.image}`}
                  alt="img"
                />
              </div> */}
              <h2 className="articleName">
                {rawData?.data?.attributes?.name?.[lang]}
              </h2>
              <div className="d-flex justify-content-center w-100 pt-4">
                <img
                  className="articleBanner"
                  src={rawData?.data?.attributes?.additionalData?.bannerImage}
                  alt=""
                />
              </div>
              <span className="pt-2 fs-6">
                {t("By")}{" "}
                {rawData?.data?.attributes?.additionalData?.by?.[lang]},{" "}
                {rawData?.data?.attributes?.additionalData?.date}
              </span>
              <div className="row mt-5">
                <ShareIcons
                  className="col-1 d-none d-lg-flex flex-column"
                  type={"v"}
                />
                <ShareIcons
                  className="d-flex d-lg-none justify-content-around"
                  type={"h"}
                />
                <div className="col-12 col-sm-11">
                  <div
                    className={`min-vh-100 articleImages text-center text-sm-${
                      lang === "en" ? "start" : "end"
                    }`}
                  >
                    <p
                      style={{ maxWidth: "unset", marginInline: "0px" }}
                      dangerouslySetInnerHTML={{
                        __html: data,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            {/* {
              <div
                class="set"
                dangerouslySetInnerHTML={{ __html: arr.content }}
              />
            } */}
          </div>
        </section>
      ) : (
        ""
      )}
    </div>
  );
}
