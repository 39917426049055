import React, { useEffect, useState } from "react";
import Chat from "./Chat";
import ChatUser from "./ChatUser";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { messageFun } from "../../../../../Reducer/messageSlice";
import { title } from "../../../../../data/content";
import { useLocation, useSearchParams } from "react-router-dom";
import $ from "jquery";
import { sideBarList } from "../../content";
import { Icon } from "@iconify/react/dist/iconify.js";
import { markChatIdAsRead } from "../../../../../Reducer/api";
import { myprofilefetch } from "../../../../../Reducer/myprofileSlice";

export default function Messages() {
  const [params] = useSearchParams();
  const data = useSelector((state) => state.mychat.chats);
  const submitting = useSelector((state) => state.mychat.submitting);
  const success = useSelector((state) => state.mychat.success);
  const [classs, setdata] = useState("main");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedChat, setSelectedChat] = useState("");
  const [selectedChatData, setSelectedChatData] = useState({});

  useEffect(() => {
    if (success && data) {
      const chats = data?.chats?.docs;
      if (!selectedChat) {
        const id = params.get("id");
        if (id) {
          setSelectedChat(id);
          setSelectedChatData(chats?.filter((v) => v?._id === id)?.[0]);
        } else if (data) {
          setSelectedChat(chats?.[0]?._id);
          setSelectedChatData(chats?.[0]);
        }
      }
    }
  }, [success, data]);

  useEffect(() => {
    dispatch(messageFun());
  }, [dispatch]);

  const setClass = (m) => {
    if (classs === "main") {
      $(".sidebar").addClass("main");
    } else {
      setdata(m);
    }
  };

  const markRead = () => {
    markChatIdAsRead(selectedChat).then((res) => {
      if (res?.status) {
        dispatch(myprofilefetch());
        dispatch(messageFun());
      }
    });
  };

  useEffect(() => {
    if (selectedChat) {
      const currEl = document.getElementById(`chat_item_${selectedChat}`);
      console.log(currEl, "scroll view element")
      setTimeout(() => {
        currEl?.scrollIntoView({
          block: "nearest",
          inline: "nearest",
          behavior: "smooth",
        });
      }, 450);
      markRead();
      const intervalId = setInterval(function () {
        markRead();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [selectedChat]);

  const [loading, setLoading] = useState(false);
  if (submitting && !selectedChat) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div class="spinner-grow" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <section class="chats close_amountmain ">
        {data?.chats?.docs?.length > 0 ? (
          <div class="container">
            <h6
              onClick={() => setClass("main")}
              role="button"
              className="w-75 d-flex align-items-center d-block d-md-none"
              style={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#0c0c0c",
                zIndex: 99,
                marginTop: "-56px",
                background: "white",
                // height: "60px",
              }}
            >
              {classs === "main" ? (
                t("Chats")
              ) : (
                <span className="d-flex align-items-center">
                  <Icon
                    icon="ic:round-arrow-back-ios"
                    style={{
                      color: "#2b2b2b",
                      transform:
                        i18n.language === "English"
                          ? "unset"
                          : `rotate(180deg)`,
                    }}
                  />
                  {t("Back to list")}
                </span>
              )}
            </h6>

            <div class="main_user test mt-4">
              <ChatUser
                data={data?.chats?.docs}
                classs={classs}
                setdata={setdata}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
                setLoading={setLoading}
                setSelectedChatData={setSelectedChatData}
              />
              <Chat
                data={
                  data?.conversations?.filter((v) => v.id == selectedChat)?.[0]
                }
                selectedChat={selectedChat}
                loading={loading}
                setLoading={setLoading}
                selectedChatData={selectedChatData}
              />
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <h5 style={{ fontWeight: "500" }} className="text-center">
              {t("No users to chat")}
            </h5>
          </div>
        )}
      </section>
    </div>
  );
}
