import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import ListItem from "../../../../Tools/ListItem";
import {
  formatTime24to12,
  removeAmPm,
  staticWorkingHourData,
} from "../../../../../utils/constants";
import { paths } from "../../../../../utils/paths";
export default function WorkingHour({ data }) {
  const info = useSelector((state) => state.auth.auth);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const workingHours =
    data?.additionalInfo?.dealerWorkingHours || staticWorkingHourData;

  const handleClicks = () => {
    //  alert('nav')
    navigate(paths.editWorkHour, {
      state: { data: data?.additionalInfo, id: info.id },
    });
  };

  const formatValue = (time) => {
    const a = time.substring(time.length - 2);
    let res = "";
    if (a.toLowerCase() == "am") {
      res = t("AM");
    } else if (a.toLowerCase() == "pm") {
      res = t("PM");
    } else {
      return time;
    }
    if (!res) {
      return time;
    }
    return time.substring(0, time.length - 2).concat(" " + res);
  };

  const formatTimeRTL = (time) => {
    const tmp = formatValue(formatTime24to12(time));
    console.log(tmp);
    return tmp;
  };

  return (
    <div>
      <h3>
        {t("Working hours")}
        <a class="edt" onClick={handleClicks}>
          <img src="../assets/images/pencil.png" alt="pencil" />{" "}
          {t("Edit hours")}
        </a>
      </h3>
      <div class="row text-center">
        {/* { data.working_hour && data.working_hour.map((item,key)=>{
                return(<div class="col-sm-3 coleh"> mx-1
                  <div class="stp w-100">
                    <img src="../assets/images/check-circle0.png" alt="img"/>
                    <h6>{item.day ? item.day[i18n.language] : ""}</h6>
                    <h6>{item.time ? item.time : ""}</h6>
                 </div>
              </div>)
              })} */}
        {workingHours.map((item) => {
          return (
            <div
              className="stp mx-2 col-lg-2 col-xs-12"
              style={{ width: "175px" }}
            >
              <img src="../assets/images/check-circle0.png" alt="img" />
              <h6>{t(item?.day)}</h6>
              <div
                // dir={i18n.language === "Arabic" ? "rtl" : "ltr"}
                style={{
                  fontSize: "10px",
                  color: !item?.active ? "#e44e4e" : "black",
                }}
              >
                {!item?.active ? (
                  t("Closed")
                ) : (
                  <span dir={i18n.language === 'Kurdish' ? "rtl" : ""}>
                    <span dir={i18n.language === 'Kurdish' ? "rtl" : ""}>{removeAmPm(item?.startTime)}</span>
                    {" - "}
                    <span dir={i18n.language === 'Kurdish' ? "rtl" : ""}>{removeAmPm(item?.endTime)}</span>
                  </span>
                  // <span>{`${removeAmPm(item?.startTime)} - ${removeAmPm(item?.endTime)}`}</span>
                )}
              </div>
            </div>

            // <div
            //   className="col rounded-2"
            //   style={{ border: "1px solid lightgray", margin: '5px', minWidth: '100px', maxWidth: '120px' }}
            // >
            //   <h6 className="mt-2">{item?.day}</h6>
            //   <hr />
            //   <div className="boder mx-1">
            //     <span>
            //       <h6>{item?.startTime}</h6>
            //       to
            //       <h6>{item?.endTime}</h6>
            //     </span>
            //   </div>
            // </div>
          );
        })}
      </div>
    </div>
  );
}
