import React, { useEffect } from "react";
import AboutAko from "../../MyComponents/AboutAko";
import { ldSchemas } from "../../../utils/json-schemas";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";

export default function AboutPage() {

  
  return (
    <div className="container my-5">
      <AboutAko />
      <JsonLDSchemaHandler data={ldSchemas?.aboutSayara} />
    </div>
  );
}
