import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRecentSearchDataSet } from './api';

export const rentalSearchDataSet = createAsyncThunk('rental_search/fetchDataSet', async (_, { rejectWithValue }) => {
  try {
    const selectedSearch = await fetchRecentSearchDataSet();
    return selectedSearch;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const rentalSearchSlice = createSlice({
  name: 'rental_search',
  initialState: {
    dataSet: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(rentalSearchDataSet.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rentalSearchDataSet.fulfilled, (state, action) => {
        state.dataSet = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(rentalSearchDataSet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default rentalSearchSlice.reducer;
