import { t } from "i18next";
import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import i18n from "../../../i18n";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../utils/paths";

function WelcomeModal({ modal, closeModal, userType }) {
  const navigate = useNavigate();
  return (
    <Modal
      isOpen={modal}
      toggle={closeModal}
      size="md"
      centered
      style={{ position: "relative" }}
    >
      <ModalHeader
        toggle={closeModal}
        className="border-0"
        style={{ alignSelf: "end" }}
      ></ModalHeader>
      <ModalBody className="m-3">
        {/* <img
          src="/assets/images/close.png"
          height={25}
          onClick={() => closeModal()}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "0px",
            [i18n.language === "English" ? "right" : "left"]: "0px",
          }}
          alt=""
        /> */}
        <h1 className="text-center mb-4" style={{ marginTop: "-40px" }}>{`${t(
          "Welcome To"
        )} ${t("Ako Sayara")}`}</h1>
        <h5 className="my-4">
          {t("Please complete following details to attract the buyers")}
        </h5>
        <ul>
          <li className="fs-6">
            {t("Verify Your Email Address/Mobile Number")}
          </li>
          {userType === "dealer" && (
            <li className="fs-6">{t("Please update your working hours")}</li>
          )}
          {userType === "dealer" ? (
            <li className="fs-6">{t("Please update your cover picture")}</li>
          ) : (
            <li className="fs-6">{t("Please update your profile picture")}</li>
          )}
        </ul>
        <Button
          className="themeButton w-100 mt-3"
          onClick={() => navigate(paths.myProfile)}
        >
          {t("Continue")}
        </Button>
      </ModalBody>
    </Modal>
  );
}

export default WelcomeModal;
