import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchCrousel from "../SearchCrousel";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { initiateChat, likeDislikePost } from "../../../Reducer/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchSaved } from "../../../Reducer/savedSlice";
import ShareModal from "../ShareModal";
import {
  beginWithImage,
  findUploads,
  formatPrice,
  makeTagText,
  renderPhoneNumber,
  setAdsArray,
  setCourierFilters,
} from "../../../utils/constants";

import { Button } from "reactstrap";
import { paths } from "../../../utils/paths";
import PopoverTool from "../PopoverTool";
export default function RentalItem({ data, allFilters, allAdIds }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const profileData = useSelector((state) => state.myprofile.profilearr);
  const savedCars = useSelector((state) => state.saved.saved)?.ads?.map(
    (v) => v._id
  );
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const getPage = (data) => {
    if (data?.adTypeName?.["en"] === "Sell") {
      return formatPrice(lang, data?.askPrice, data?.dollorAskPrice);
    } else {
      if (allFilters?.rentType === "daily") {
        return (
          formatPrice(lang, data?.askPrice, data?.dollorAskPrice) +
          `/ ${t("Day")}`
        );
      } else if (allFilters?.rentType === "weekly") {
        return (
          formatPrice(lang, data?.askPrice1, data?.dollorAskPrice1) +
          `/ ${t("Week")}`
        );
      } else {
        return (
          formatPrice(lang, data?.askPrice2, data?.dollorAskPrice2) +
          `/ ${t("Month")}`
        );
      }
    }
  };

  const [savedIcon, toggleSavedIcon] = useState(
    savedCars?.includes(data?._id) ? "heart_fill.png" : "heart.webp"
  );
  const [all_images, set_all_images] = useState([]);
  useEffect(() => {
    if (data) {
      const ad = data;
      let all_list = findUploads(ad)
        .map((v) => v?.thumbnail || v?.s3URL)
        .slice(0, 3);
      set_all_images(all_list);
    }
  }, [data]);

  const gotoSingleCar = () => {
    // setCourierFilters({...allFilters, owner: data?.owner}, "searchResults");
    setAdsArray(allAdIds);
    if (data?.adTypeName?.["en"] === "Sell") {
      navigate(`${paths.singleCar}?adId=${data?._id}`);
    } else {
      navigate(`${paths.singleRental}?adId=${data?._id}`);
    }
  };

  const toggleSaveCar = async () => {
    if (!localStorage.getItem("token")) {
      navigate("/login", {
        state: { backPage: location.pathname + location?.search },
      });
      return;
    }
    setLoading(true);
    const perform = savedCars?.includes(data?._id) ? "remove" : "add";
    const res = await likeDislikePost(data?._id, perform, setLoading);
    dispatch(fetchSaved());
    if (res)
      toggleSavedIcon(
        savedCars?.includes(data?._id) ? "heart.webp" : "heart_fill.png"
      );
  };
  const gotoSingleDealer = () => {
    if (data?.owner?.userType !== "dealer") return;

    navigate(`${paths.searchResultPage}?id=${data?.owner?._id}`);
  };
  const [chatNow, setChatNow] = useState({
    loading: false,
    adId: "",
  });
  const [chatNowPop, setChatNowPop] = useState(false);
  const handleInitiateChat = (id) => {
    if (chatNow.loading) return;
    if (!localStorage.getItem("token")) {
      navigate("/login", {
        state: { backPage: location.pathname + location?.search },
      });
      return;
    }

    if (!profileData?.isProfileCompleted || !profileData?.phoneNumberVerified) {
      setChatNowPop(true);
      setTimeout(() => {
        setChatNowPop(false);
      }, 2000);
      return;
    }

    setChatNow({ ...chatNow, adId: id, loading: "true" });
    initiateChat(id)
      .then((res) => {
        if (res.status) {
          navigate(`${paths.messages}?id=${res.chatId}`);
        }
        setChatNow({ ...chatNow, loading: "false" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [sharePop, setSharePop] = useState(false);
  const handleCloseSharePop = () => {
    setSharePop(false);
  };

  return (
    <div class="car_set position-relative overflow-hidden">
      <div class="row carousel-margin">
        <SearchCrousel images={all_images ? all_images : []} id={data?._id} />
        <div class="col-sm-6">
          {!["dealer"].includes(data?.owner?.userType) && (
            <p
              className={`position-absolute top-0 ${
                lang === "en" ? "end" : "start"
              }-0`}
            >
              {" "}
              <span
                style={{
                  borderRadius:
                    lang === "en" ? "0px 0px 0px 10px" : "0px 0px 10px 0px",
                  marginTop: "-5px",
                  fontSize: "11px",
                  backgroundColor: "#334660",
                  lineHeight: "23px",
                }}
                class=" text-light float-start px-2  "
              >
                {data?.owner?.userType === "user" ? t("Private ad") : ""}
                {data?.owner?.userType === "brand" && data?.properties?.adLabel
                  ? t(data?.properties?.adLabel)
                  : ""}
              </span>
            </p>
          )}
          <h6>
            {getPage(data)}{" "}
            <span style={{ marginTop: "-3px" }}>
              <Button
                id={`PopoverFocus${data?._id}-view`}
                type="button"
                onClick={() => setSharePop(true)}
                className="btn-sm btn-light"
                style={{ backgroundColor: "transparent", border: "unset" }}
              >
                <a
                // id="PopoverFocus"
                // onClick={() => openShareModal(data?._id)}
                >
                  <img
                    src="../assets/images/share.png"
                    alt="icon"
                    className="me-1"
                  />{" "}
                  {t("Share")}
                </a>{" "}
              </Button>
              <ShareModal
                id={`PopoverFocus${data?._id}-view`}
                data={data}
                open={sharePop}
                close={handleCloseSharePop}
              />

              {loading ? (
                <>
                  <button
                    class="btn btn-light"
                    style={{ width: "74px" }}
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-grow spinner-grow-sm"
                      style={{ marginTop: "-4px", marginRight: "14px" }}
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden" role="status">
                      Loading...
                    </span>
                  </button>
                </>
              ) : (
                <a onClick={() => toggleSaveCar()}>
                  <img
                    src={`../assets/images/${savedIcon}`}
                    className="me-1"
                    alt="icon"
                  />{" "}
                  {savedCars?.includes(data?._id) ? t("heartSaved") : t("Save")}
                </a>
              )}
            </span>
          </h6>
          {/* <h5>{data?.adTitle?.[lang]}</h5> */}
          <h5 className="fw-bold" style={{ fontSize: "20px" }}>
            {t(data?.properties?.basicDetails?.make?.["en"]) +
              " " +
              t(data?.properties?.basicDetails?.model?.["en"])}
          </h5>
          <p>{makeTagText(data, lang)}</p>
          <span
            class="view_car"
            style={{ cursor: "pointer" }}
            onClick={() => gotoSingleCar()}
          >
            {t("View car")}
          </span>
          <div class="cont">
            <div class="row">
              <div class="col-4">
                <p
                  style={{
                    cursor:
                      data?.owner?.userType === "dealer"
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => gotoSingleDealer()}
                >
                  <b>
                    {(data?.owner?.dealerShipName !== "N/A" &&
                      data?.owner?.dealerShipName) ||
                      data?.owner?.fullName}
                  </b>
                </p>
                <p>{t(data?.city)}</p>
                {/* <p>{Array(data.ratting ? data.ratting : 3 ).fill().map((_,i) =>{ return(<i class="fa-solid fa-star"></i>)})}</p> */}
              </div>
              {data?.owner?._id !== profileData?._id ? (
                <>
                  <div class="col-8">
                    <p>
                      {renderPhoneNumber(data?.owner, ({ phone }) => {
                        return (
                          <a href={`tel:${phone}`}>
                            <img src="../assets/images/call.png" alt="icon" />{" "}
                            {`${lang === "en" ? "+" : ""}${phone}${
                              lang === "en" ? "" : "+"
                            }`}
                          </a>
                        );
                      })}
                    </p>

                    <p>
                      <PopoverTool
                        id={`PopoverTool${data?._id}`}
                        open={chatNowPop}
                        close={setChatNowPop}
                      >
                        <div>
                          {t(
                            "Please complete your profile and verify mobile number to start chatting"
                          )}
                        </div>
                      </PopoverTool>
                      <a
                        id={`PopoverTool${data?._id}`}
                        onClick={() => handleInitiateChat(data?._id)}
                        style={{
                          cursor: chatNow.loading ? "default" : "pointer",
                        }}
                      >
                        <img src="../assets/images/message.png" alt="icon" />{" "}
                        {chatNow.loading && chatNow.adId === data?._id
                          ? t("Please wait...")
                          : t("Chat now")}
                      </a>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-8 d-flex justify-content-center align-items-center">
                    <span
                      style={{
                        // backgroundColor: "#4c7ee8",
                        width: "fit-content",
                        color: "#2b2b2b",
                      }}
                      className=" shadow-sm px-4 rounded-pill"
                    >
                      Your Ad
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
