export const sideBarRent = {
  sort: [
    {
      id: "1",
      name: "Latest",
      value: "latest",
      selected: true,
    },
    {
      id: "2",
      name: "Monthly",
      value: "monthly",
    },
    {
      id: "3",
      name: "Yearly",
      value: "yearly",
    },
  ],

  drop_down_filter: [
    {
      name: "Make",
      backend_column: "make",
      data_type: "on select",
    },
    {
      name: "Model",
      backend_column: "model",
      data_type: "on select",
    },
    // {
    //   name: "Model Variant",
    //   backend_column: "variant",
    //   data_type: "on select",
    // },
    {
      name: "Colour",
      backend_column: "colour",
      data_type: "checkbox",
    },
    {
      name: "Price",
      backend_column: "min_max_price",
      data_type: "D_W_M_Price",
    },
  ],
};

export const sideBarNormal = {
  sort: [
    {
      id: "1",
      name: "Latest",
      value: "latest",
      selected: true,
    },
    {
      id: "2",
      name: "Monthly",
      value: "monthly",
    },
    {
      id: "3",
      name: "Yearly",
      value: "yearly",
    },
  ],

  drop_down_filter: [
    {
      name: "Make",
      backend_column: "make",
      data_type: "on select",
    },
    {
      name: "Model",
      backend_column: "model",
      data_type: "on select",
    },
    // {
    //   name: "Model Variant",
    //   backend_column: "variant",
    //   data_type: "on select",
    // },
    {
      name: "Colour",
      backend_column: "colour",
      data_type: "checkbox",
    },
    {
      name: "Price",
      backend_column: "min_max_price",
      data_type: "cfPrice",
    },
  ],
};
