import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import { isImageOrVideo } from "../../../utils/constants";

import ReactImageGallery from "react-image-gallery";
import { Icon } from "@iconify/react/dist/iconify.js";
import { MapInteractionCSS, MapInteraction } from "react-map-interaction";

export default function Items({ imageArray }) {
  const { t, i18n } = useTranslation();
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const active = i18n.language === "English" ? 0 : imageArray.length - 1;
  const handleCloseModal = () => {
    setModal(false);
  };

  const openModal = (item, type) => {
    console.log(item);
    if (type === "video") return;
    const i = images.map((v) => v.original).indexOf(item?.s3URL);
    setImage(i);
    setModal(true);
  };

  useEffect(() => {
    if (imageArray) {
      let arr = imageArray.map((v) => {
        return {
          original: v?.s3URL,
          thumbnail: v?.thumbnail,
          type: isImageOrVideo(v?.s3URL),
        };
      });
      setImages(arr);
    }
  }, [imageArray]);

  const [zoom, setZoom] = useState();

  return (
    <>
      {imageArray && imageArray.length > 0
        ? imageArray.map((item, index) => {
            const type = isImageOrVideo(item?.s3URL);
            if (type === "video") {
              return (
                <div
                  onClick={() => openModal(item, type)}
                  class={`h-100 carousel-item${
                    index == active ? " active" : ""
                  } `}
                >
                  <video
                    controls
                    // muted
                    className="rounded-2"
                    height={400}
                    style={{
                      objectFit: "cover",
                      marginBottom: "-7px",
                      width: "100%",
                    }}
                  >
                    <source src={item?.s3URL} />
                  </video>
                </div>
              );
            } else if (type === "image") {
              return (
                <div
                  onClick={() => openModal(item, type)}
                  class={`h-100 w-100 carousel-item${
                    index == active ? " active" : ""
                  } `}
                >
                  <img
                    class={`w-100`}
                    height={400}
                    style={{ objectFit: "cover" }}
                    src={item ? item?.s3URL : "../assets/images/notFound.png"}
                    onError={(err) => console.log(err.target.message, "error")}
                    onErrorCapture={(e) => console.log(e, "capture")}
                    // onClick={() => openImageViewer(index)}
                    alt="bg"
                  />

                  {/* <ul class="butm">
                {isImageOrVideo(item) !== "video" && (
                  <ListItem
                    innerhtmlType={"html"}
                    value={
                      <a
                        onClick={() => window.print()}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="../assets/images/Print.png"
                          class="Print"
                          style={{ width: "unset" }}
                        />{" "}
                        {t("Print")}
                      </a>
                    }
                  />
                )}
                <ListItem
                  innerhtmlType={"html"}
                  value={
                    <a href={item} download style={{ cursor: "pointer" }}>
                      <img
                        src="../assets/images/Download.png"
                        class="Print"
                        style={{ width: "unset" }}
                      />{" "}
                      {t("Download")}
                    </a>
                  }
                />
              </ul> */}
                </div>
              );
            }
          })
        : ""}
      <Modal
        // size="lg"
        isOpen={modal}
        fullscreen
        centered
        toggle={handleCloseModal}
      >
        {/* <ModalHeader
          style={{ alignSelf: "end" }}
          toggle={handleCloseModal}
          className="d-flex justify-content-end border-0"
        ></ModalHeader> */}
        <ModalBody
          className="d-flex justify-content-center align-items-center"
          style={{ position: "relative", padding: "0xp" }}
        >
          <Icon
            icon="flowbite:close-circle-solid"
            fontSize={34}
            role="button"
            onClick={handleCloseModal}
            style={{
              color: "#4c7ee8",
              position: "absolute",
              right: "30px",
              top: "30px",
              zIndex: 9999,
            }}
          />
          {/* <div
          className="bg-light p-2 rounded-2"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 999,
            }}
          >
            <img
              src="/assets/images/Close.png"
              height={25}
              onClick={() => handleCloseModal()}
              style={{ cursor: "pointer" }}
              alt=""
            />
          </div> */}
          <ReactImageGallery
            startIndex={image}
            items={images || []}
            renderItem={(e) => {
              const item = e?.original;
              switch (e.type) {
                case "image":
                  return (
                    // <MapInteractionCSS
                    //   // value={zoom}
                    //   // defaultValue={{
                    //   //   scale: 0.5,
                    //   //   translation: {
                    //   //     x: 600,
                    //   //     y: 200,
                    //   //   },
                    //   // }}
                    //   // onChange={(e) => console.log(e)}
                    // >
                      <img
                        className="py-3"
                        style={{
                          objectFit: "contain",
                          width: "-webkit-fill-available",
                          height: "90vh",
                        }}
                        src={item ? item : "../assets/images/notFound.png"}
                        alt="bg"
                      />
                    // </MapInteractionCSS>
                    // <MapInteraction>
                    //   {(translate, scale) => {
                    //     console.log(translate, scale);
                    //     return (
                    //       <img
                    //         // className="py-3"
                    //         style={{
                    //           // objectFit: "contain",
                    //           // width: "-webkit-fill-available",
                    //           // height: "90vh",
                    //           transform: `scale(${0.5})`,
                    //         }}
                    //         src={item ? item : "../assets/images/notFound.png"}
                    //         alt="bg"
                    //       />
                    //     );
                    //   }}
                    // </MapInteraction>
                  );
                case "video":
                  return (
                    <video
                      controls
                      className="rounded-2"
                      // height={400}
                      style={{
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                        height: "100vh",
                      }}
                    >
                      <source src={item} />
                    </video>
                  );
              }
            }}
            showThumbnails={false}
            showBullets={false}
            showFullscreenButton={false}
            showPlayButton={false}
            isRTL={i18n.language === "English" ? false : true}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
