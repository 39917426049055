import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function ContactBox({ data, formData, setFormData }) {
  const { t, i18n } = useTranslation();
  const [checkedStates, setCheckedStates] = useState(null);

  const handleItemClick = (index, item) => {
    setCheckedStates(index);
    setFormData({ ...formData, roleObj: item, roleError: '' });
  };

  if (
    !data.contactContant ||
    !Array.isArray(data.contactContant) ||
    data.contactContant.length === 0
  ) {
    return null;
  }

  return data.contactContant.map((item, i) => (
    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 mb-2" key={i}>
      <div className="set1 ">
        <label className="checkcontainer">
          <input
            type="radio"
            name="quality"
            className="quality"
            value={item.id}
            checked={checkedStates == i ? "checked" : ""}
            onChange={() => handleItemClick(i, item)}
          />
          <span className="radiobtn"></span>
        </label>
        <div className="imgs">
          <img src={item.image} alt="img" />
        </div>
        <h4>{item.title[i18n.language]}</h4>
        <p>{item.description[i18n.language]}</p>
      </div>
    </div>
  ));
}
