import { t } from "i18next";
import React from "react";
import { getSiteContent } from "../../Reducer/api";
import { useEffect } from "react";
import { useState } from "react";
import i18n from "../../i18n";
import { marked } from "marked";
import { useSelector } from "react-redux";
import JsonLDSchemaHandler from "../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../utils/json-schemas";

export default function Cookies() {
  const language = useSelector((state) => state.auth.language);
  const [loading, setLoading] = useState(true);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [data, setData] = useState("");

  const getData = async () => {
    setLoading(true);

    await getSiteContent("cookies").then((res) => {
      res.json().then((r) => {
        setData(r?.data?.[0]?.attributes);
      });
    });

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  // let mammoth = require("mammoth");
  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false,
  });

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div class="spinner-grow" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div class=" staticConditions container py-5 min-vh-100">
      <p
        dangerouslySetInnerHTML={{
          __html: marked.parse(data ? data?.[lang] : t("Please wait...")),
        }}
      ></p>

      {/* {data.map((item) => {
        return (
          <div className="container">
            <h4>{item.title[lang]}</h4>
            <p className="text-center">
              {item.description[lang]}
            </p>
          </div>
        )
      })} */}

      <JsonLDSchemaHandler data={ldSchemas?.careers} />
    </div>
  );
}
