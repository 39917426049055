import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ObjectedList from "./objected";
import IndexedList from "./indexed";
import {
  camelToNormal,
  getFeatureName,
  getNameFromVal,
  numberWithCommas,
} from "../../../utils/constants";
import { getFeatures, translateInTwoLanguages } from "../../../Reducer/api";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

export default function CarDetailModel({ data, title }) {
  const { t, i18n } = useTranslation();
  const [allDetails, setAllDetails] = useState(null);
  const [featuresData, setFeaturesData] = useState([]);
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const setTitle = (i) => {
    switch (i) {
      case 0:
        return t("Basic Details");
      case 1:
        return t("Specifications");
      case 2:
        return t("Features");
    }
  };
  const getRequests = () => {
    getFeatures().then((res) => {
      if (res?.status) {
        // setFeaturesData(res?.data?.features)
        let reqFeatures = {};
        const features = res?.data?.features;
        features.forEach((v) => {
          if (data?.features?.[v?.value]) {
            reqFeatures[v?.name?.[lang]] = true;
          } else {
            reqFeatures[v?.name?.[lang]] = false;
          }
        });
        setAllDetails([data?.basicDetails, data?.specifications, reqFeatures]);
      }
    });
  };
  useEffect(() => {
    getRequests();
  }, []);
  const makeValue = (name, value) => {
    if (name === "carDescription") {
      return null;
    }
    if (name === "anyThingElse") {
      return null;
    }
    if (name === "engineSize") {
      if (!value) return null;
      const obj = name.replace("engine", "").toLowerCase();
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {t(getFeatureName(name))}
          <span className="text-end">
            {value ? getNameFromVal(obj, value, lang) : "--"}
          </span>
        </li>
      );
    }
    if (name === "enginePower") {
      if (!value) return null;
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {t(getFeatureName(name))}
          <span className="text-end">
            {value ? `${numberWithCommas(value)} ${t("bhb")}` : "--"}
          </span>
        </li>
      );
    }
    if (name === "km") {
      if (!value) return null;
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {t("Kilometres")}
          <span className="text-end">
            {value ? `${numberWithCommas(value)} ${t("KMs")}` : "--"}
          </span>
        </li>
      );
    }
    const type = typeof value;
    switch (type) {
      case "boolean":
        return value ? (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {name}{" "}
            <span>
              <img
                src="assets/images/check.webp"
                className="text-end"
                height={15}
                alt=""
              />
            </span>
          </li>
        ) : null;
      case "string":
        if (!value) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {t(getFeatureName(name))}
            <span className="text-end">
              {value && value?.length > 0 ? t(value) : "--"}
            </span>
          </li>
        );
      case "number":
        if (value !== 0 && !value) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {t(getFeatureName(name))}
            <span className="text-end">
              {value || value == 0 ? value : "--"}
            </span>
          </li>
        );
      case "object":
        if (!value?.[lang]) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {t(getFeatureName(name))}
            <span className="text-end">
              {value?.[lang] ? t(value?.[lang]) : "--"}
            </span>
          </li>
        );
    }
  };

  const [accordion, setAccordion] = useState([]);
  const toggleAccordion = (e) => {
    if (accordion.includes(e)) {
      setAccordion([...accordion.filter((v) => v !== e)]);
    } else {
      setAccordion([...accordion, e]);
    }
  };

  return (
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog specifications">
        <div class="modal-content" style={{ paddingTop: "18px" }}>
          <div class="modal-header d-flex justify-content-end">
            <a class="btnclose mb-3" data-bs-dismiss="modal" aria-label="Close">
              <img
                src="../assets/images/close_img.png"
                alt="icon"
                height={22}
              />
            </a>
          </div>
          <div class="modal-body">
            {/* <div class="accordion accordion-flush" id="accordionExample">
              {data &&
                data.map((item, index) => {
                  return (
                    <div class="accordion-item">
                      <h2 class="accordion-header" id={`headingOne${index}`}>
                        <button
                          class={`accordion-button`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${index}`}
                          // aria-expanded="true"
                          aria-controls={`#collapseOne${index}`}
                        >
                          <img class="plus" src="../assets/images/plus.png" />
                          <img
                            style={{ display: "none" }}
                            src="../assets/images/cls.png"
                          />{" "}
                          {setTitle(index)}
                        </button>
                      </h2>
                      <div
                        id={`collapseOne${index}`}
                        class={`accordion-collapse collapse`}
                        // aria-labelledby={`headingOne${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <ul className="d-none d-sm-block w-100">
                          {Object.keys(item).map((val) => {
                            return makeValue(val, item[val]);
                          })}
                        </ul>
                        <div
                          className="d-block d-sm-none list-group"
                          style={{ listStyleType: "none" }}
                        >
                          {Object.keys(item).map((val) => {
                            return makeValue(val, item[val]);
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div> */}

            <div className="specifications">
              <Accordion open={accordion} toggle={toggleAccordion}>
                {allDetails &&
                  allDetails?.map((item, index) => {
                    return (
                      <AccordionItem>
                        <AccordionHeader targetId={index + 1}>
                          <img class="plus" src="../assets/images/plus.png" />
                          <img
                            style={{ display: "none" }}
                            src="../assets/images/cls.png"
                          />
                          {setTitle(index)}
                        </AccordionHeader>
                        <AccordionBody accordionId={index + 1}>
                          <ul className="d-none d-sm-block w-100">
                            {Object.keys(item).map((val) => {
                              let v = item?.[val];
                              if (v === "true") v = true;
                              if (v === "false") v = false;
                              const valu = makeValue(val, v);
                              return valu;
                            })}
                          </ul>
                          <div
                            className="d-block d-sm-none list-group"
                            style={{ listStyleType: "none" }}
                          >
                            {Object.keys(item).map((val) => {
                              let v = item?.[val];
                              if (v === "true") v = true;
                              if (v === "false") v = false;
                              const valu = makeValue(val, v);
                              return valu;
                            })}
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
