import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { detailFormSubmit, sendWelcomeMail } from "./api";

export const detailForm = createAsyncThunk(
  "detail/detailForm",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await detailFormSubmit(formData, rejectWithValue);
      if (response.status) {
        let data = response;
        data.data = JSON.parse(localStorage.getItem("user"));
        sendWelcomeMail()
        return data;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const detailSlice = createSlice({
  name: "detail",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    detail: {},
  },
  reducers: {
    clearStatus: (state) => {
      state.submitting = false;
      state.error = null;
      state.success = false;
      state.detail = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(detailForm.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(detailForm.fulfilled, (state, action) => {
        state.submitting = false;
        state.detail = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(detailForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export const { clearStatus } = detailSlice.actions;
export default detailSlice.reducer;
