import { Icon } from "@iconify/react/dist/iconify.js";
import { t } from "i18next";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import ShareModal from "../../Tools/ShareModal";
import { useLocation, useNavigate } from "react-router-dom";
import { likeDislikePost } from "../../../Reducer/api";
import { useDispatch } from "react-redux";
import { fetchSaved } from "../../../Reducer/savedSlice";
import { paths } from "../../../utils/paths";

function CompareComponent({ data, className, id }) {
  const savedCars = useSelector((state) => state.saved.saved)?.ads?.map(
    (v) => v._id
  );
  const saveLoad = useSelector((state) => state.saved.loading);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [sharePop, setSharePop] = useState(false);

  const toggleSaveCar = async () => {
    if (!localStorage.getItem("token")) {
      navigate("/login", {
        state: { backPage: location.pathname + location?.search },
      });
      return;
    }

    // setLoading(true);
    const perform = savedCars?.includes(data?._id) ? "remove" : "add";
    const res = await likeDislikePost(data?._id, perform);
    dispatch(fetchSaved());
  };

  const handleCloseSharePop = () => {
    setSharePop(false);
  };
  const comapreCars = (item) => {
    console.log("comparing car ", item?._id);
    navigate(paths?.saved, { state: { cars: [item] } });
  };
  const boxWidth = "90px";
  return (
    <ul
      class={`butm gap-2 align-items-center ${className || ""}`}
      // style={{ [lang === "en" ? "right" : "left"]: "2px" }}
      style={{ position: "unset", width: "unset" }}
    >
      <Button
        id={`PopoverFocus${data?._id}-${id}`}
        type="button"
        onClick={() => setSharePop(true)}
        className="btn-sm btn-light d-flex justify-content-center"
        style={{
          backgroundColor: "transparent",
          border: "unset",
          padding: "0px",
          minWidth: boxWidth,
          width: "fit-content",
        }}
      >
        <li className="d-flex align-items-center justify-content-between gap-1 px-2 w-100">
          <Icon
            icon="ri:share-line"
            fontSize={20}
            style={{ color: "#4c7ee8" }}
          />
          <span
            className="d-lg-inline text-nowrap"
            style={{ fontSize: "15px" }}
          >
            {t("Share")}
          </span>
          <div></div>
        </li>
      </Button>
      <ShareModal
        id={`PopoverFocus${data?._id}-${id}`}
        className={className}
        data={data}
        open={sharePop}
        close={handleCloseSharePop}
      />
      <li
        role="button"
        className="d-flex align-items-center justify-content-between gap-1 px-2"
        style={{ minWidth: boxWidth, width: "fit-content" }}
        onClick={() => comapreCars(data)}
      >
        <Icon
          icon={"material-symbols:text-compare-outline-rounded"}
          fontSize={20}
          style={{ color: "#4c7ee8" }}
        />
        <span className="d-lg-inline text-nowrap" style={{ fontSize: "15px" }}>
          {t("Compare")}
        </span>
        <div></div>
      </li>
      <li
        role="button"
        className="d-flex align-items-center justify-content-between gap-1 px-2"
        style={{ minWidth: boxWidth, width: "fit-content" }}
        onClick={() => toggleSaveCar()}
      >
        {saveLoad ? (
          <span className="w-100 d-flex justify-content-center loader">
            <button
              class="btn btn-light"
              type="button"
              disabled
              style={{ padding: "0px" }}
            >
              <span
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden" role="status">
                Loading...
              </span>
            </button>
          </span>
        ) : (
          <>
            <Icon
              icon={!savedCars?.includes(data?._id) ? "bx:heart" : "bxs:heart"}
              fontSize={20}
              style={{ color: "#4c7ee8" }}
            />
            <span
              className=" d-lg-inline text-nowrap"
              style={{ fontSize: "15px" }}
            >
              {savedCars?.includes(data?._id) ? t("heartSaved") : t("Save")}
            </span>
            <div></div>
          </>
        )}
      </li>
    </ul>
  );
}

export default CompareComponent;
