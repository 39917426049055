import { t } from "i18next";
import React from "react";
import { getSiteContent } from "../../Reducer/api";
import { useEffect } from "react";
import { useState } from "react";
import i18n from "../../i18n";
import { marked } from "marked";
import { useSelector } from "react-redux";

export default function AboutAko() {

  const language = useSelector((state) => state.auth.language);

  const lang =
    language === "English"
      ? "en"
      : language === "Arabic"
      ? "ar"
      : "ku";
  const [data, setData] = useState(null);

  useEffect(() => {
    getSiteContent('aboutUs').then((res) => {
      res.json().then((r) => {
        console.log(r)
        setData(r?.data?.[0]?.attributes);
      });
    });
  }, []);


  // useEffect(() => {
  //   getSiteContent('aboutUs').then(res => {
  //     if(res.status){
  //       setData(res.data)
  //     }
  //   })
  // }, [])

  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    // sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false
  });

  return (
    <div className=" staticConditions container min-vh-100">

      <p
        dangerouslySetInnerHTML={{
          __html: marked.parse(data?.[lang] || t("Please wait...")),
        }}
      ></p>

      {/* {data.map((item) => {
        return (
          <div className="container">
            <h4>{item.title[lang]}</h4>
            <p className="text-center">
              {item.description[lang]}
            </p>
          </div>
        )
      })} */}
    </div>
  );
}
