import React from "react";
const InputFormFile = ({
  style,
  event,
  name,
  onChange,
  accept,
  multiple,
  ...props
}) => {
  if (name) {
    return (
      <input
        multiple={multiple}
        class={style}
        onClick={(e) => (e.target.value = null)}
        accept={accept}
        name={name}
        type={"file"}
        onChange={onChange}
        {...props}
      />
    );
  }
  return (
    <input
      class={style}
      accept={accept}
      name={name}
      type={"file"}
      {...event(name)}
      onChange={onChange}
      {...props}
    />
  );
};
export default InputFormFile;
