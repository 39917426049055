import React, { useEffect, useState } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import FindCar from "../../Tools/FindCar";
import ThiredStep from "../../Tools/SRPages/ThirdStep";
import Final from "../../Tools/SRPages/Final";
import FirstStep from "../../Tools/SRPages/FirstStep";
import SecondStep from "../../Tools/SRPages/SecondStep";
import { TostOptions, title } from "../../../data/content";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { paths } from "../../../utils/paths";
import { ldSchemas } from "../../../utils/json-schemas";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";

export default function SellYourCar() {
  const navigate = useNavigate();
  const location = useLocation();
  const udata = useSelector((state) => state.auth);

  const [step, setStep] = useState(1);
  const [startup, setStart] = useState("FirstChildVIn");
  const [details, setDetails] = useState({});
  const { t, i18n } = useTranslation();
  var data = { startpage: startup, backpage: "", data: details };
  const [reset, setReset] = useState(false);
  if (location.state && location.state.startpage != "") {
    data.startpage = location.state.startpage;
    data.backpage = location.state.backpage;
    data.data = location.state.data;
  }

  const handleClick = (steps, from, data) => {
    setStep(steps);
    setStart(from);
    setDetails(data);
  };

  const page = () => {
    switch (step) {
      case 1:
        return (
          <FirstStep
            data={data}
            onClick={handleClick}
            page={"SellYourCar"}
            reset={reset}
          />
        );
      case 2:
        return (
          <SecondStep
            onClick={handleClick}
            start={startup}
            details={details}
            page={"SellYourCar"}
          />
        );
      case 3:
        return (
          <ThiredStep
            onClick={handleClick}
            start={startup}
            details={details}
            page={"SellYourCar"}
          />
        );
      case 4:
        return (
          <Final page={"SellYourCar"} setReset={setReset} setStep={setStep} />
        );
    }
  };
  if (!udata.token) {
    return (
      <section>
        <div className="login min-vh-100">
          <Navigate to={paths.login} />
        </div>
      </section>
    );
  } else {
    return (
      <>
        <FindCar element={page()} title={"Sell Your Car"} step={step} />
        <JsonLDSchemaHandler data={ldSchemas?.sellYourCar} />
      </>
    );
  }
}
