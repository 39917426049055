import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPlansData } from './api';

export const fetchPlans = createAsyncThunk('plans/fetchPlans', async (formData, { rejectWithValue }) => {
  try {
    const response = await fetchPlansData(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const planSlice = createSlice({
  name: 'plans',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    plans:[],
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(fetchPlans.pending, state => {
      state.submitting = true;
      state.error = null;
      state.success = false;
    })
    .addCase(fetchPlans.fulfilled, (state,action) => {
      state.submitting = false;
      state.plans = action.payload.data ? action.payload.data : [];
      state.success=true;
      state.error = null;
    })
    .addCase(fetchPlans.rejected, (state, action) => {
      state.submitting = false;
      state.error = action.payload;
      state.success = false;
    })
  },
});
export default planSlice.reducer;
