import { t } from "i18next";
import React from "react";

export default function ComingSoon() {
  return (
    <div class="min-vh-100" style={{ marginTop: "300px" }}>
      <h2
        style={{ textShadow: "3px 3px 5px gray", fontSize: "50px" }}
        className="w-100 h-100 text-center "
      >
        {t("Coming Soon...")}
      </h2>
    </div>
  );
}
