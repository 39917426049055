import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlItem from "../OwlItem";
import { homeOptions } from "../../../data/content";
import { fetchRecent } from "../../../Reducer/recentSlice";
import OwlItemCars from "../OwlItem/owlItemCars";

export default function RecentlyCarView({ rtl, page, title }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const recent = useSelector((state) => state.recent.recent);
  const loading = useSelector((state) => state.recent.loading);
  const error = useSelector((state) => state.recent.error);
  const auth = useSelector((state) => state.auth.auth);
  let data = { id: auth && auth.id ? auth.id : "", page: page };
  const type = page === "normal" ? "Sell" : "Rent";

  useEffect(() => {
    dispatch(fetchRecent(data));
  }, [dispatch, page]);

  let responce =
    auth && recent?.data && recent?.data.length > 0 && page?.length > 0
      ? recent?.data.filter((v) => v?.adTypeName?.["en"] === type)
      : recent?.data;
  const handleClick = (datalist) => {
    if (datalist) {
      dispatch(fetchRecent(data));
    }
  };
  console.log(recent);
  if (loading) {
    return (
      <div
        class="d-flex justify-content-center"
        style={{ marginBlock: "25vh" }}
      >
        <div class="spinner-grow" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <section class="recently_viewed">
      {responce && responce.length > 0 ? (
        <div class="container">
          <h2 class="hed">{t(title)}</h2>
          <OwlCarousel
            {...homeOptions}
            className={`owl-carousel Recently_carousel owl.carousel.min owl-theme owl-rtl owl-loaded owl-drag ${
              rtl && "invertButtons"
            }`}
            // margin={10}
            rtl={rtl}
          >
            <OwlItemCars
              key={0}
              cars={responce}
              page={"BrandNewSection"}
              rentType={"daily"}
              filters={{ recentAds: true }}
              adsArray={recent?.allAdIds}
            />
            {/* <OwlItem key={0} fill={'heart_fill.png'} unfill={'heart.webp'} responce={responce} page={'RecentlyCarSection'} handleClick={handleClick}/> */}
          </OwlCarousel>
        </div>
      ) : (
        <>
          {/* <div className="col-sm-7 col-12">
            <span className="fs-5 fw-semibold text-center w-100">
              {t('No car recently viewed')}
            </span>
            <br />
          </div> */}
        </>
      )}
    </section>
  );
}
