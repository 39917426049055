import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchdealerCarInfo } from './api';

export const dealerInfo= createAsyncThunk('details/dealerInfo', async (formData, { rejectWithValue }) => {
  try {
    const data = await fetchdealerCarInfo(formData);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const dealer = createSlice({
  name: 'dealer',
  initialState: {
    dealerarr: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(dealerInfo.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(dealerInfo.fulfilled, (state, action) => {
        state.dealerarr = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(dealerInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dealer.reducer;
