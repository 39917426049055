import moment from "moment/moment";
import React from "react";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { showTimeUnixTranslated } from "../../../../../utils/constants";
export default function LeftRigtChat({
  datas,
  classs,
  key,
  person,
  parentMessage,
  setReplying,
}) {
  const navigate = useNavigate();
  const [chat, setChat] = useState(datas);
  const udata = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  const handleCloseModal = () => {
    setModal(false);
  };
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const showOnMapClicked = (data) => {
    console.log(data);
    const ar = data.split(",");
    window.open("https://maps.google.com?q=" + ar[0] + "," + ar[1]);
  };
  const getAudioPerm = (e) => {
    // navigator.permissions.query({name: 'audio'}).then(res => {
    //   console.log(res)
    // })
    console.log(e);
  };

  const getContent = (data, color) => {
    if (!data) return;
    switch (data?.messageType) {
      case "text":
        return <p style={{ color: color }}>{data.messageBody}</p>;
      case "image":
        return (
          <div>
            <img
              src={data?.attachmentPath}
              alt="image"
              width={150}
              height={150}
              // className="img-thumbnail"
              onClick={() => setModal(true)}
              style={{
                cursor: "pointer",
                objectFit: "cover",
                objectPosition: "center",
                borderRadius: "10px",
              }}
            />
            <p className="mt-2" style={{ color: color }}>
              {data?.messageBody}
            </p>
          </div>
        );
      case "audio":
        return (
          <>
            <audio
              controls
              style={{ height: "34px", width: "250px" }}
            >
              <source src={data?.attachmentPath} type="audio/mpeg" />
              <source src={data?.attachmentPath} type="audio/wav" />
              <source src={data?.attachmentPath} type="audio/aac" />
              <source src={data?.attachmentPath} type="audio/oog" />
            </audio>{" "}
            <br />
            <p
              style={{
                width: "250px",
              }}
            >
              {data?.messageBody}
            </p>
          </>
        );
      case "video":
        return (
          <>
            <div
              className="text-light"
              // onClick={() => setModal(true)}
              style={{
                cursor: "pointer",
                color: color,
                width: "200px",
                backgroundImage: `url(/assets/images/)`,
              }}
            >
              <video controls className="img-fluid rounded-3">
                <source src={data?.attachmentPath} />
              </video>
              {/* <Icon
                icon="iconoir:play-solid"
                height={30}
                className="mx-5 my-4 d-block"
              /> */}
            </div>
            <p>{data?.messageBody}</p>
          </>
        );
      case "document":
        return (
          <a href={data?.attachmentPath} style={{ cursor: "pointer" }}>
            <Icon
              icon="pajamas:doc-text"
              width={60}
              color={classs == "inright" ? "white" : "black"}
            />
            <Icon
              icon="material-symbols:download"
              width={20}
              style={{ minWidth: "40px" }}
              color={classs == "inright" ? "white" : "black"}
            />
            <br />
            <br />
            <p>{data?.messageBody}</p>
          </a>
        );
      case "location":
        return (
          <div
            className="text-light text-center"
            onClick={() => showOnMapClicked(data?.messageBody)}
            style={{ cursor: "pointer", color: color }}
          >
            <img
              src="/assets/images/location.png"
              alt=""
              style={{ borderRadius: "15px" }}
              width={60}
              className="img-fluid"
            />
          </div>
        );
      default:
        return <p>{data.messageBody}</p>;
    }
  };

  // let name =
  //   person?.sellerId === udata?.auth?.userId
  //     ? person?.buyerName
  //     : person?.buyerId === udata?.auth?.userId
  //     ? person?.sellerName
  //     : "--";
  let dp =
    person?.sellerId === udata?.auth?.userId
      ? person?.buyerAvatar
      : person?.buyerId === udata?.auth?.userId
      ? person?.sellerAvatar
      : "";
  if (!dp || dp === "N/A") dp = "/assets/images/ap2.png";

  const reply = (msg) => {
    let replyMsg = {
      data: getHighlightContent(msg),
      id: msg?._id,
      msg: msg,
    };
    setReplying(replyMsg);
  };

  const scrollMsgToView = (id) => {
    const element = document.getElementById(`msgId${id}`);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });

      element.classList.add("msgReached");
      setTimeout(() => {
        element.classList.remove("msgReached");
      }, 1500);
    }
  };

  const getHighlightContent = (data) => {
    if (!data) return null;

    switch (data?.messageType) {
      case "text":
        return (
          <p
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => scrollMsgToView(data?._id)}
          >
            {data.messageBody}
          </p>
        );
      case "image":
        return (
          <p
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => scrollMsgToView(data?._id)}
          >
            <span className="d-flex justify-content-center">
              <img
                src={data?.attachmentPath}
                height={60}
                alt=""
                style={{ borderRadius: "5px" }}
              />
            </span>
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {data?.messageBody}
            </span>
          </p>
        );
      case "audio":
        return (
          <p
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => scrollMsgToView(data?._id)}
          >
            <span className="d-flex justify-content-center">
              <img
                src={"/assets/images/audio_thumbnail.png"}
                height={40}
                alt="audio_thumbnail"
              />
            </span>
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {data?.messageBody}
            </span>
          </p>
        );
      case "video":
        return (
          <p
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => scrollMsgToView(data?._id)}
          >
            <span className="d-flex justify-content-center">
              <img
                src={"/assets/images/video.png"}
                height={40}
                alt="audio_thumbnail"
              />
            </span>
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {data?.messageBody}
            </span>
          </p>
        );
      case "document":
        return (
          <p
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => scrollMsgToView(data?._id)}
          >
            <span className="d-flex justify-content-center">
              <Icon icon="pajamas:doc-text" color={"black"} />
            </span>
            <p
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                color: "black",
              }}
            >
              {data?.messageBody}
            </p>
          </p>
        );
      case "location":
        return (
          <p
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => scrollMsgToView(data?._id)}
          >
            <span className="d-flex justify-content-center">
              <img
                src={"/assets/images/location.png"}
                height={40}
                alt="audio_thumbnail"
              />
            </span>
          </p>
        );
      default:
        return <p style={{ color: "black" }}>{data.messageBody}</p>;
    }
  };
  return (
    <div id={`msgId${datas?._id}`}>
      <div class={`col-12 ${classs} mb-4`} key={key}>
        <div class="msg">
          <div class="msgimg">
            <img src={dp} class="img-fluid h-100 w-100 border rounded-circle" />
          </div>
          <h6 className="mt-2">{showTimeUnixTranslated(chat?.deliveredAt)}</h6>
          <div
            style={{ padding: "10px" }}
            class={`ext ${
              classs === "inright"
                ? lang === "en"
                  ? "float-end"
                  : "float-start"
                : lang === "en"
                ? "float-start"
                : "float-end"
            }`}
          >
            {/* <p>{datas.message}</p> */}

            {parentMessage && (
              <div
                className="bg-light p-2 my-2 rounded-3"
                style={{ color: "black" }}
              >
                {getHighlightContent(parentMessage)}
              </div>
            )}
            {getContent(chat)}
            <Icon
              icon="material-symbols:reply"
              color={classs == "inright" ? "white" : "black"}
              width={15}
              style={{ cursor: "pointer" }}
              onClick={() => reply(chat)}
            />
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        isOpen={modal}
        fullscreen
        centered
        toggle={handleCloseModal}
      >
        <ModalHeader dir="ltr" className="d-flex justify-content-end">
          <img
            src="/assets/images/close.png"
            height={25}
            onClick={() => handleCloseModal()}
            style={{ cursor: "pointer" }}
            alt=""
          />
        </ModalHeader>

        <ModalBody className="d-flex justify-content-center align-items-center">
          {chat?.messageType === "image" && (
            <>
              <img
                src={chat?.attachmentPath}
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                  objectFit: "contain",
                }}
                alt="image-full-view"
              />
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
