import React from "react";
import { useState } from "react";
import i18n from "../../../i18n";
const FormInput = ({style, placeholder,intype,event,name,value,min,max,checked,disabled}) => {
    const [hidden, setHidden] = useState(false)
    const lang = i18n.language
    let rtlStyles = {}

    if(lang !== 'English') {
        rtlStyles = {
            left: '15px', 
            right: 'unset'
        }
    }
    
    const getInput=()=>{
        let val;
        switch (intype) {
            case 'checkbox':
                val=<input class={style} name={name} type={intype} {...event(name)} defaultChecked={checked ? 'checked' :''} placeholder={placeholder}/>;
                break;
            case 'password':
                val=<div>
                        <input class={style} name={name} type={!hidden ? 'password': 'text'} {...event(name)} defaultChecked={checked ? 'checked' :''} placeholder={placeholder}/>
                        <i class={`far fa-eye${hidden ? "" : "-slash"} d-block`} onClick={() => setHidden(!hidden)}  style={{cursor: 'pointer', ...rtlStyles}}></i>
                    </div>;
                break;
            default:
                val=<input class={style} disabled={disabled} name={name}  minlength={min} maxlength={max} type={intype} defaultValue={value} {...event(name)} checked={checked ? 'checked' :''} placeholder={placeholder}/>;
                break;
        }
        return val;
    }


    return (
        <>{getInput()}</>
    )
}
export default FormInput;

  