import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {fetchSearchedElectricCar,fetchBrandByPostData} from './api';

export const ElectricDetail = createAsyncThunk('form/fetchElectricDetail', async (formData, { rejectWithValue }) => {
  try {
    const response = await fetchSearchedElectricCar(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchPostByBrand = createAsyncThunk('searched/fetchPostByBrand', async (formData, { rejectWithValue }) => {
    try {
      const selectedSearch = await fetchBrandByPostData(formData);
      return selectedSearch;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

const electric_car = createSlice({
  name: 'electric_car',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    cardata:{},
   
  },
  reducers: {},
  extraReducers: builder => {
    builder
        //Electric  cars ............
      .addCase(ElectricDetail.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(ElectricDetail.fulfilled, (state,action) => {
        state.submitting = false;
        state.cardata = action.payload.data;
        state.success=true;
        state.error = null;
      })
      .addCase(ElectricDetail.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })

    //    Post data 
    .addCase(fetchPostByBrand.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPostByBrand.fulfilled, (state, action) => {
        state.cardata.default_active = action.payload;
        state.loading = false;
        state.error = null;
      }).addCase(fetchPostByBrand.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
     
    
  },
});
export default electric_car.reducer;
