import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRecentData } from './api';

export const fetchRecent = createAsyncThunk('recent/fetchRecent', async (formData, { rejectWithValue }) => {
  try {
    const recent = await fetchRecentData(formData);
    console.log(recent)
    return recent;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const recentSlice = createSlice({
  name: 'recent',
  initialState: {
    recent: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRecent.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRecent.fulfilled, (state, action) => {
        state.recent = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchRecent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
  },
});

export default recentSlice.reducer;
