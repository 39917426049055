import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHomeArticleData } from './api';

export const fetchhomeArticle = createAsyncThunk('article/fetchomeArticle', async (_, { rejectWithValue }) => {
  try {
    const searched = await fetchHomeArticleData();
    return searched;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const homeArticleSlice = createSlice({
  name: 'home_article',
  initialState: {
    home_article: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchhomeArticle.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchhomeArticle.fulfilled, (state, action) => {
        state.home_article = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchhomeArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default homeArticleSlice.reducer;
