import React from "react";
import TermsAndConditions from "../../../../MyComponents/TermsAndConditions";

export default function Advertising_TCs() {
  return (
    <div className="container">
      <TermsAndConditions />
    </div>
  );
}
