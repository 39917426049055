import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Button from "../Button";
import ArticleItem from "../ArticleItem";
import { fetchhomeArticle } from "../../../Reducer/homeArticleSlice";
import { homeOptions } from "../../../data/content";
import { NavLink } from "react-router-dom";
import { fetchHomeArticleData } from "../../../Reducer/api";
import { paths } from "../../../utils/paths";

export default function ArticlesSection({ rtl }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const home_article = useSelector((state) => state.home_article.home_article);
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const loading = useSelector((state) => state.home_article.loading);
  const error = useSelector((state) => state.home_article.error);
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    // dispatch(fetchhomeArticle());
    fetchHomeArticleData({ pageSize: "4", sortBy: "desc" })
      .then((res) => {
        res
          .json()
          .then((r) => {
            if (r?.data) {
              setArticles(r.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section class="recent_articles">
      {articles.length > 0 && (
        <div class="container">
          <h2 class="hed">{t("Recent articles from Ako Sayara")}</h2>
          <div class="w-100">
            <OwlCarousel
              className={`owl-carousel owl.carousel.min owl-theme ${
                rtl ? "articleOwlRtl" : "articleOwl"
              }`}
              {...homeOptions}
              // margin={30}
              rtl={rtl}
            >
              {articles.map((item, key) => {
                return (
                  <ArticleItem
                    key={key}
                    style={""}
                    title={item?.attributes?.name?.[lang]}
                    created_by={item?.attributes?.additionalData?.by?.[lang]}
                    time_ago={item?.attributes?.additionalData?.date}
                    image={item?.attributes?.additionalData?.thumbnail}
                    // description={item.description[language]}
                    data={item}
                    id={item.id}
                    lang={lang}
                    thumbnailHeight={"200px"}
                  ></ArticleItem>
                );
              })}
            </OwlCarousel>
          </div>
          <div class="w-100 float-left text-center mt-4">
            <NavLink className={"viewall"} to={paths.articles}>
              {t("View all articles")}
            </NavLink>
          </div>
        </div>
      )}
    </section>
  );
}
