import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { vehicleMy } from "../../../../../Reducer/myvehicleSlice";
import MyvehicleTool from "../../../../Tools/Myvehicle";
import TobBar from "./TobBar";
import myvehicle from "../../../../../data/myvechicle.json";
import { useState } from "react";
import { fetchMyvehicle } from "../../../../../Reducer/api";

export default function MyVehicle() {
  // const data = useSelector((state) => state.myvehicle.myvehicleArr);
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    console.log("refresh called............");
    refresh();
  }, [page, filter]);

  useEffect(() => {
    setPage(1);
  }, [filter]);

  const refresh = async () => {
    if (page == 1) {
      setLoading(true);
    }
    setPageLoading(true)
    // setLoading(true);
    const myAds = await fetchMyvehicle({
      page: page,
      ...filter,
    });

    if (myAds?.status) {
      if (page > 1) {
        setData((data) => ({
          ...data,
          data: { ...data.data, hasNextPage: myAds?.data?.hasNextPage, ads: data?.data?.ads.concat(myAds?.data?.ads) },
        }));
      } else {
        setData(myAds);
      }
    } else {
      setData([]);
    }

    setLoading(false);

    setPageLoading(false)
  };

  const goNext = (e) => {
    console.log(e, "going next");
    if(!pageLoading) {
      setPage((page) => page + 1);
    }
  };

  myvehicle.data.tobbar[0].count = data?.data?.totalAds
    ? data?.data?.totalAds
    : "0";

  return (
    <>
      <TobBar
        data={myvehicle.data.tobbar ? myvehicle.data.tobbar : []}
        refresh={refresh}
        setFilter={setFilter}
      />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : data?.data?.totalAds ? (
        <div class="row">
          <MyvehicleTool data={data} refresh={refresh} goNext={goNext} />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          {!data?.status && (
            <h5 style={{ fontWeight: "500" }} className="text-center">
              {t("No vehicles available")}
            </h5>
          )}
        </div>
      )}
    </>
  );
}
