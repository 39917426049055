import { toast } from "react-toastify";
import { apiManager } from "../api/connectAPI";
import i18n from "../i18n";
import { t } from "i18next";
import { paths } from "../utils/paths";
const lang =
  i18n.language === "English" ? "en" : i18n.language === "Arabic" ? "ar" : "ku";

export const TempBaseUrl = "assets/images/";

export const headerData = [
  {
    id: "1",
    name: "Buy a Car",
    segment: "",
  },
  {
    id: "2",
    name: "Rent a Car",
    segment: paths.rentPerfectCars.slice(1),
  },
  {
    id: "3",
    name: "Sell Your Car",
    segment: paths.sellYourCar.slice(1),
  },
  {
    id: "4",
    name: "Rent Your Car",
    segment: paths.rentYourCar.slice(1),
  },
  {
    id: "5",
    name: "Check Your Car",
    segment: paths.checkYourCars.slice(1),
  },
  // {
  //   id: "6",
  //   name: "Electric cars",
  //   segment: "ElectricCars",
  // },
  // {
  //   id: "7",
  //   name: "Classic cars",
  //   segment: "classic_cars",
  // },
];

export const searchPages = [
  {
    name: "Search cars now",
    segment: "Serching",
  },
  {
    name: "Search hatchbacks now",
    segment: "hatchback_cars",
  },
  {
    name: "Search saloons now",
    segment: "saloons",
  },
  {
    name: "Search coupes now",
    segment: "coupes",
  },
  {
    name: "Search pickups now",
    segment: "pickups",
  },
];

export const KeyFect = [
  {
    name: "Paint",
    value: "paint",
  },
  {
    name: "Price",
    value: "Price",
    highlight: true,
  },
  {
    name: "Fuel Type",
    value: "petrol",
  },
  {
    name: "Age",
    value: "age",
  },
  {
    name: "City",
    value: "cities",
  },
  {
    name: "Distance",
    value: "kilometers",
  },
  {
    name: "Make",
    value: "make",
  },
  {
    name: "Model",
    value: "model",
  },
  {
    name: "Model Variant",
    value: "variant",
  },
  {
    name: "Regional Specs",
    value: "regional_specs",
  },
  {
    name: "Body Condition",
    value: "body",
  },
  {
    name: "Kilometers",
    value: "kilometers",
  },
  {
    name: "Transmission",
    value: "transmission",
  },
  {
    name: "Colour",
    value: "colour",
  },
  {
    name: "Height",
    value: "height",
  },
  {
    name: "Gross vehicle weight",
    value: "weight",
  },
  {
    name: "Length",
    value: "length",
  },
  {
    name: "Wheelbase",
    value: "wheelbase",
  },
  {
    name: "Width",
    value: "width",
  },
  {
    name: "Fuel tank capacity",
    value: "fuel_tank",
  },
];

export const Practicality = [
  {
    name: "Boot space (seats up)",
    value: "boot_space_seats_down",
  },
  {
    name: "Boot space (seats up)",
    value: "boot_space_seats_up",
  },
  {
    name: "Minimum kerb weight",
    value: "minimum_kerb_weight",
  },
  {
    name: "Max loading weight",
    value: "max_kerb_weight",
  },
  {
    name: "Doors",
    value: "engine",
  },
  {
    name: "Seats",
    value: "seats",
  },
];

export const Performance = [
  {
    name: "Engine Size",
    value: "engine",
  },
  {
    name: "Engine Power",
    value: "power",
  },
  {
    name: "Cylinders",
    value: "cylinders",
  },
  {
    name: "Number of valves",
    value: "num_value",
  },
  {
    name: "Engine torque",
    value: "engine_torque",
  },
  {
    name: "Top speed",
    value: "speed",
  },
  {
    name: "Acceleration",
    value: "acceleration",
  },
];
export const DateFormet = "DD/MM/YYYY";

export const title = {
  Home: {
    English: t("Ako Sayara"),
    Arabic: t("Ako Sayara"),
    Kurdish: t("Ako Sayara"),
  },
  SellYourCar: {
    English: t("Sell Your Car"),
    Arabic: t("Sell Your Car"),
    Kurdish: t("Sell Your Car"),
  },
  RentYourCar: {
    English: t("Rent Your Car"),
    Arabic: t("Rent Your Car"),
    Kurdish: t("Rent Your Car"),
  },
  CheckYourCar: {
    English: t("Check Your Car"),
    Arabic: t("Check Your Car"),
    Kurdish: t("Check Your Car"),
  },
  Login: {
    English: t("Login"),
    Arabic: t("Login"),
    Kurdish: t("Login"),
  },
  Signup: {
    English: t("Sign Up"),
    Arabic: t("Sign Up"),
    Kurdish: t("Sign Up"),
  },
  Detail: {
    English: t("Basic Details"),
    Arabic: t("Basic Details"),
    Kurdish: t("Basic Details"),
  },
  Saved: {
    English: t("Saved"),
    Arabic: t("Saved"),
    Kurdish: t("Saved"),
  },
  Cheap: {
    English: t("Cheap cars"),
    Arabic: t("Cheap cars"),
    Kurdish: t("Cheap cars"),
  },
  Newcar: {
    English: t("New cars"),
    Arabic: t("New cars"),
    Kurdish: t("New cars"),
  },
  Electric: {
    English: t("Electric cars"),
    Arabic: t("Electric cars"),
    Kurdish: t("Electric cars"),
  },
  Classicar: {
    English: t("Classic cars"),
    Arabic: t("Classic cars"),
    Kurdish: t("Classic cars"),
  },
  Blog: {
    English: "Ako Sayara || ",
    Arabic: "Ako Sayara ||",
    Kurdish: "Ako Sayara ||",
  },
  retnalcar: {
    English: t("Rent Your Car"),
    Arabic: t("Rent Your Car"),
    Kurdish: t("Rent Your Car"),
  },
  dealershipdoc: {
    English: t("Upload Dealership Document"),
    Arabic: t("Upload Dealership Document"),
    Kurdish: t("Upload Dealership Document"),
  },
  tc_c: {
    English: t("Terms & Conditions"),
    Arabic: t("Terms & Conditions"),
    Kurdish: t("Terms & Conditions"),
  },
  whyako: {
    English: t("Why Ako Sayara"),
    Arabic: t("Why Ako Sayara"),
    Kurdish: t("Why Ako Sayara"),
  },
  Contactus: {
    English: t("Contact Us"),
    Arabic: t("Contact Us"),
    Kurdish: t("Contact Us"),
  },
  Privacyp: {
    English: t("Privacy Policy"),
    Arabic: t("Privacy Policy"),
    Kurdish: t("Privacy Policy"),
  },
  closeacount: {
    English: t("Close my Account"),
    Arabic: t("Close my Account"),
    Kurdish: t("Close my Account"),
  },
  dealerpage: {
    English: t("Dealer Search Result"),
    Arabic: t("Dealer Search Result"),
    Kurdish: t("Dealer Search Result"),
  },
  Myvehicles: {
    English: t("My vehicles"),
    Arabic: t("My vehicles"),
    Kurdish: t("My vehicles"),
  },
  Enquiry: {
    English: t("Your Enquiry"),
    Arabic: t("Your Enquiry"),
    Kurdish: t("Your Enquiry"),
  },
  Messages: {
    English: t("Messages"),
    Arabic: t("Messages"),
    Kurdish: t("Messages"),
  },
  Myprofile: {
    English: t("My profile"),
    Arabic: t("My profile"),
    Kurdish: t("My profile"),
  },
  Transactions: {
    English: t("Transactions"),
    Arabic: t("Transactions"),
    Kurdish: t("Transactions"),
  },
  Performance: {
    English: t("Performance"),
    Arabic: t("Performance"),
    Kurdish: t("Performance"),
  },
  cookeis: {
    English: t("Cookies"),
    Arabic: t("Cookies"),
    Kurdish: t("Cookies"),
  },
};

export const footerData = [
  {
    id: "1",
    name: "Company",
    segment: "company",
    sub_list: [
      {
        id: "1",
        name: "About Ako Sayara",
        segment: paths.aboutSayara.slice(1),
      },
      {
        id: "2",
        name: "Contact Us",
        segment: paths.contact.slice(1),
      },
      {
        id: "3",
        name: "Careers",
        segment: paths.careers.slice(1),
      },
      {
        id: "4",
        name: "Privacy policies",
        segment: paths.policies.slice(1),
      },
      {
        id: "5",
        name: "Terms & Conditions",
        segment: paths.conditions.slice(1),
      },
      // {
      //   id: "6",
      //   name: "Brand Advertising on Ako Sayara",
      //   segment: "advertising",
      // },
      {
        id: "7",
        name: "Cookies",
        segment: paths.cookies.slice(1),
      },
    ],
  },
  {
    id: "2",
    name: "Products & Services",
    segment: "product_service",
    sub_list: [
      {
        id: "1",
        name: "Buy your perfect car",
        segment: paths.buyCar.slice(1),
      },
      {
        id: "4",
        name: "Sell Your Car",
        segment: paths.sellYourCar.slice(1),
      },
      {
        id: "3",
        name: "Rent your perfect car",
        segment: paths.rentPerfectCars.slice(1),
      },
      {
        id: "2",
        name: "Rent Your Car",
        segment: paths.rentYourCar.slice(1),
      },
      {
        id: "5",
        name: "Check Your Car",
        segment: paths.checkYourCars.slice(1),
      },
    ],
  },
  {
    id: "3",
    name: "Buying advice",
    segment: "buying_advice",
    sub_list: [
      {
        id: "1",
        name: "Buying guide",
        segment: `${paths.singleGuide.slice(1)}/1`,
      },
      {
        id: "3",
        name: "Selling guide",
        segment: `${paths.singleGuide.slice(1)}/2`,
      },
      {
        id: "2",
        name: "Renting guide",
        segment: `${paths.singleGuide.slice(1)}/3`,
      },
      {
        id: "4",
        name: "Rent your car guide",
        segment: `${paths.singleGuide.slice(1)}/4`,
      },
      {
        id: "5",
        name: "Articles",
        segment: paths.articles.slice(1),
      },
      {
        id: "6",
        name: "Why Ako Sayara",
        segment: paths.whyAkoSayara.slice(1),
      },
    ],
  },
  {
    id: "4",
    name: "Quick search",
    segment: "quick_search",
    sub_list: [
      {
        id: "1",
        name: "Find a car dealer",
        segment: paths.dealerSearchResults.slice(1),
      },
      {
        id: "2",
        name: "Find a brand",
        segment: paths.brandSearchResults.slice(1),
      },
      {
        id: "3",
        name: "Classic cars",
        segment: paths.classicCars.slice(1),
      },
      {
        id: "4",
        name: "Cheap cars",
        segment: paths.cheapCars.slice(1),
      },
      {
        id: "5",
        name: "New car deals",
        segment: paths.newCarDeals.slice(1),
      },
    ],
  },
];

export const TostOptions = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  bodyClassName: 'toaster'
};
export const social = [
  {
    id: "1",
    name: "Facebook",
    // icon: "fa-brands fa-facebook",
    icon: "devicon:facebook",
    link: "https://www.facebook.com/akosayara",
    height: 26
  },
  {
    id: "2",
    name: "X (Twitter)",
    // icon: "fa-brands fa-twitter",
    icon: "devicon:twitter",
    link: "https://twitter.com/akosayara",
    height: 22
  },
  {
    id: "3",
    name: "Linkedin",
    // icon: "fa-brands fa-linkedin",
    icon: "devicon:linkedin" ,
    link: "https://www.linkedin.com/company/akosayara/",
    height: 26
  },
  {
    id: "4",
    name: "Youtube",
    // icon: "fa-brands fa-youtube",
    icon: "logos:youtube-icon",
    link: "https://youtube.com/@akosayara?si=959yernRHGst8Y4Y",
    height: 25
  },
  {
    id: "5",
    name: "Instagram",
    // icon: "fa-brands fa-instagram",
    icon: "skill-icons:instagram",
    link: "https://www.instagram.com/akosayara/",
    height: 28
  },
  {
    id: "6",
    name: "Tiktok",
    // icon: "fa-brands fa-tiktok",
    icon: "logos:tiktok-icon",
    link: "https://www.tiktok.com/@akosayara",
    height: 28
  },
];

export const appLinks = {
  apple: "https://apps.apple.com/in/app/ako-sayara/id6458545849",
  google: "https://play.google.com/store/apps/details?id=com.ako_sayara",
  huawei: "https://appgallery.huawei.com/app/C110928665"
}

export const checkbox = [
  {
    id: 1,
    name: "ABS",
    value: "abc",
    selected: false,
  },
  {
    id: 2,
    name: "Electric seats",
    value: "electric_seats",
    selected: false,
  },
  {
    id: 3,
    name: "Power door locks",
    value: "power_door_locks",
    selected: false,
  },
  {
    id: 4,
    name: "Air condition seats",
    value: "air_condition_seats",
    selected: false,
  },
  {
    id: 45,
    name: "Airbags",
    value: "airbags",
    selected: false,
  },
  {
    id: 5,
    name: "Head screen dashboard projector",
    value: "head_screen_dashboard_projector",
    selected: false,
  },
  {
    id: 6,
    name: "Rear view camera",
    value: "rear_view_camera",
    selected: false,
  },
  {
    id: 345,
    name: "Smart Entry",
    value: "smart_entry",
    selected: false,
  },
  {
    id: 7,
    name: "Air condition",
    value: "air_condition",
    selected: false,
  },
  {
    id: 64,
    name: "Cruise control",
    value: "cruise_control",
    selected: false,
  },
  {
    id: 8,
    name: "Heated seats",
    value: "heated_seats",
    selected: false,
  },
  {
    id: 9,
    name: "Sunroof",
    value: "sunroof",
    selected: false,
  },
  {
    id: 10,
    name: "AUX/USB Plug",
    value: "plug",
    selected: false,
  },
  {
    id: 11,
    name: "Heated steering wheel",
    value: "heated_steering_wheel",
    selected: false,
  },
  {
    id: 3,
    name: "Smart parcking",
    value: "smart_parcking",
    selected: false,
  },
  {
    id: 4,
    name: "Bluetooth",
    value: "bluetooth",
    selected: false,
  },
  {
    id: 5,
    name: "Keyless entry",
    value: "keyless_entry",
    selected: false,
  },
  {
    id: 6,
    name: "Touchscreen",
    value: "touchscreen",
    selected: false,
  },
  {
    id: 435,
    name: "USB type C",
    value: "usb_type_c",
    selected: false,
  },
  {
    id: 7,
    name: "Cooling box fridge",
    value: "cooling_box_fridge",
    selected: false,
  },
  {
    id: 8,
    name: "Leather seats",
    value: "leather_seats",
    selected: false,
  },
  {
    id: 43,
    name: "Led lights",
    value: "led_lights",
    selected: false,
  },
  {
    id: 9,
    name: "Wireless charging",
    value: "wireless_charging",
    selected: false,
  },
  {
    id: 4,
    name: "Electric Doors",
    value: "electric_doors",
    selected: false,
  },
  {
    id: 43,
    name: "Electric mirrors",
    value: "electric_mirrors",
    selected: false,
  },
  {
    id: 5,
    name: "Message seats",
    value: "message_seats",
    selected: false,
  },
  {
    id: 8,
    name: "Navigation system",
    value: "navigation_system",
    selected: false,
  },
  {
    id: 6,
    name: "Xenon lights",
    value: "xenon_lights",
    selected: false,
  },
  {
    id: 9,
    name: "Parking sensors",
    value: "parking_sensors",
    selected: false,
  },
];

export const getAllGenders = async () => {
  const apiCall = apiManager("GET", `general/gender?lang=${lang}`)
    .then((res) => {
      if (res.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err.message);
    });
  return apiCall;
};

export const genders = [
  {
    _id: "1",
    name: {
      English: "Male",
      Arabic: "diff",
      Kurdish: "diff",
    },
  },
  {
    _id: "2",
    name: {
      English: "Female",
      Arabic: "diff",
      Kurdish: "diff",
    },
  },
];

export const cities = [
  { _id: 1, name: { en: "Abu Al-Khaseeb", ar: "Abu Al-Khaseeb", ku: "Abu Al-Khaseeb" } },
  { _id: 2, name: { en: "Akre", ar: "Akre", ku: "Akre" } },
  { _id: 3, name: { en: "Al Khalis", ar: "Al Khalis", ku: "Al Khalis" } },
  { _id: 4, name: { en: "Al Midhatiya", ar: "Al Midhatiya", ku: "Al Midhatiya" } },
  { _id: 5, name: { en: "Al Nasr Wal Salam", ar: "Al Nasr Wal Salam", ku: "Al Nasr Wal Salam" } },
  { _id: 6, name: { en: "Al Qa'im", ar: "Al Qa'im", ku: "Al Qa'im" } },
  { _id: 7, name: { en: "Al Qasim", ar: "Al Qasim", ku: "Al Qasim" } },
  { _id: 8, name: { en: "Al Rifa'i", ar: "Al Rifa'i", ku: "Al Rifa'i" } },
  { _id: 9, name: { en: "Al Rumaitha", ar: "Al Rumaitha", ku: "Al Rumaitha" } },
  { _id: 10, name: { en: "Al Shamal", ar: "Al Shamal", ku: "Al Shamal" } },
  { _id: 11, name: { en: "Al Shamiya", ar: "Al Shamiya", ku: "Al Shamiya" } },
  { _id: 12, name: { en: "Al Shatrah", ar: "Al Shatrah", ku: "Al Shatrah" } },
  { _id: 13, name: { en: "Al Suwaira", ar: "Al Suwaira", ku: "Al Suwaira" } },
  { _id: 14, name: { en: "Al Wahda", ar: "Al Wahda", ku: "Al Wahda" } },
  { _id: 15, name: { en: "Al-Aziziyah", ar: "Al-Aziziyah", ku: "Al-Aziziyah" } },
  { _id: 16, name: { en: "Al Hindiya", ar: "Al Hindiya", ku: "Al Hindiya" } },
  { _id: 17, name: { en: "Al Mahmudiyah", ar: "Al Mahmudiyah", ku: "Al Mahmudiyah" } },
  { _id: 18, name: { en: "Al Mejar Al Kabir", ar: "Al Mejar Al Kabir", ku: "Al Mejar Al Kabir" } },
  { _id: 19, name: { en: "An Numaniyah", ar: "An Numaniyah", ku: "An Numaniyah" } },
  { _id: 20, name: { en: "Amarah", ar: "Amarah", ku: "Amarah" } },
  { _id: 21, name: { en: "Baiji", ar: "Baiji", ku: "Baiji" } },
  { _id: 22, name: { en: "Baghdad", ar: "Baghdad", ku: "Baghdad" } },
  { _id: 23, name: { en: "Baqubah", ar: "Baqubah", ku: "Baqubah" } },
  { _id: 24, name: { en: "Balad", ar: "Balad", ku: "Balad" } },
  { _id: 25, name: { en: "Balad Ruz", ar: "Balad Ruz", ku: "Balad Ruz" } },
  { _id: 26, name: { en: "Banslawa", ar: "Banslawa", ku: "Banslawa" } },
  { _id: 27, name: { en: "Basra", ar: "Basra", ku: "Basra" } },
  { _id: 28, name: { en: "Bekreco", ar: "Bekreco", ku: "Bekreco" } },
  { _id: 29, name: { en: "Chamchamal", ar: "Chamchamal", ku: "Chamchamal" } },
  { _id: 30, name: { en: "Daretu", ar: "Daretu", ku: "Daretu" } },
  { _id: 31, name: { en: "Diwaniyah", ar: "Diwaniyah", ku: "Diwaniyah" } },
  { _id: 32, name: { en: "Diyala", ar: "Diyala", ku: "Diyala" } },
  { _id: 33, name: { en: "Dohuk", ar: "Dohuk", ku: "Dohuk" } },
  { _id: 34, name: { en: "Fallujah", ar: "Fallujah", ku: "Fallujah" } },
  { _id: 35, name: { en: "Fayda", ar: "Fayda", ku: "Fayda" } },
  { _id: 36, name: { en: "Haji Awa", ar: "Haji Awa", ku: "Haji Awa" } },
  { _id: 37, name: { en: "Halabja", ar: "Halabja", ku: "Halabja" } },
  { _id: 38, name: { en: "Hawija", ar: "Hawija", ku: "Hawija" } },
  { _id: 39, name: { en: "Hillah", ar: "Hillah", ku: "Hillah" } },
  { _id: 40, name: { en: "Hit", ar: "Hit", ku: "Hit" } },
  { _id: 41, name: { en: "Iskandariya", ar: "Iskandariya", ku: "Iskandariya" } },
  { _id: 42, name: { en: "Jalawla", ar: "Jalawla", ku: "Jalawla" } },
  { _id: 43, name: { en: "Karbala", ar: "Karbala", ku: "Karbala" } },
  { _id: 44, name: { en: "Kasnazan", ar: "Kasnazan", ku: "Kasnazan" } },
  { _id: 45, name: { en: "Kalar", ar: "Kalar", ku: "Kalar" } },
  { _id: 46, name: { en: "Khanakin", ar: "Khanakin", ku: "Khanakin" } },
  { _id: 47, name: { en: "Kirkuk", ar: "Kirkuk", ku: "Kirkuk" } },
  { _id: 48, name: { en: "Koy Sanjaq", ar: "Koy Sanjaq", ku: "Koy Sanjaq" } },
  { _id: 49, name: { en: "Kufa", ar: "Kufa", ku: "Kufa" } },
  { _id: 50, name: { en: "Kut", ar: "Kut", ku: "Kut" } },
  { _id: 51, name: { en: "Mosul", ar: "Mosul", ku: "Mosul" } },
  { _id: 52, name: { en: "Musayyib", ar: "Musayyib", ku: "Musayyib" } },
  { _id: 53, name: { en: "Najaf", ar: "Najaf", ku: "Najaf" } },
  { _id: 54, name: { en: "Nasiriyah", ar: "Nasiriyah", ku: "Nasiriyah" } },
  { _id: 55, name: { en: "Numaniyah", ar: "Numaniyah", ku: "Numaniyah" } },
  { _id: 56, name: { en: "Qahtaniyah", ar: "Qahtaniyah", ku: "Qahtaniyah" } },
  { _id: 57, name: { en: "Qaladiza", ar: "Qaladiza", ku: "Qaladiza" } },
  { _id: 58, name: { en: "Qalat Sukkar", ar: "Qalat Sukkar", ku: "Qalat Sukkar" } },
  { _id: 59, name: { en: "Qasim", ar: "Qasim", ku: "Qasim" } },
  { _id: 60, name: { en: "Qasrok", ar: "Qasrok", ku: "Qasrok" } },
  { _id: 61, name: { en: "Ramadi", ar: "Ramadi", ku: "Ramadi" } },
  { _id: 62, name: { en: "Ranya", ar: "Ranya", ku: "Ranya" } },
  { _id: 63, name: { en: "Said Sadiq", ar: "Said Sadiq", ku: "Said Sadiq" } },
  { _id: 64, name: { en: "Samarra", ar: "Samarra", ku: "Samarra" } },
  { _id: 65, name: { en: "Samawah", ar: "Samawah", ku: "Samawah" } },
  { _id: 66, name: { en: "Shamiyah", ar: "Shamiyah", ku: "Shamiyah" } },
  { _id: 67, name: { en: "Shatt Al Arab", ar: "Shatt Al Arab", ku: "Shatt Al Arab" } },
  { _id: 68, name: { en: "Shirqat", ar: "Shirqat", ku: "Shirqat" } },
  { _id: 69, name: { en: "Shorish", ar: "Shorish", ku: "Shorish" } },
  { _id: 70, name: { en: "Simele", ar: "Simele", ku: "Simele" } },
  { _id: 71, name: { en: "Sinjar", ar: "Sinjar", ku: "Sinjar" } },
  { _id: 72, name: { en: "Sulaymaniyah", ar: "Sulaymaniyah", ku: "Sulaymaniyah" } },
  { _id: 73, name: { en: "Suq al-Shuyukh", ar: "Suq al-Shuyukh", ku: "Suq al-Shuyukh" } },
  { _id: 74, name: { en: "Soran", ar: "Soran", ku: "Soran" } },
  { _id: 75, name: { en: "Tal Afar", ar: "Tal Afar", ku: "Tal Afar" } },
  { _id: 76, name: { en: "Tikrit", ar: "Tikrit", ku: "Tikrit" } },
  { _id: 77, name: { en: "Tuz Khurmatu", ar: "Tuz Khurmatu", ku: "Tuz Khurmatu" } },
  { _id: 78, name: { en: "Umm Qasr", ar: "Umm Qasr", ku: "Umm Qasr" } },
  { _id: 79, name: { en: "Zubayr", ar: "Zubayr", ku: "Zubayr" } }
]

export const ComparePages = [
  {
    title: "View detail",
    segment: paths.comparing.slice(1),
  },
  {
    title: "View image",
    segment: paths.viewImage.slice(1),
  },
  // {
  //   title: "View on map",
  //   segment: "ViewMap",
  // },
];

export const accountClosureReasons = [
  {
    id: 1,
    name: {
      en: "Disinterest or inactivity",
      ar: "Disinterest or inactivity",
      ku: "Disinterest or inactivity",
    },
  },
  {
    id: 2,
    name: {
      en: "Dissatisfaction with the services",
      ar: "Dissatisfaction with the services",
      ku: "Dissatisfaction with the services",
    },
  },
  {
    id: 3,
    name: {
      en: "Switching to competing services",
      ar: "Switching to competing services",
      ku: "Switching to competing services",
    },
  },
  {
    id: 4,
    name: {
      en: "Fraudulent activity",
      ar: "Fraudulent activity",
      ku: "Fraudulent activity",
    },
  },
  {
    id: 5,
    name: {
      en: "Discontinuation of services",
      ar: "Discontinuation of services",
      ku: "Discontinuation of services",
    },
  },
  {
    id: 6,
    name: {
      en: "Privacy concerns",
      ar: "Privacy concerns",
      ku: "Privacy concerns",
    },
  },
  {
    id: 7,
    name: {
      en: "Security concerns",
      ar: "Security concerns",
      ku: "Security concerns",
    },
  },
  {
    id: 8,
    name: {
      en: "Payment issues",
      ar: "Payment issues",
      ku: "Payment issues",
    },
  },
];

export const addForm = {
  categoryId: "",
  subcategoryId: "",
  entityTypeId: "",
  name: {
    en: "",
    ar: "",
    ku: "",
  },
  description: {
    en: "",
    ar: "",
    ku: "",
  },
  brandId: "",
  properties: {
    basicDetails: {
      make: "",
      makeYear: "",
      model: "",
      regionalSpecs: "",
      condition: "",
      color: "",
    },
    specifications: {
      paint: "",
      km: "",
      gearbox: "",
      fuelType: "",
      bodyType: "",
      engineSize: "",
      enginePower: "",
      cylinders: "",
      doors: "",
      seats: "",
    },
    features: {
      abs: false,
      airbags: false,
      ac: false,
      acSeats: false,
      xUsb: false,
      bluetooth: false,
      coolingFridge: false,
      cruiseControl: false,
      electricDoors: false,
      electricMirrors: false,
      electricSeats: false,
      dashboardProjector: false,
      heatedSeats: false,
      heatedSteringWheel: false,
      keylessEntry: false,
      leatherSeats: false,
      ledLights: false,
      massgeSeats: false,
      navigationSystem: false,
      parkingSensors: false,
      powerLocks: false,
      rearCamera: false,
      smartEntry: false,
      sunroof: false,
      smartParking: false,
      touchscreen: false,
      usbTypeC: false,
      wirelessCharging: false,
      xenonLights: false,
      anyThingElse: "",
      carDescription: "",
    },
    entityImages: [],
    price: {
      daily: "",
      weekly: "",
      monthly: "",
    },
    city: "",
    registrationNumber: "",
  },
};

export const homebanner = {
  cities: [],
  distance: [],
  make: [],
  model: [],
  min_price: [],
  max_price: [],
};

export const brandSection = {
  topfour: [],
  all: [],
};

export const errorSms = {
  first: "Something went wrong",
};

export const phoneRegExp =
  "/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/";

export const homeOptions = {
  // loop: true,
  autoplay: false,
  autoplayTimeout: 5000,
  margin: 0,
  responsiveClass: true,
  responsive: {
    0: { items: 2, nav: true },
    576: { items: 2, nav: true },
    768: { items: 2, nav: true },
    992: { items: 3, nav: true },
    1200: { items: 3, nav: true },
  },
};

export const homeSelect = {
  loop: true,
  autoplay: false,
  autoplayTimeout: 5000,
  margin: 0,
  responsiveClass: true,
  responsive: {
    0: { items: 2.5, nav: false },
    576: { items: 2.5, nav: true },
  },
};

export const centerLocation = { lat: -34.397, lng: 150.644 };

export const compareSection = {
  loop: false,
  autoplay: false,
  autoplayTimeout: 5000,
  margin: 15,
  responsiveClass: true,
  responsive: {
    0: { items: 1, nav: true },
    576: { items: 2, nav: true },
    768: { items: 3, nav: true },
    992: { items: 4, nav: true },
    1200: { items: 5, nav: true },
  },
};

export function isFileSizeValid(files, maxSize) {
  let valid = true;
  if (files) {
    files.map((file) => {
      const fileSize = file.size;
      if (fileSize > maxSize) {
        valid = false;
      }
    });
  }
  return valid;
}

export function isFileTypesValid(files, authorizedExtensions) {
  let valid = true;
  if (files) {
    files.map((file) => {
      console.log("file-type", file);
      console.log("file-authorizedExtensions", authorizedExtensions);
      if (!authorizedExtensions.includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
}

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function closestValue(target, numbers) {
  let closest = null;
  let minDifference = Infinity;

  for (let i = 0; i < numbers.length; i++) {
    const difference = Math.abs(target - numbers[i]);
    if (difference < minDifference) {
      minDifference = difference;
      closest = numbers[i];
    }
  }

  return closest;
}

export const findMyCarDataStatic = {
  status: true,
  message: "Record Available",
  data: {
    status: "ok",
    result: {
      id: "3D671D4B",
      vin: "5TDYK3DC8DS290235",
      report: {
        decoder: {
          vin: "5TDYK3DC8DS290235",
          make: "Toyota",
          msrp: "$33,360 USD",
          trim: "XLE FWD 8-Passenger V6",
          year: "2013",
          depth: "",
          model: "Sienna",
          style: "SPORTS VAN",
          tires: "235/60R17",
          width: "78.10 in.",
          "4WdAwd": "N/A",
          engine: "3.5L V6 EFI DOHC 24V",
          height: "70.70 in.",
          length: "200.20 in.",
          madeIn: "UNITED STATES",
          invoice: "$30,691 USD",
          cargoNet: "Opt.",
          cdPlayer: "Std.",
          fuelTank: "20.00 gallon",
          absBrakes: "Std.",
          amFmRadio: "Std.",
          cdChanger: "Opt.",
          driveline: "FWD",
          dvdPlayer: "Opt.",
          fogLights: "Std.",
          rearWiper: "Std.",
          skidPlate: "N/A",
          subwoofer: "Opt.",
          trackRear: "67.70 in.",
          wheelbase: "119.30 in.",
          tachometer: "Std.",
          trackFront: "67.70 in.",
          alloyWheels: "Std.",
          cargoLength: "",
          cargoVolume: "39.10 cu.ft.",
          cityMileage: "18 miles/gallon",
          firstAidKit: "Opt.",
          frontAirDam: "Std.",
          leatherSeat: "Std.",
          maximumGvwr: "",
          rearHipRoom: "66.10 in.",
          rearLegroom: "37.60 in.",
          rearSpoiler: "Std.",
          steelWheels: "N/A",
          widthAtWall: "",
          chromeWheels: "N/A",
          driverAirbag: "Std.",
          frontHipRoom: "58.60 in.",
          frontLegroom: "40.50 in.",
          interiorTrim: "Bisque Leather Interior | Light Gray Leather Interior",
          keylessEntry: "Std.",
          powerSunroof: "Std.",
          powerWindows: "Std.",
          rearHeadroom: "39.70 in.",
          removableTop: "N/A",
          runFlatTires: "N/A",
          rustDistance: "Unlimited mile",
          rustDuration: "60 month",
          splashGuards: "Opt.",
          standardGvwr: "",
          steeringType: "R&P",
          tiltSteering: "Std.",
          tripComputer: "Std.",
          basicDistance: "36,000 mile",
          basicDuration: "36 month",
          cruiseControl: "Std.",
          exteriorColor:
            "Black | Cypress Pearl | Predawn Gray Mica | Salsa Red Pearl | Sandy Beach Metallic | Shoreline Blue Pearl | Silver Sky Metallic | Super White",
          frontHeadroom: "39.10 in.",
          manualSunroof: "Std.",
          maximumTowing: "3500 lbs",
          navigationAid: "Opt.",
          powerTrunkLid: "Std.",
          rearBrakeType: "Drum",
          runningBoards: "Opt.",
          cargoAreaCover: "N/A",
          cassettePlayer: "N/A",
          frontBrakeType: "Disc",
          highwayMileage: "25 miles/gallon",
          intervalWipers: "Std.",
          maximumPayload: "",
          powerDoorLocks: "Std.",
          rearSpringType: "Coil",
          rearSuspension: "Semi",
          remoteIgnition: "N/A",
          standardTowing: "3500 lbs",
          airConditioning: "Std.",
          antiBrakeSystem: "4-Wheel ABS",
          deepTintedGlass: "Std.",
          frontCooledSeat: "N/A",
          frontHeatedSeat: "Std.",
          frontSideAirbag: "Std.",
          frontSpringType: "Coil",
          frontSuspension: "Ind",
          genuineWoodTrim: "N/A",
          groundClearance: "6.50 in.",
          optionalSeating: "",
          passengerAirbag: "Std.",
          passengerVolume: "",
          standardPayload: "",
          standardSeating: "8",
          tractionControl: "Std.",
          turningDiameter: "37.50 in.",
          curbWeightManual: "",
          rearShoulderRoom: "64.60 in.",
          telematicsSystem: "Opt.",
          towHitchReceiver: "N/A",
          transmissionLong: "5-Speed Automatic",
          vehicleAntiTheft: "Std.",
          widthAtWheelwell: "",
          cargoAreaTiedowns: "N/A",
          destinationCharge: "$845 USD",
          frontShoulderRoom: "65.00 in.",
          fullSizeSpareTire: "N/A",
          rainSensingWipers: "N/A",
          transmissionShort: "5A",
          powertrainDistance: "60,000 mile",
          powertrainDuration: "60 month",
          rearWindowDefogger: "Std.",
          tiltSteeringColumn: "Std.",
          automaticHeadlights: "Std.",
          curbWeightAutomatic: "4465 lbs",
          frontSplitBenchSeat: "N/A",
          heatedSteeringWheel: "N/A",
          lockingDifferential: "N/A",
          pickupTruckBedLiner: "N/A",
          productionSeqNumber: "290235",
          secondRowHeatedSeat: "N/A",
          secondRowSideAirbag: "N/A",
          tirePressureMonitor: "Std.",
          trunkAntiTrapDevice: "N/A",
          adjustableFootPedals: "N/A",
          childSafetyDoorLocks: "Std.",
          daytimeRunningLights: "Std.",
          electronicParkingAid: "Opt.",
          frontPowerMemorySeat: "N/A",
          heatedExteriorMirror: "Std.",
          leatherSteeringWheel: "Std.",
          secondRowFoldingSeat: "N/A",
          automaticLoadLeveling: "N/A",
          sideHeadCurtainAirbag: "Std.",
          thirdRowRemovableSeat: "Std.",
          secondRowRemovableSeat: "Std.",
          secondRowSoundControls: "Opt.",
          frontPowerLumbarSupport: "Std.",
          limitedSlipDifferential: "N/A",
          loadBearingExteriorRack: "N/A",
          powerSlidingSideVanDoor: "Std.",
          voiceActivatedTelephone: "N/A",
          pickupTruckCargoBoxLight: "N/A",
          telescopicSteeringColumn: "Std.",
          towingPreparationPackage: "Std.",
          electronicBrakeAssistance: "Std.",
          lockingPickupTruckTailgate: "N/A",
          glassRearWindowOnConvertible: "N/A",
          slidingRearPickupTruckWindow: "N/A",
          steeringWheelMountedControls: "Std.",
          windDeflectorForConvertibles: "N/A",
          powerAdjustableExteriorMirror: "Std.",
          vehicleStabilityControlSystem: "Std.",
          driverMultiAdjustablePowerSeat: "Std.",
          highIntensityDischargeHeadlights: "N/A",
          frontSideAirbagWithHeadProtection: "N/A",
          passengerMultiAdjustablePowerSeat: "Std.",
          secondRowMultiAdjustablePowerSeat: "N/A",
          electrochromicExteriorRearviewMirror: "N/A",
          electrochromicInteriorRearviewMirror: "Std.",
          secondRowSideAirbagWithHeadProtection: "N/A",
          separateDriverFrontPassengerClimateControls: "Std.",
        },
        auctions: [
          {
            announced_at_auction: "Aug 9, 2017",
            make: "Toyota",
            model: "Sienna",
            odometer: null,
            odometer_status: "INOPERABLE DIGITAL DASH",
            color: "GRAY",
            engine: "3.5L V6 FI DOHC 24V NF4",
            drive: null,
            acv: null,
            repair_cost: null,
            fuel: null,
            cylinders: null,
            own_doc: " ",
            primary_damage: "FRONT END",
            secondary_damage: "MECHANICAL",
            location: "East Bay",
            sale_date: null,
            sale_status: null,
            images: [
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab4e8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab4d8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab4c8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab4b8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab4a8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab498bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab488bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab478bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab468bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab4ef7ad05b9b19f.jpg",
            ],
            condition: "WON'T START",
            sale_price: null,
            seller: null,
            auctionId: "216388047",
            vendor: "salvagebid.com",
            title_state: " ",
            date: "Aug 9, 2017",
          },
          {
            announced_at_auction: "Sep 22, 2017",
            make: "Toyota",
            model: "Sienna",
            odometer: 0,
            odometer_status: "NOT ACTUAL",
            color: "GRAY",
            engine: "3.5L V6 FI DOHC 24V NF4",
            drive: null,
            acv: 24212,
            repair_cost: null,
            fuel: null,
            cylinders: null,
            own_doc: "CA SALVAGE CERTIFICATE",
            primary_damage: "FRONT END",
            secondary_damage: "MECHANICAL",
            location: "East Bay",
            sale_date: "2017-11-16",
            sale_status: null,
            images: [
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab4e8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab4d8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab4c8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab4b8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab4a8bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab498bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab488bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab478bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab468bcf41a3a6.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a41ff3d2fbaec1656eeb58e32dd5ab4ef7ad05b9b19f.jpg",
            ],
            condition: "CAN'T TEST",
            sale_price: null,
            seller: "Mercury Insurance",
            auctionId: "872801785",
            vendor: "salvagebid.com",
            title_state: "CA",
            date: "Nov 15, 2017",
          },
          {
            announced_at_auction: "Nov 25, 2017",
            make: "Toyota",
            model: "Sienna Xle",
            odometer: 30525,
            odometer_status: "Not Actual",
            color: "GRAY",
            engine: "3.5L  6",
            drive: "Front-wheel Drive",
            acv: 29212,
            repair_cost: 2647,
            fuel: "GAS",
            cylinders: 6,
            own_doc: "CA SALVAGE CERTIFICATE",
            primary_damage: "FRONT END",
            secondary_damage: null,
            location: "CA - Los Angeles",
            sale_date: "2017-12-28",
            sale_status: "On Minimum Bid",
            images: [
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc676ce70de62dd2e352f48048e4eccc784232fcfe772b24a39473c8c2e44810f5c22e4a5aefd97567.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc3068ed54e623d7e352a3d613ebeccc221663fca72c2a20a390769cc3e41e15a792294a53efd97567.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc673dbb0fb427d6e552a18319e0eccc7f1664fcfe272f27a397779bc5e51815f4c8754a53efd97567.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc336def58b37282bf52ff8549e6eccc7e4635fcfd2d7976a39376cdc7b41811f3c27f4f52efd97567.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc6c6ebc55b52487b052f58419e6eccc2a4732fcfd262f23a395259e92e21a43aec12b460eefd97567.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc636bba58b324d6b452f5d21ee4eccc2f446cfca7712924a3c223ccc4e51345a7c97f4a5aefd97567.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc376fec59e671d3b452a3d818e0eccc794d6dfca7277c76a3c022c990e24e41f2c17e4909efd97567.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc653ae855b22280b452f6804ab7eccc781667fcfe717b25a3927fcf97b74e16a0c62f485fefd97567.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc336fed0de12287e352f6801de3eccc2a436cfcfe232f70a3c674cb95e31c45f4c0284c59efd97567.jpg",
              "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccece00959e0a94bc93e0238875d27865d541d0b4fbdb4df0759da7dc16a70c655bcf49a4048cb591b5dc3668ed54e577d8e452a5d61be3eccc294d62fca7747e22a3c17098c1b64942f092754953efd97567.jpg",
            ],
            condition: "Run & Drive Verified",
            sale_price: null,
            current_bid: null,
            recommended_bid: 8250,
            seller: null,
            auctionId: "48111367",
            vendor: "autobidmaster.com",
            title_state: "CA",
            date: "Dec 27, 2017",
          },
        ],
        previewImage:
          "https://www.clearvin.com/images/auctions/72616e646f6d33326c656e6774687374:8542406531503ccede40968816c9aa84e779c62b91367e8514fe8fcee461ad6091e48304ad073c069856a612fcd4f8aec1656aea5ce720d8ab4e8bcf41a3a6.jpg",
      },
    },
  },
};

export const carVinDecoderDataStatic = {
  status: true,
  message: "Record Available",
  data: {
    year: 2017,
    make: "KIA",
    model: "Soul",
    trim: "BASE",
    specs: {
      active_safety_system_note: null,
      adaptive_cruise_control_acc: null,
      adaptive_driving_beam_adb: null,
      anti_lock_braking_system_abs: "Standard",
      auto_reverse_system_for_windows_and_sunroofs: "Standard",
      automatic_crash_notification_acn_advanced_automatic_crash_notification_aacn:
        null,
      automatic_pedestrian_alerting_sound_for_hybrid_and_ev_only: null,
      axle_configuration: null,
      axles: "2",
      backup_camera: "Optional",
      base_price: "16100",
      battery_current_amps_from: null,
      battery_current_amps_to: null,
      battery_energy_kwh_from: null,
      battery_energy_kwh_to: null,
      battery_type: null,
      battery_voltage_volts_from: null,
      battery_voltage_volts_to: null,
      bed_length_inches: null,
      bed_type: null,
      blind_spot_intervention_bsi: null,
      blind_spot_warning_bsw: null,
      body_class: "Sport Utility Vehicle (SUV)/Multi-Purpose Vehicle (MPV)",
      brake_system_description: null,
      brake_system_type: null,
      bus_floor_configuration_type: "Not Applicable",
      bus_length_feet: null,
      bus_type: "Not Applicable",
      cab_type: null,
      charger_level: null,
      charger_power_kw: null,
      cooling_type: null,
      crash_imminent_braking_cib: null,
      curb_weight_pounds: null,
      curtain_air_bag_locations: "1st and 2nd Rows",
      custom_motorcycle_type: "Not Applicable",
      daytime_running_light_drl: null,
      destination_market: null,
      displacement_cc: "1600.0",
      displacement_ci: "97.63799055157",
      displacement_l: "1.6",
      doors: "4",
      drive_type: "4x2",
      dynamic_brake_support_dbs: "Standard",
      electrification_level: null,
      electronic_stability_control_esc: "Standard",
      engine_brake_hp_from: "130",
      engine_brake_hp_to: null,
      engine_configuration: null,
      engine_manufacturer: "KMC",
      engine_model: "GDI",
      engine_number_of_cylinders: "4",
      engine_power_kw: "96.9410",
      engine_stroke_cycles: null,
      entertainment_system: null,
      ev_drive_unit: null,
      event_data_recorder_edr: null,
      forward_collision_warning_fcw: null,
      front_air_bag_locations: "1st Row (Driver and Passenger)",
      fuel_delivery_fuel_injection_type: null,
      fuel_type_primary: "Gasoline",
      fuel_type_secondary: null,
      gross_combination_weight_rating_from: null,
      gross_combination_weight_rating_to: null,
      gross_vehicle_weight_rating_from:
        "Class 1B: 3,001 - 4,000 lb (1,360 - 1,814 kg)",
      gross_vehicle_weight_rating_to: null,
      headlamp_light_source: null,
      keyless_ignition: null,
      knee_air_bag_locations: null,
      lane_centering_assistance: null,
      lane_departure_warning_ldw: null,
      lane_keeping_assistance_lka: null,
      manufacturer_name: "KIA CORPORATION",
      motorcycle_chassis_type: "Not Applicable",
      motorcycle_suspension_type: "Not Applicable",
      ncsa_body_type: "Station Wagon (excluding van and truck based)",
      ncsa_make: "KIA",
      ncsa_model: "Soul",
      ncsa_note: null,
      non_land_use: null,
      note: null,
      number_of_battery_cells_per_module: null,
      number_of_battery_modules_per_pack: null,
      number_of_battery_packs_per_vehicle: null,
      number_of_seat_rows: "2",
      number_of_seats: "5",
      number_of_wheels: "4",
      other_battery_info: null,
      other_bus_info: null,
      other_engine_info: null,
      other_motorcycle_info: null,
      other_restraint_system_info: null,
      other_trailer_info: null,
      parking_assist: null,
      pedestrian_automatic_emergency_braking_paeb: null,
      plant_city: "GWANG-JU",
      plant_company_name: null,
      plant_country: "SOUTH KOREA",
      plant_state: null,
      possible_values: "",
      pretensioner: null,
      rear_automatic_emergency_braking: null,
      rear_cross_traffic_alert: null,
      sae_automation_level_from: null,
      sae_automation_level_to: null,
      seat_belt_type: "Manual",
      seat_cushion_air_bag_locations: null,
      semiautomatic_headlamp_beam_switching: "Standard",
      series: "BASE",
      series2: "Wagon body style",
      side_air_bag_locations: "1st Row (Driver and Passenger)",
      steering_location: "Left-Hand Drive (LHD)",
      suggested_vin: "",
      tire_pressure_monitoring_system_tpms_type: "Direct",
      top_speed_mph: "125",
      track_width_inches: null,
      traction_control: "Standard",
      trailer_body_type: "Not Applicable",
      trailer_length_feet: null,
      trailer_type_connection: "Not Applicable",
      transmission_speeds: "6",
      transmission_style: null,
      trim: null,
      trim2: null,
      turbo: null,
      valve_train_design: "Dual Overhead Cam (DOHC)",
      vehicle_descriptor: "KNDJN2A2*H7",
      vehicle_type: "MULTIPURPOSE PASSENGER VEHICLE (MPV)",
      wheel_base_inches_from: "101.2",
      wheel_base_inches_to: null,
      wheel_base_type: null,
      wheel_size_front_inches: "16",
      wheel_size_rear_inches: "16",
      windows: null,
    },
    trims: [
      {
        id: 16929,
        make_model_id: 56,
        year: 2017,
        name: "Base",
        description: "4dr Wagon (1.6L 4cyl 6M)",
        msrp: 16100,
        invoice: 15757,
        created: "2023-06-29T21:03:31-04:00",
        modified: "2023-06-29T21:03:31-04:00",
        make_model: {
          id: 56,
          make_id: 13,
          name: "Soul",
          make: {
            id: 13,
            name: "Kia",
          },
        },
      },
      {
        id: 16930,
        make_model_id: 56,
        year: 2017,
        name: "Base",
        description: "4dr Wagon (1.6L 4cyl 6A)",
        msrp: 17700,
        invoice: 17123,
        created: "2023-06-29T21:03:31-04:00",
        modified: "2023-06-29T21:03:31-04:00",
        make_model: {
          id: 56,
          make_id: 13,
          name: "Soul",
          make: {
            id: 13,
            name: "Kia",
          },
        },
      },
      {
        id: 16931,
        make_model_id: 56,
        year: 2017,
        name: "+",
        description: "+ 4dr Wagon (2.0L 4cyl 6A)",
        msrp: 19800,
        invoice: 18764,
        created: "2023-06-29T21:03:31-04:00",
        modified: "2023-06-29T21:03:31-04:00",
        make_model: {
          id: 56,
          make_id: 13,
          name: "Soul",
          make: {
            id: 13,
            name: "Kia",
          },
        },
      },
      {
        id: 16932,
        make_model_id: 56,
        year: 2017,
        name: "!",
        description: "! 4dr Wagon (1.6L 4cyl Turbo 7AM)",
        msrp: 22800,
        invoice: 21561,
        created: "2023-06-29T21:03:31-04:00",
        modified: "2023-06-29T21:03:31-04:00",
        make_model: {
          id: 56,
          make_id: 13,
          name: "Soul",
          make: {
            id: 13,
            name: "Kia",
          },
        },
      },
    ],
  },
};

export const carVinMixData = {
  status: true,
  message: "Record Available",
  carApiarResponse: {
    year: 2017,
    make: "KIA",
    model: "Soul",
    trim: "BASE",
    specs: {
      active_safety_system_note: null,
      adaptive_cruise_control_acc: null,
      adaptive_driving_beam_adb: null,
      anti_lock_braking_system_abs: "Standard",
      auto_reverse_system_for_windows_and_sunroofs: "Standard",
      automatic_crash_notification_acn_advanced_automatic_crash_notification_aacn:
        null,
      automatic_pedestrian_alerting_sound_for_hybrid_and_ev_only: null,
      axle_configuration: null,
      axles: "2",
      backup_camera: "Optional",
      base_price: "16100",
      battery_current_amps_from: null,
      battery_current_amps_to: null,
      battery_energy_kwh_from: null,
      battery_energy_kwh_to: null,
      battery_type: null,
      battery_voltage_volts_from: null,
      battery_voltage_volts_to: null,
      bed_length_inches: null,
      bed_type: null,
      blind_spot_intervention_bsi: null,
      blind_spot_warning_bsw: null,
      body_class: "Sport Utility Vehicle (SUV)/Multi-Purpose Vehicle (MPV)",
      brake_system_description: null,
      brake_system_type: null,
      bus_floor_configuration_type: "Not Applicable",
      bus_length_feet: null,
      bus_type: "Not Applicable",
      cab_type: null,
      charger_level: null,
      charger_power_kw: null,
      cooling_type: null,
      crash_imminent_braking_cib: null,
      curb_weight_pounds: null,
      curtain_air_bag_locations: "1st and 2nd Rows",
      custom_motorcycle_type: "Not Applicable",
      daytime_running_light_drl: null,
      destination_market: null,
      displacement_cc: "1600.0",
      displacement_ci: "97.63799055157",
      displacement_l: "1.6",
      doors: "4",
      drive_type: "4x2",
      dynamic_brake_support_dbs: "Standard",
      electrification_level: null,
      electronic_stability_control_esc: "Standard",
      engine_brake_hp_from: "130",
      engine_brake_hp_to: null,
      engine_configuration: null,
      engine_manufacturer: "KMC",
      engine_model: "GDI",
      engine_number_of_cylinders: "4",
      engine_power_kw: null,
      engine_stroke_cycles: null,
      entertainment_system: null,
      ev_drive_unit: null,
      event_data_recorder_edr: null,
      forward_collision_warning_fcw: null,
      front_air_bag_locations: "1st Row (Driver and Passenger)",
      fuel_delivery_fuel_injection_type: null,
      fuel_type_primary: "Gasoline",
      fuel_type_secondary: null,
      gross_combination_weight_rating_from: null,
      gross_combination_weight_rating_to: null,
      gross_vehicle_weight_rating_from:
        "Class 1B: 3,001 - 4,000 lb (1,360 - 1,814 kg)",
      gross_vehicle_weight_rating_to: null,
      headlamp_light_source: null,
      keyless_ignition: null,
      knee_air_bag_locations: null,
      lane_centering_assistance: null,
      lane_departure_warning_ldw: null,
      lane_keeping_assistance_lka: null,
      manufacturer_name: "KIA CORPORATION",
      motorcycle_chassis_type: "Not Applicable",
      motorcycle_suspension_type: "Not Applicable",
      ncsa_body_type: "Station Wagon (excluding van and truck based)",
      ncsa_make: "KIA",
      ncsa_model: "Soul",
      ncsa_note: null,
      non_land_use: null,
      note: null,
      number_of_battery_cells_per_module: null,
      number_of_battery_modules_per_pack: null,
      number_of_battery_packs_per_vehicle: null,
      number_of_seat_rows: "2",
      number_of_seats: "5",
      number_of_wheels: "4",
      other_battery_info: null,
      other_bus_info: null,
      other_engine_info: null,
      other_motorcycle_info: null,
      other_restraint_system_info: null,
      other_trailer_info: null,
      parking_assist: null,
      pedestrian_automatic_emergency_braking_paeb: null,
      plant_city: "GWANG-JU",
      plant_company_name: null,
      plant_country: "SOUTH KOREA",
      plant_state: null,
      possible_values: "",
      pretensioner: null,
      rear_automatic_emergency_braking: null,
      rear_cross_traffic_alert: null,
      sae_automation_level_from: null,
      sae_automation_level_to: null,
      seat_belt_type: "Manual",
      seat_cushion_air_bag_locations: null,
      semiautomatic_headlamp_beam_switching: "Standard",
      series: "BASE",
      series2: "Wagon body style",
      side_air_bag_locations: "1st Row (Driver and Passenger)",
      steering_location: "Left-Hand Drive (LHD)",
      suggested_vin: "",
      tire_pressure_monitoring_system_tpms_type: "Direct",
      top_speed_mph: "125",
      track_width_inches: null,
      traction_control: "Standard",
      trailer_body_type: "Not Applicable",
      trailer_length_feet: null,
      trailer_type_connection: "Not Applicable",
      transmission_speeds: "6",
      transmission_style: null,
      trim: null,
      trim2: null,
      turbo: null,
      valve_train_design: "Dual Overhead Cam (DOHC)",
      vehicle_descriptor: "KNDJN2A2*H7",
      vehicle_type: "MULTIPURPOSE PASSENGER VEHICLE (MPV)",
      wheel_base_inches_from: "101.2",
      wheel_base_inches_to: null,
      wheel_base_type: null,
      wheel_size_front_inches: "16",
      wheel_size_rear_inches: "16",
      windows: null,
    },
    trims: [
      {
        id: 16932,
        make_model_id: 56,
        year: 2017,
        name: "!",
        description: "! 4dr Wagon (1.6L 4cyl Turbo 7AM)",
        msrp: 22800,
        invoice: 21561,
        created: "2023-06-29T21:03:31-04:00",
        modified: "2023-06-29T21:03:31-04:00",
        make_model: {
          id: 56,
          make_id: 13,
          name: "Soul",
          make: {
            id: 13,
            name: "Kia",
          },
        },
      },
      {
        id: 16931,
        make_model_id: 56,
        year: 2017,
        name: "+",
        description: "+ 4dr Wagon (2.0L 4cyl 6A)",
        msrp: 19800,
        invoice: 18764,
        created: "2023-06-29T21:03:31-04:00",
        modified: "2023-06-29T21:03:31-04:00",
        make_model: {
          id: 56,
          make_id: 13,
          name: "Soul",
          make: {
            id: 13,
            name: "Kia",
          },
        },
      },
      {
        id: 16930,
        make_model_id: 56,
        year: 2017,
        name: "Base",
        description: "4dr Wagon (1.6L 4cyl 6A)",
        msrp: 17700,
        invoice: 17123,
        created: "2023-06-29T21:03:31-04:00",
        modified: "2023-06-29T21:03:31-04:00",
        make_model: {
          id: 56,
          make_id: 13,
          name: "Soul",
          make: {
            id: 13,
            name: "Kia",
          },
        },
      },
      {
        id: 16929,
        make_model_id: 56,
        year: 2017,
        name: "Base",
        description: "4dr Wagon (1.6L 4cyl 6M)",
        msrp: 16100,
        invoice: 15757,
        created: "2023-06-29T21:03:31-04:00",
        modified: "2023-06-29T21:03:31-04:00",
        make_model: {
          id: 56,
          make_id: 13,
          name: "Soul",
          make: {
            id: 13,
            name: "Kia",
          },
        },
      },
    ],
  },
  vinDecoderResponse: {
    price: 0,
    price_currency: "USD",
    balance: {
      "API Decode": 1013,
      "API Stolen Check": 4,
      "API Vehicle Market Value": 5,
    },
    decode: {
      VIN: "KNDJN2A2XH7880168",
      "Vehicle ID": 2459,
      Make: "Kia",
      Model: "Soul",
      "Model Year": 2017,
      "Product Type": "Car",
      Body: "Sport Utility Vehicle (SUV)/Multi Purpose Vehicle (MPV)",
      Series: "BASE",
      Drive: "4x2",
      "Engine Displacement (ccm)": 1600,
      "Engine Power (kW)": 97,
      "Engine Power (HP)": 130,
      "Fuel Type - Primary": "Gasoline",
      "Engine Model": "GDI",
      "Number of Gears": 6,
      Manufacturer: "Kia Motors Corp",
      "Manufacturer Address":
        "781-1 Soha Dong Kwangmyung Shi, Kyungki-Do 423-701, Seoul Korea",
      "Plant City": "GWANG-JU",
      "Plant Country": "SOUTH KOREA",
      "Engine Cylinders": 4,
      "Number of Doors": 4,
      "Number of Seat Rows": 2,
      "Number of Seats": "5",
      Steering: "Left Hand Drive (LHD)",
      "Check Digit": "X",
      "Sequential Number": "880168",
    },
  },
};

