import React from 'react'
import Contactus from '../../MyComponents/ContactUs'

export default function ContactUS() {
  return (
    <div className='container my-5'>
      <Contactus />
    </div>
  )
}
