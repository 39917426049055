import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import English from './locales/English/translation.json';
import Arabic from './locales/Arabic/translation.json';
import Kurdish from './locales/Kurdish/translation.json';

let val=localStorage.getItem('cl') ? localStorage.getItem('cl') : 'English';
console.log('val',val);
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: val,
    supportedLngs: ["English", "Arabic","Kurdish"],
    resources: {
      English,
      Arabic,
      Kurdish
      },  
    interpolation: {
      escapeValue: false,
    },
  });
  
export default i18n;
