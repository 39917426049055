import { checkNullUndefineReturnEmpty } from "../../../utils/constants";

export const dataInFormatCheck = (result) => {
  let updatedResult = {};
  console.log(result && Object.keys(result.decoder).length > 5)
  if (result && Object.keys(result.decoder).length > 5) {
    updatedResult = {...result.decoder, found: true};
    updatedResult.year =
      updatedResult.year !== "N/A" && updatedResult.year !== ""
        ? updatedResult.year
        : "";
    updatedResult.engine =
      updatedResult.engine !== "N/A" && updatedResult.engine !== ""
        ? updatedResult.engine.substring(0, 4)
        : "";

    // Check auctions key in result object
    const auctions = result?.auctions || [];
    const requiredKeys = {
      color: "",
      cylinders: "",
      fuel: "",
      images: [],
    };
    if (auctions) {
      for (let i = 0; i < auctions.length; i++) {
        const item = auctions[i];
        if (item.color !== "N/A" && item.color !== "") {
          requiredKeys.color = item.color;
        }

        if (item.cylinders !== "N/A" && item.cylinders !== "") {
          requiredKeys.cylinders = item.cylinders;
        }

        if (item.images) {
          requiredKeys.images = [...requiredKeys.images, ...auctions?.[i]?.images];
        }

        if (item.fuel !== "N/A" && item.fuel !== "") {
          requiredKeys.fuel = item.fuel;
        }

        if (
          requiredKeys.color?.length > 0 &&
          requiredKeys.cylinders?.length > 0 &&
          requiredKeys.fuel?.length > 0
        ) {
          break;
        }
      }
    }
    updatedResult.color = requiredKeys.color;
    updatedResult.cylinders = requiredKeys.cylinders;
    updatedResult.fuel = requiredKeys.fuel;
    updatedResult.images = requiredKeys.images;

    updatedResult.abs = checkNullUndefineReturnEmpty(
      updatedResult.absBrakes,
      "boolean"
    );
    updatedResult.leatherSeats = checkNullUndefineReturnEmpty(
      updatedResult.leatherSeat,
      "boolean"
    );
    updatedResult.airbags = checkNullUndefineReturnEmpty(
      updatedResult.driverAirbag,
      "boolean"
    );
    updatedResult.keylessEntry = checkNullUndefineReturnEmpty(
      updatedResult.keylessEntry,
      "boolean"
    );
    updatedResult.sunroof = checkNullUndefineReturnEmpty(
      updatedResult.powerSunroof,
      "boolean"
    );
    updatedResult.cruiseControl = checkNullUndefineReturnEmpty(
      updatedResult.cruiseControl,
      "boolean"
    );
    updatedResult.powerLocks = checkNullUndefineReturnEmpty(
      updatedResult.powerDoorLocks,
      "boolean"
    );
    updatedResult.ac = checkNullUndefineReturnEmpty(
      updatedResult.airConditioning,
      "boolean"
    );
    updatedResult.heatedSteeringWheel = checkNullUndefineReturnEmpty(
      updatedResult.heatedSteeringWheel,
      "boolean"
    );
    // props.navigation.navigate('SellCarSuccessError', { value: checkStatus, isWithout: true, data: null, vinData: updatedResult})
  } else {
    updatedResult.found = false
  }
  return updatedResult
};
