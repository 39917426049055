import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {fetchStockDetail,fetchPopularItemsDetail,benefitsFuncation} from './api';

export const StockDetail = createAsyncThunk('form/fetchstockDetail', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchStockDetail();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const PopularItems = createAsyncThunk('form/fetchPopularItemsDetail', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchPopularItemsDetail();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const benefitsItem = createAsyncThunk('form/benefitsFuncation', async (_, { rejectWithValue }) => {
  try {
    const response = await benefitsFuncation();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const newcar = createSlice({
  name: 'newcar',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    stockdata:[],
    itemdata:[],
    benefitsdata:[],
  },
  reducers: {},
  extraReducers: builder => {
    builder
        //stock ............
      .addCase(StockDetail.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(StockDetail.fulfilled, (state,action) => {
        state.submitting = false;
        state.stockdata = action.payload.data;
        state.success=true;
        state.error = null;
      })
      .addCase(StockDetail.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      // popular item
        .addCase(PopularItems.pending, state => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(PopularItems.fulfilled, (state,action) => {
          state.submitting = false;
          state.itemdata = action.payload.data;
          state.success=true;
          state.error = null;
        })
        .addCase(PopularItems.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        })
        // benfites item
        .addCase(benefitsItem.pending, state => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(benefitsItem.fulfilled, (state,action) => {
          state.submitting = false;
          state.benefitsdata = action.payload.data;
          state.success=true;
          state.error = null;
        })
        .addCase(benefitsItem.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        })
    
  },
});
export default newcar.reducer;
