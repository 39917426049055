import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { dealerInfo } from "../../../Reducer/searchDealerPageSlice";
import { title } from "../../../data/content";
import OwlCarouselSection from "../../Tools/OwlCarouselSection";
import DealerCarInfo from "./DealerCarInfo";
import { useSearchParams } from "react-router-dom";
import {
  fetchDefaultSearch,
  fetchFilteredSearchResults,
} from "../../../Reducer/api";
export default function SearchResultpage() {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const [loading, setLoading] = useState(false);
  const [loadingCars, setLoadingCars] = useState(false);
  const [params] = useSearchParams();
  const dealerId = params.get("id");

  const dispatch = useDispatch();
  const responce = useSelector((state) => state.dealer.dealerarr);
  const auth = useSelector((state) => state.auth.auth);
  let data = { id: auth && auth.id ? auth.id : "" };
  const [dealerCars, setDealerCars] = useState(null);

  const getDealerCars = async () => {
    setLoadingCars(true);
    const cars = await fetchFilteredSearchResults({
      page: "",
      // limit: 10,
      currentPage: 0,
      brandId: null,
      ownerId: dealerId,
      setLoading: setLoadingCars,
    });
    setDealerCars(cars);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(dealerInfo({ dealerId, setLoading }));
  }, [dispatch]);

  useEffect(() => {
    getDealerCars();
  }, []);
  
  return (
    <div>
      {loading ? (
        <div
          class="d-flex justify-content-center"
          style={{ minHeight: "100vh", marginTop: "25vh" }}
        >
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <DealerCarInfo responce={responce?.docs?.[0]} dealerCars={dealerCars?.data || []} />
      )}
      <br />
      <br />
      {loadingCars ? (
        <div
          class="d-flex justify-content-center"
          style={{ minHeight: "25vh", marginTop: "25vh" }}
        >
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <OwlCarouselSection
          responce={dealerCars?.data || []}
          title1={"All cars"}
          title2={"View all cars"}
          rtl={rtl}
          forComponent={"singleDealer"}
          adsArray={dealerCars?.allAdIds}
        />
      )}
    </div>
  );
}
