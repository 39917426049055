import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Tools/Button";
import { useDispatch, useSelector } from "react-redux";
import Details from "./details";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { clearStatus, make_payment } from "../../../Reducer/paymentSlice";
import { errorSms } from "../../../data/content";
import { toast } from "react-toastify";

//stripe
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { apiManager } from "../../../api/connectAPI";

export default function Payments({ onClick, start, details }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const submitting = useSelector((state) => state.payment.submitting);
  const payment = useSelector((state) => state.payment.payment);
  const error = useSelector((state) => state.payment.error);
  const success = useSelector((state) => state.payment.success);
  const require = t("This field is requried");
  const [errorMsg, setErrorMsg] = useState(""); // custom erorr message
  const schema = Yup.object().shape({
    card_number: Yup.string()
      .required(require)
      .min(8, "Must be exactly 8 digits")
      .max(19, "Must be exactly 19 digits"),
    card_name: Yup.string()
      .required(require)
      .min(5, "Must be exactly 5 digits")
      .max(10, "Must be exactly 10 digits"),
    expiry: Yup.string().required(require),
    code: Yup.string()
      .required(require)
      .min(3, "Must be exactly 3 digits")
      .max(3, "Must be exactly 3 digits"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onError = (errors) => {
    console.log(errors);
  };

  if (success == true) {
    toast.success(t(payment.message));
    dispatch(clearStatus());
    onClick(4, start, details);
  }

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
      default:
        toast.error(t(errorSms.first));
        break;
    }
  }

  const stripe = useStripe();
  let elements = useElements();

  const [paymentLoad, setPaymentLoad] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setPaymentLoad(true);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setPaymentLoad(false);
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        // return_url: "https://example.com/order/123/complete",
      },
    });

    console.log(result);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setErrorMsg(t("card_declined"));
    } else {
      setPaymentLoad(false);
      if (result?.paymentIntent?.status === "succeeded") {
        apiManager("POST", `user/confirmPayment?lang=${lang}`, {
          adId: details?._id,
        })
          .then((res) => {
            if (res.status) {
              onClick(4, start, details);
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <div className="container min-vh-100">
      <div>
        <h6 class="back">
          <Button
            title={
              <>
                <img src="assets/images/backbt.png" /> {t("Go back")}
              </>
            }
          />
        </h6>
        <div class="enquery">
          <h5>
            {t("Selected Plan")}
            <span className="">
              <span
                style={{ width: "fit-content" }}
                className="fw-semibold packName"
              >
                {t(details?.pack?.name)}
              </span>
              <span
                style={{ width: "fit-content", marginTop: "0px" }}
                className=" packName text-wrap"
              >
                {t(details?.pack?.description)}
              </span>
            </span>
            <a
              class="cngplan cngplanNew"
              onClick={() => {
                onClick(2, start, details);
              }}
            >
              {t("Change Plan")}
              <img src="assets/images/arrow-narrow-right.png" alt="img" />
            </a>
          </h5>

          <div class="row">
            <div className="col-lg-6">
              <Details details={details} />
            </div>
            <div class="col-lg-6">
              <div class="st">
                <form class="mb-0" onSubmit={onSubmit}>
                  <div class="row">
                    <PaymentElement />

                    <div class="col-12">
                      <Button
                        type="submit"
                        disabled={paymentLoad}
                        title={
                          !paymentLoad ? t("Make Payment") : t("Please wait...")
                        }
                      />
                    </div>
                  </div>
                  <p className="helperText text-center">{errorMsg}</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
