import React from "react";
import { useTranslation } from "react-i18next";
import SelectOptions from "../../Tools/SelctOptions";
import SelectWithCategory from "../SelectWithCategory";
import SelectCustomItems from "../SelectCustomItems";
import i18n from "../../../i18n";
export default function LeftSideBar({
  type,
  title,
  allFilters,
  setAllFilters,
  models,
  data,
}) {
  const { t } = useTranslation();

  return (
    <div class="set">
      <h4>{title}</h4>
      <div class="row">
        {/* {basic_select_box &&
          basic_select_box.map((item, index) => {
            if(type === 'Rentals' && skip.includes(item.field)){
              return null
            }

            return (
              <div class="col-6">
                <p>{t(item?.name)}</p>
                <SelectOptions
                  style={"form-control"}
                  placeholder={item?.field === 'cities' ? t('National') : t('Any')}
                  selectedOption={""}
                  onChange={(e) => handleChange(e, item.field)}
                  name={item?.field}
                  values={item.field === 'model' ? models : item?.data}
                  // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
                />
              </div>
            );
          })} */}

        <div class="col-6">
          <p>{t("Cities")}</p>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.city?.[0] || t('National')}
            onChange={(e) =>
              setAllFilters({ ...allFilters, city: [e.target.value] })
            }
            name={"cities"}
            values={data?.cities}
            // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
          />
        </div>

        <div class="col-6">
          <p>{t("Make")}</p>
          {/* <SelectWithCategory
            style={"form-control"}
            selectedOption={t("Any")}
            onChange={(e) => setAllFilters({ ...allFilters, make: e.target.value })}
            name={data?.field}
            values1={data?.make?.slice(0, 8)}
            values2={data?.make}
            boxStyle={{
              padding: '10px'
            }}
            title1={t('Most searched for')}
            title2={t('All makes')}
          /> */}
          <SelectCustomItems
            selectedOption={allFilters.make}
            // items1={data?.make?.slice(0, 5)}
            items2={data?.make}
            // title1={"Most searched for"}
            title2={"All makes"}
            placeholder={t("Any")}
            handelChange={(e) =>
              setAllFilters({ ...allFilters, make: e?.value })
            }
            showDropdownIcon
            width={'100%'}
          />
        </div>
        <div class="col-6">
          <p>{t("Model")}</p>
          <SelectOptions
            style={"form-control"}
            selectedOption={t("Any")}
            onChange={(e) =>
              setAllFilters({ ...allFilters, model: e.target.value })
            }
            name={data?.field}
            values={models}
            disabled={models?.length == 0}
          />
        </div>
        <div class="col-6">
          <p>{t("Regional Specs")}</p>
          <SelectOptions
            style={"form-control"}
            // placeholder={t('Any')}
            selectedOption={allFilters?.regional?.[0] || t('Any')}
            onChange={(e) =>
              setAllFilters({ ...allFilters, regional: [e.target.value] })
            }
            name={data?.field}
            values={data?.regional_space}
            // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
          />
        </div>
        {type !== "Rentals" && (
          <div class="col-6">
            <p>{t("Body conditon")}</p>
            <SelectOptions
              style={"form-control"}
              selectedOption={allFilters?.condition?.[0] || t("Any")}
              onChange={(e) =>
                setAllFilters({ ...allFilters, condition: [e.target.value] })
              }
              name={data?.field}
              values={data?.body}
              // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
            />
          </div>
        )}
        {type !== "Rentals" && (
          <div class="col-6">
            <p>{t("Paint")}</p>
            <SelectOptions
              style={"form-control"}
              selectedOption={allFilters?.paint?.[0] || t("Any")}
              onChange={(e) =>
                setAllFilters({ ...allFilters, paint: [e.target.value] })
              }
              name={data?.field}
              values={data?.paint}
              // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
            />
          </div>
        )}
      </div>
    </div>
  );
}
