import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../../Tools/FormInput";
import Button from "../../Tools/Button";
import { useDispatch, useSelector } from "react-redux";
import SellFind from "../../Tools/SellFind";
import SellFindModel from "../../Tools/SellFindModel";
import { error } from "jquery";
import { bannerCheck, paths } from "../../../utils/paths";

export default function CheckMyCar({ step, setStep, setVin, vin }) {
  const navigate = useNavigate();
  const location = useLocation();
  const udata = useSelector((state) => state.auth);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const [step, setStep] = useState(1);
  // const [startup, setStart] = useState("FirstChildVIn");
  // const [details, setDetails] = useState({});

  const schema = Yup.object().shape({
    chasis: Yup.string()
      .required(t("Chassis number is required"))
      .min(17, t("Chassis number must be exactly 17 characters"))
      .max(17, t("Chassis number must be exactly 17 characters")),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const onError = (errors) => {
    console.log(errors);
  };
  const onSubmit = async (data) => {
    data = { ...data, chasis: data.chasis.toUpperCase() };
    if (!udata.token) {
      localStorage.setItem("vinData", JSON.stringify(data));
      navigate(paths.login, { state: { backPage: location.pathname } });
      return;
    }

    if (loading == false) {
      setVin(data);
      // setStep(2);
      navigate(paths.payment, { state: { data: data } });
    }
  };

  useEffect(() => {
    const vinData = JSON.parse(localStorage.getItem("vinData"));
    localStorage.removeItem("vinData");
    if (vinData) {
      console.log(vinData);
      setVin(vinData);
      // setStep(2);
      navigate(paths.payment, { state: { data: vinData } });
    }
  }, [localStorage]);
  
  // var data = { startpage: startup, backpage: "", data: details };

  // if (location.state && location.state.startpage != "") {
  //   data.startpage = location.state.startpage;
  //   data.backpage = location.state.backpage;
  //   data.data = location.state.data;
  // }

  // const handleClick = (steps, from, data) => {
  //   setStep(steps);
  //   setStart(from);
  //   setDetails(data);
  // };

  // console.log(data);
  if (localStorage.getItem("vinData")) {
    return <div className="min-vh-100"></div>;
  }
  return (
    <div class="container">
      <img
        class="w-100"
        src={bannerCheck ? bannerCheck : "../assets/images/check_my_car.png"}
        alt="img"
      />
      <div class="">
        {/* <h2>{t('Check your Car')}</h2> */}
        {/* <FirstStep
            data={data}
            onClick={handleClick}
            page={"CheckYourCar"}
            title={"Check your Car"}
          /> */}
        <div attr={"Check your Car"} class={"check_my_car"}>
          <h6 class="back">
            <NavLink to={paths.home}>
              <img src="../assets/images/backbt.png" /> {t("Go back")}
            </NavLink>
          </h6>

          <form
            className={"set incell position-relative"}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <h5>
              {t("Check Your Car")}
              <span
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFindVin"
              >
                <img src="../assets/images/i_tp.png" className="px-1" />
              </span>
            </h5>
            <div class="form-group position-relative ft pb-1 text-center">
              <FormInput
                style={"form-control chasis"}
                intype={"text"}
                event={register}
                name={"chasis"}
                placeholder={t("Chassis number (VIN)")}
              />
              {errors.chasis && (
                <p
                  className="text-danger text-center"
                  style={{ fontSize: "14px", marginTop: "-5px" }}
                >
                  {errors.chasis.message}
                </p>
              )}
            </div>
            <Button
              style="Search_VIN"
              title={
                loading == false ? t("Search VIN Number") : t("Please wait...")
              }
              type="submit"
            />
          </form>
          <SellFind />
          <SellFindModel />
        </div>
      </div>
    </div>
  );
}
