import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import { compareSection } from "../../../data/content";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { isImageOrVideo } from "../../../utils/constants";
import ReactImageGallery from "react-image-gallery";
export default function ImageView({ data }) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const [modal, setModal] = useState(false);
  const handleCloseModal = () => {
    setModal(false);
  };
  const openModal = (item, i) => {
    const newArr = item?.uploads?.map(({s3URL}) => ({ original: s3URL, thumbnail: s3URL, type: isImageOrVideo(s3URL) }))
    setImages(newArr)
    setImage(i);
    console.log(newArr)
    setModal(true);
  };

  const [image, setImage] = useState("");
  const [images, setImages] = useState();
  console.log(images)
  return (
    <>
      {data.map((item, index) => {
        return (
          <div class="col-md-12 ">
            <div class="allimage">
              <h4 class="mb-0">
                {t(item?.properties?.basicDetails?.make?.[lang])}{" "}
                {t(item?.properties?.basicDetails?.model?.[lang])}
              </h4>
              <p>{item?.uploads?.length + " " + t("Images")}</p>

              <OwlCarousel
                className={`owl-carousel allimage_carousel owl.carousel.min owl-theme ${
                  rtl && "invertButtons"
                }`}
                {...compareSection}
                rtl={rtl}
                // nav
              >
                {item?.uploads?.map(({s3URL}, i) => {
                  const items = s3URL
                  const type = isImageOrVideo(items);
                  return (
                    <div
                      class="item"
                      style={{ cursor: "pointer" }}
                      onClick={() => openModal(item, i)}
                    >
                      {type === "video" ? (
                        <video
                          controls={false}
                          className="rounded-2"
                          style={{
                            objectFit: "cover",
                            marginBottom: "-7px",
                            width: "100%",
                            height: '220px'
                          }}
                        >
                          <source
                            src={items || "assets/images/notFound.png"}
                          />
                        </video>
                      ) : (
                        <div
                          class="img"
                          style={{
                            backgroundImage: `url(${items})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></div>
                      )}
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        );
      })}
      <Modal
        size="lg"
        isOpen={modal}
        fullscreen
        centered
        toggle={handleCloseModal}
      >
        {/* <ModalHeader className="d-flex justify-content-end">
          <img
            src="/assets/images/close.png"
            height={25}
            onClick={() => handleCloseModal()}
            style={{ cursor: "pointer" }}
            alt=""
          />
        </ModalHeader> */}

        <ModalBody
          className="d-flex justify-content-center align-items-center"
          style={{ position: "relative", padding: "0xp" }}
        >
          <div
            className="bg-light p-2 rounded-2"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 999,
            }}
          >
            <img
              src="/assets/images/Close.png"
              height={25}
              onClick={() => handleCloseModal()}
              style={{ cursor: "pointer" }}
              alt=""
            />
          </div>
          <ReactImageGallery
            startIndex={image}
            items={images || []}
            renderItem={(e) => {
              const item = e?.original;
              switch (e.type) {
                case "image":
                  return (
                    <img
                      style={{
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                        height: "100vh",
                      }}
                      src={item ? item : "../assets/images/notFound.png"}
                      // onError={() => filterImageArray(item)}
                      alt="bg"
                    />
                  );
                case "video":
                  return (
                    <video
                      controls
                      className="rounded-2"
                      // height={400}
                      style={{
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                        height: "100vh",
                      }}
                    >
                      <source src={item} />
                    </video>
                  );
              }
            }}
            showThumbnails={false}
            showBullets={false}
            showFullscreenButton={false}
            showPlayButton={false}
            isRTL={i18n.language === "English" ? false : true}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
