import React from 'react'
import Cookies from '../../MyComponents/Cookies'

export default function index() {
  return (
    <div className='container mb-5'>
      <Cookies />
    </div>
  )
}
