import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import { beginWithImage, findUploads, isImageOrVideo } from "../../../utils/constants";
import { t } from "i18next";
export default function CompareItem({ item, style, index, onChange }) {
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const handleClick = () => {
    onChange(index);
  };

  const [adImages, set_all_images] = useState([]);
  useEffect(() => {
    if (item) {
      const ad = item;
      let all_list = findUploads(ad);
      set_all_images(all_list);
    }
  }, [item]);
  const firstImage = adImages?.[0]?.thumbnail 
  const type = isImageOrVideo(adImages?.[0]?.thumbnail);

  return (
    <div class={style}>
      <div class="set">
        {type === 'video' ? (
          <div
            class="img"
            style={{
              backgroundImage: `url(${firstImage})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: "7px",
              backgroundSize: "cover",
              // height: '100%'
            }}
          >
            <video
              controls={false}
              className="rounded-2"
              // height={400}
              style={{
                objectFit: "cover",
                marginBottom: "-7px",
                width: "100%",
                height: '100%'
              }}
            >
              <source src={firstImage || "assets/images/notFound.png"} />
            </video>
            <span class="close">
              <i class="fa-solid fa-xmark" onClick={() => handleClick()}></i>
            </span>
          </div>
        ) : (
          <div
            class="img"
            style={{
              backgroundImage: `url(${firstImage})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: "7px",
              backgroundSize: "cover",
            }}
          >
            <span class="close">
              <i class="fa-solid fa-xmark" onClick={() => handleClick()}></i>
            </span>
          </div>
        )}
        <h4 className="pt-3 pt-sm-0 mb-2">
          {t(item?.properties?.basicDetails?.make?.[lang])}{" "}
          {t(item?.properties?.basicDetails?.model?.[lang])}
        </h4>
      </div>
    </div>
  );
}
