import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
const Pagination = ({
  style,
  next,
  previous,
  pageCount,
  rangeDisplayed,
  handlePageClick,
  forcePage,
}) => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [forcePage])

  return (
    <ReactPaginate
      className={style}
      previousLabel="<"
      nextLabel=">"
      forcePage={forcePage - 1}
      breakLabel={"..."}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={rangeDisplayed}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      activeClassName={"active"}
    />
  );
};

export default Pagination;
