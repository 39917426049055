import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import FindCar from "../../Tools/FindCar";
import ThiredStep from "../../Tools/SRPages/ThirdStep";
import Final from "../../Tools/SRPages/Final";
import FirstStep from "../../Tools/SRPages/FirstStep";
import SecondStep from "../../Tools/SRPages/SecondStep";
import { title } from "../../../data/content";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { paths } from "../../../utils/paths";
import { ldSchemas } from "../../../utils/json-schemas";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";

export default function RentYourCar() {
  const navigate = useNavigate();
  const location = useLocation();
  const udata = useSelector((state) => state.auth);
  useEffect(() => {
    if (!udata.token) {
      navigate(paths.login, { state: { backPage: location.pathname } });
    }
  }, []);
  const [reset, setReset] = useState(false);
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(1);
  const [startup, setStart] = useState("FirstChildVIn");
  const [details, setDetails] = useState({});

  var data = { startpage: startup, backpage: "", data: details };

  if (location?.state && location?.state?.startpage != "") {
    data.startpage = location.state.startpage;
    data.backpage = location.state.backpage;
    data.data = location.state.data;
  }

  const handleClick = (steps, from, data) => {
    setStep(steps);
    setStart(from);
    setDetails(data);
  };

  const page = () => {
    switch (step) {
      case 1:
        return (
          <FirstStep
            data={data}
            onClick={handleClick}
            page={"RentYourCar"}
            reset={reset}
          />
        );
      case 2:
        return (
          <SecondStep
            onClick={handleClick}
            start={startup}
            details={details}
            page={"RentYourCar"}
          />
        );
      case 3:
        return (
          <ThiredStep
            onClick={handleClick}
            start={startup}
            details={details}
            page={"RentYourCar"}
          />
        );
      case 4:
        return (
          <Final setStep={setStep} page={"RentYourCar"} setReset={setReset} />
        );
    }
  };

  return (
    <>
      <FindCar element={page()} title={"Rent Your Car"} step={step} />
      <JsonLDSchemaHandler data={ldSchemas?.rentYourCar} />
    </>
  );
}
