import React from "react";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import { homeOptions, homeSelect } from "../../../data/content";
import { NavLink } from "react-router-dom";
import ListItem from "../ListItem";
import OwlItem from "../OwlItem";
import { useSelector } from "react-redux";
import OwlItemCars from "../OwlItem/owlItemCars";
import { paths } from "../../../utils/paths";

export default function SelectViewCar({
  rtl,
  responce,
  responceList,
  title,
  path,
  selectBrand,
  handleClick,
  selectedCarType,
  page,
  adsArray,
}) {
  const loading = useSelector((state) => state.searched.loading);
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const { t, i18n } = useTranslation();
  const styles = { display: "none" };

  const getButtonText = () => {
    if (!selectedCarType) return t("View all cars");
    const name = selectedCarType?.name?.en;
    return t(`View all ${name} cars`);
  };
  return (
    <section class="recently_viewed most_searched">
      <div class="container">
        <h2 class="hed">{title}</h2>
        <ul
          style={{ flexWrap: "unset", overflowX: "scroll" }}
          class="nav nav-tabs slides d-flex justify-content-start align-items-center searched-section-margin"
          id="myTab"
          role="tablist"
        >
          {responce?.map?.((item, key) => {
            return (
              <ListItem
                key={key}
                innerhtmlType={"button"}
                buttonId={"btn-" + item?.id}
                style={"nav-item mx-2"}
                inlineStyle={{
                  // flexWrap: 'unset'
                  width: "200px",
                  display: "inline-block",
                }}
                onClick={() => selectBrand("#btn-", item)}
                buttonStyle={"nav-link sl w-100"}
                buttonTitle={
                  <div>
                    <img src={item?.image} alt="img" />
                    <span>{item?.name?.[lang]}</span>
                  </div>
                }
              ></ListItem>
            );
          })}
        </ul>
        {/* <OwlCarousel
          className="nav nav-tabs owl-carousel hat_carousel owl.carousel.min owl-theme owl-loaded owl-drag"
          id="myTab"
          role="tablist"
          style={styles}
          // {...homeSelect}
          rtl={rtl}
          margin={10}
        >
          {responce.map((item, key) => {
            return (
              <ListItem
                key={key}
                innerhtmlType={"button"}
                buttonId={"btn-m" + item.id}
                style={"nav-item"}
                onClick={() => selectBrand("#btn-m", item)}
                buttonStyle={
                  item?.name?.["en"] == "SUV"
                    ? "nav-link sl active"
                    : "nav-link sl"
                }
                buttonTitle={
                  <>
                    <img src={item?.image} alt="img" />
                    <span>{item?.name?.[lang]}</span>
                  </>
                }
              ></ListItem>
            );
          })}
        </OwlCarousel> */}
        {!loading > 0 ? (
          <>
            {responceList && responceList.length > 0 ? (
              <>
                <OwlCarousel
                  className={`owl-carousel Recently_carousel owl.carousel.min owl-theme ${
                    rtl && "invertButtons"
                  }`}
                  {...homeOptions}
                  rtl={rtl}
                  // margin={10}
                  nav
                >
                  <OwlItemCars
                    cars={responceList}
                    rentType={"daily"}
                    // filters={{ bodyType: [selectedCarType?.name?.en || ""] }}
                    adsArray={adsArray}
                  />
                </OwlCarousel>
                <div class="w-100 float-left text-center">
                  <NavLink
                    className={"viewall"}
                    to={page === "normal" ? paths.buyCar : paths.rentCar}
                    state={{
                      filters: {
                        bodyType: selectedCarType?.name?.["en"]
                          ? [selectedCarType?.name?.["en"]]
                          : "",
                      },
                    }}
                  >
                    {getButtonText()}
                  </NavLink>
                </div>
              </>
            ) : (
              <>
                <p className="fs-5 text-center" style={{ maxWidth: "unset" }}>
                  {t("No cars of this type available")}
                </p>
              </>
            )}
          </>
        ) : (
          <>
            <div></div>
            <div
              style={{ height: "543px" }}
              className="container d-flex justify-content-center align-items-center"
            >
              <a disabled>
                <span
                  class="spinner-grow spinner-grow"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden" role="status">
                  Loading...
                </span>
              </a>
            </div>
            <div></div>
          </>
        )}
      </div>
    </section>
  );
}
