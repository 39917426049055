import React from "react";
import CareersComponent from "../../MyComponents/CareersComponent";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";

export default function Careers() {
  return (
    <>
      <CareersComponent />
      <JsonLDSchemaHandler data={ldSchemas?.careers} />
    </>
  );
}
