import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "./Banner";
import ElectricCarsType from "./ElectricCarsType";
import { title } from "../../../data/content";
import BrandSection from "../../Tools/BrandSection";
import PopularModal from "../../Tools/PopularModal";
import ComingSoon from "../../ComingSoon";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
export default function ElectricCars() {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }

  return (
    <>
      <ComingSoon />
      {/* <Banner/>
        <ElectricCarsType rtl={rtl}/>
        <BrandSection brandTitle={t('Popular electric car brands')}/>
        <PopularModal rtl={rtl}/> */}

      <JsonLDSchemaHandler data={ldSchemas?.electricCars} />
    </>
  );
}
