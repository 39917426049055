import React from "react";
import { useTranslation } from "react-i18next";
import ListItem from "../../Tools/ListItem";
import { TempBaseUrl } from "../../../data/content";
import { useState } from "react";
export default function CarBody2({ body_type, title, handleClick }) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const [selected, setSelected] = useState(body_type?.[0]?.id);
  return (
    <div class="strow">
      <h3>{title}</h3>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        {body_type &&
          body_type.map((item, index) => {
            return (
              <ListItem
                innerhtmlType={"html"}
                style={`nav-item ${selected === item?.id ? "active" : ""}`}
                id={`zfr${index}`}
                value={
                  <button
                    class="nav-link"
                    onClick={() => {
                      handleClick(item, `zf${index}`);
                      setSelected(item.id);
                    }}
                  >
                    <img src={item.image} alt="img" />
                    <span>
                      {item?.name?.[i18n.language] ||
                        item?.name?.[lang] ||
                        item.name}
                    </span>
                  </button>
                }
              ></ListItem>
            );
          })}
      </ul>
    </div>
  );
}
