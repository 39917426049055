import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import { appLinks } from "../../../data/content";
import { paths } from "../../../utils/paths";

function TestRoute() {
  // states
  const [loading, setLoading] = useState(true);

  const androidAppUrl =
    "https://play.google.com/store/apps/details?id=com.ako_sayara";
  const iosAppUrl = "https://apps.apple.com/in/app/ako-sayara/id6458545849";
  // const huaweiAppUrl = "https://appgallery.huawei.com/app/C110928665";
  const huaweiAppUrl =
    "https://play.google.com/store/apps/details?id=com.ako_sayara";

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let displayText = "";

  if (/android/i.test(userAgent)) {
    // Android user
    displayText = "Redirecting to Google Play Store";
    window.location.href = androidAppUrl;
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    // iOS user
    displayText = "Redirecting to Apple App Store";
    window.location.href = iosAppUrl;
  } else if (/huawei/i.test(userAgent) || /HarmonyOS/i.test(userAgent)) {
    // Huawei user
    displayText = "Redirecting to Huawei App Gallery";
    window.location.href = huaweiAppUrl;
  } else {
    // Default fallback
    displayText = "Unable to redirect, please visit the app store manually.";
    window.location.href = paths.home
  }

  const openLink = (type) => {
    const link = appLinks?.[type];
    window.open(link);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="d-flex gap-4 flex-column justify-content-center align-items-center min-vh-100">
      {loading ? (
        <Icon
          icon="eos-icons:loading"
          fontSize={170}
          style={{ color: "#4c7ee8" }}
        />
      ) : (
        <>
          <p
            className="text-center"
            style={{ fontSize: "20px", fontWeight: 500 }}
          >
            {displayText}
          </p>

          {/* {displayText.includes('')} */}
          {displayText.includes("manually") && (
            <div class=" d-flex justify-content-center gap-2 flex-wrap">
              <div role="button" className="">
                <img
                  onClick={() => openLink("apple")}
                  class="w-100"
                  src="../assets/images/apple-store.svg"
                  alt="app"
                />
              </div>
              <div role="button" className="">
                <img
                  onClick={() => openLink("google")}
                  className="w-100"
                  src="../assets/images/google-store.svg"
                  alt="app"
                />
              </div>
              <div role="button" className="">
                <img
                  onClick={() => openLink("huawei")}
                  className="w-100"
                  src="../assets/images/huawei-store.svg"
                  alt="app"
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TestRoute;
