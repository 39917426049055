import React, { useState, useEffect } from "react";

const CountdownTimer = ({ initialSeconds, isActive, setIsActive, taskAfterCountdown }) => {
  const [seconds, setSeconds] = useState(initialSeconds);


  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      setIsActive(false);
      if(taskAfterCountdown) {
        taskAfterCountdown()
      }
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return <p style={{ width: "20px", textWrap: "nowrap", textAlign: "center" }}>{seconds}</p>;
};

export default CountdownTimer;
