import React from "react";
import { Popover, PopoverBody } from "reactstrap";

export default function PopoverTool({ id, open, close, children, className }) {
  return (
    <Popover
      isOpen={open}
      toggle={close}
      placement="bottom"
      target={`${id}`}
      trigger="focus"
      className={`popover-tool ${className}`}
    >
      <PopoverBody>{children}</PopoverBody>
    </Popover>
  );
}
