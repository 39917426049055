import React from "react";
const ImageBox = ({
  value,
  style,
  path,
  onClick,
  singleImageStyle,
  imageHeight,
  imageClasses,
  titleStyles,
  inlineStyles
}) => {
  return (
    <div className={style} style={{inlineStyles}} onClick={onClick}>
      <div class="set" style={singleImageStyle}>
        <img
          src={path}
          className={imageClasses}
          alt="img"
          style={{ objectFit: "contain" }}
        />
        <h6
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            ...titleStyles,
            lineHeight: 'unset',
            marginTop: '-2px',
          }}
        >
          {value}
        </h6>
      </div>
    </div>
  );
};
export default ImageBox;

