import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "./Banner";
import Feature from "./Feature";
import { title } from "../../../data/content";
import BrandSection from "../../Tools/BrandSection";
import PopularModal from "../../Tools/PopularModal";
import { useEffect } from "react";
import { useState } from "react";
import { getEntityTypes, getMake } from "../../../Reducer/api";
import ComingSoon from "../../ComingSoon";
export default function CheapCars() {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState(null);

  const getCarTypes = async () => {
    const res = await getEntityTypes();
    setCarTypes(res?.data);
    setSelectedCarType(res?.data?.[0]);
  };

  // useEffect(() => {
  //   getCarTypes();
  // }, []);
  // const [makes, setMakes] = useState([]);
  // useEffect(() => {
  //   getMake().then((res) => {
  //     // res = res?.map((v) => ({ label: v, value: v }));
  //     setMakes(res?.makes);
  //   });
  // }, []);

  return <ComingSoon />

  // return (
  //   <>
  //     <Banner carTypes={carTypes} makes={makes} setMakes={setMakes} />
  //     {/* <Feature /> */}
  //     <BrandSection brandTitle={t("Browse by brand")} makes={makes} setMakes={setMakes} />
  //     <PopularModal rtl={rtl} />
  //   </>
  // );
}
