import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlans } from "../../../Reducer/planSlice";
import PlanCard from "../PlansCard";
import { generateFreeInvoice } from "../../../Reducer/api";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../utils/paths";

export default function Plan({
  onClick,
  start,
  details,
  page,
  renew,
  renewCallback,
  isUpdate,
}) {
  if (!details) details = {};
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const { t, i18n } = useTranslation();
  const plans = useSelector((state) => state.plans.plans);
  const submitting = useSelector((state) => state.plans.submitting);
  const dispatch = useDispatch();
  const [pack, setPack] = useState({});
  const userType = localStorage.getItem("userType");
  useEffect(() => {
    dispatch(fetchPlans({ page: page, type: userType }));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(pack).length !== 0) {
      details = { ...details, pack: pack };
      setLoading(true);
      generateFreeInvoice({
        adId: details?._id,
        planId: pack?._id,
        isRenew: renew,
      }).then((res) => {
        if (renew || isUpdate) {
          renewCallback?.();
        } else {
          onClick(4, start, details);
        }
        setLoading(false);
      });
      // if (userType === "dealer") {
      // generateFreeInvoice({
      //   adId: details?._id,
      //   planId: pack?._id,
      // })
      //   // .then(res => {
      //   //   if(res.status) {
      //   //   }
      //   // })
      //   onClick(4, start, details);
      // } else {
      //   onClick?.(3, start, details);
      // }
    }
  }, [pack]);


  return (
    <>
      <h6 class="back">
        <Button
          onClick={() => {
            if (renew) {
              navigate(paths.myVehicles);
            } else {
              onClick?.(1, start, details);
            }
          }}
          title={
            <>
              <img src="assets/images/backbt.png" /> {t("Go back")}
            </>
          }
        />
      </h6>
      <div class="set basic">
        <div class="row ad_plan">
          {!loading && !submitting && plans && plans?.plans?.length > 0 ? (
            [...plans?.plans]?.reverse()?.map((item, index) => {
              return (
                <PlanCard
                  item={item}
                  onClick={setPack}
                  key={index}
                  details={details}
                  page={page}
                />
              );
            })
          ) : (
            <div
              class="d-flex justify-content-center"
              style={{ minHeight: "100vh", marginTop: "25vh" }}
            >
              <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
