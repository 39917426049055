import React from "react";
import { useTranslation } from "react-i18next";
import sortBy from "../../../data/sortBy.json";
import ReactSelectCustom from "../../Tools/ReactSelectCustom";
export default function ShortBy({ previous, next, setSortVal }) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <h6 class="SortBy sppt align-items-center">
        <span class="aro">
          <a onClick={previous}>
            <i class="fa-solid fa-angle-left"></i>
          </a>
          <a onClick={next}>
            <i class="fa-solid fa-angle-right"></i>
          </a>
        </span>
        {/* <span className="d-sm-online d-none">{t("Showing dealers")}</span> */}
        <span class="sort d-flex justidy-content-between align-items-center">
          <img src="../assets/images/SortBy.png" className="mx-1" alt="img" />
          {/* <select>
            <option>{t("Latest")}</option>
            <option>{t("Oldest")}</option>
            <option>{t("Name")}↑</option>
            <option>{t("Name")}↓</option>
          </select> */}
          <ReactSelectCustom
            values={sortBy.dealerSort}
            width={"180px"}
            boxWidth={"140px"}
            placeholder={""}
            selectedOption={t("Latest")}
            onChange={(e) =>
              setSortVal({
                sort: e.item.sort,
                order: e.item.order,
              })
            }
          />
        </span>
      </h6>
    </div>
  );
}
