import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCarByChaice } from "./api";
import { findMyCarDataStatic, carVinMixData } from "../data/content";

export const fromChacie = createAsyncThunk(
  "chacie/fetchChacie",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchCarByChaice(formData);
      if(response.status) {
        return response.data
      } else {
        return rejectWithValue(response.message)
      }
      // if (formData.type === "check") {
      //   return findMyCarDataStatic.data;
      // } else {
      //   return carVinMixData;
      // }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const chacieSlice = createSlice({
  name: "chacie",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    chacie: [],
  },
  reducers: {
    clearStatus: (state) => {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fromChacie.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fromChacie.fulfilled, (state, action) => {
        state.submitting = false;
        state.chacie = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(fromChacie.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export const { clearStatus } = chacieSlice.actions;
export default chacieSlice.reducer;
