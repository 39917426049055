import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUploadFormData } from './api';

export const fetchUploadFormDetails = createAsyncThunk('uploadList/fetchUploadFormDetails', async (_, { rejectWithValue }) => {
  try {
    const searched = await fetchUploadFormData();
    return searched.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const uploadFormSlice = createSlice({
  name: 'upload_list',
  initialState: {
    upload_list: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUploadFormDetails.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUploadFormDetails.fulfilled, (state, action) => {
        state.upload_list = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchUploadFormDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default uploadFormSlice.reducer;
