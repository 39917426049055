import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import SelectOptions from "../../Tools/SelctOptions";
import Button from "../../Tools/Button";
import Specification from "../../Tools/AdvanceSearch/specification";
import $ from "jquery";
import Fuel from "../../Tools/AdvanceSearch/fuel";
import CarBody from "../../Tools/AdvanceSearch/carBody";
import LeftSideBar from "../../Tools/AdvanceSearch/leftSideBar";
import Perfomance from "../../Tools/AdvanceSearch/perfomance";
import { rentalSearchDataSet } from "../../../Reducer/rentalSerchSlice";
import ListItem from "../../Tools/ListItem";
import CarBody2 from "../../Tools/AdvanceSearch/carBody2";
import i18n from "../../../i18n";
import { paths } from "../../../utils/paths";

export default function RentalSearch({
  selectedOption,
  setSelectedOption,
  data,
  allFilters,
  setAllFilters,
  models,
  showFilteredValues,
  handleClick,
  setSelectedFilters,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataSet = useSelector((state) => state.rental_search.dataSet);
  const loading = useSelector((state) => state.rental_search.loading);
  const error = useSelector((state) => state.rental_search.error);
  const navigate = useNavigate();
  const [selection, setValues] = useState({});
  useEffect(() => {
    dispatch(rentalSearchDataSet());
  }, [dispatch]);

  const handleChange = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setValues({
      ...selection,
      [name]: value,
    });
  };

  const handleSearch = (e) => {
    navigate(paths.rentCar, { state: { filters: allFilters } });
  };

  const handleClickEvent = (a, b) => {
    $(".nav-item").removeClass("active");
    $("#" + b).addClass("active");

    setAllFilters({ ...allFilters, bodyType: [a?.name?.en] });
  };

  const [priceRadio, setPriceRadio] = useState("daily");
  const handleChangePriceRadio = (e) => {
    setPriceRadio(e.target.value);
    setAllFilters({ ...allFilters, rentType: e.target.value });
  };

  return (
    <section class="advance_search">
      <div class="container">
        <h6 className="back">
          <a onClick={() => navigate(-1)}>
            <img src="assets/images/backbt.png" />
            {t("Go back")}
          </a>
        </h6>
        <div class="advance_search_in">
          <div class="hd">
            <h5>{t("Advanced search")}</h5>
            <p class="pst" style={{ marginBottom: "1px" }}>
              {t("Search the largest choice of cars")}
            </p>

            <div className="d-flex justify-content-center justify-content-md-start w-100">
              <div className="row pt-sm-1 pt-3">
                <span
                  className="login"
                  style={{ width: "250px", zIndex: "10" }}
                >
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <ListItem
                      innerhtmlType={"button"}
                      key={1}
                      btnType={"button"}
                      onClick={() => {
                        setSelectedOption("Cars");
                        setSelectedFilters([])
                      }}
                      style={"nav-item"}
                      buttonStyle={
                        selectedOption === "Cars"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      buttonTitle={t("Cars")}
                    ></ListItem>
                    <ListItem
                      innerhtmlType={"button"}
                      key={0}
                      btnType={"button"}
                      onClick={() => {
                        setSelectedOption("Rentals");
                        setSelectedFilters([])
                      }}
                      style={"nav-item"}
                      buttonStyle={
                        selectedOption === "Rentals"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      buttonTitle={t("Rentals")}
                    ></ListItem>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div class="sidebar advancesidebat">
            <div class="sept fuel1">
              <LeftSideBar
                type={selectedOption}
                title={t("Basics")}
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                models={models}
                data={data}
              />
              <div class="set fuel1">
                <h4 style={{ marginBottom: "unset" }}>{t("Price")}</h4>
                <div class="row">
                  <div
                    class="btn-group w-100"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <div className="col-4">
                      <input
                        type="radio"
                        class="btn-check"
                        value={"daily"}
                        name="btnradioDMW"
                        onChange={(e) => handleChangePriceRadio(e)}
                        id="btnradioDMW1"
                        autocomplete="off"
                        checked={priceRadio === "daily"}
                      />
                      <label
                        class={`btn rental-price-radio ${
                          priceRadio === "daily" ? "active" : ""
                        }`}
                        style={{
                          height: "43px",
                          borderRadius:
                            i18n.language === "English"
                              ? "5px 0px 0px 5px"
                              : "0px 5px 5px 0px",
                          borderTop: "1px solid #606060",
                          borderBottom: "1px solid #606060",
                          borderLeft:
                            i18n.language === "English"
                              ? "1px solid #606060"
                              : "0px solid #606060",
                          borderRight:
                            i18n.language === "English"
                              ? "0px solid #606060"
                              : "1px solid #606060",
                        }}
                        for="btnradioDMW1"
                      >
                        {t("Daily")}
                      </label>
                    </div>
                    <div className="col-4">
                      <input
                        type="radio"
                        class="btn-check"
                        value={"weekly"}
                        name="btnradioDMW"
                        onChange={(e) => handleChangePriceRadio(e)}
                        id="btnradioDMW2"
                        autocomplete="off"
                        checked={priceRadio === "weekly"}
                      />
                      <label
                        class={`btn rental-price-radio ${
                          priceRadio === "weekly" ? "active" : ""
                        }`}
                        for="btnradioDMW2"
                        style={{
                          height: "43px",
                          borderRadius: "0px",
                          borderTop: "1px solid #606060",
                          borderBottom: "1px solid #606060",
                          borderLeft: "1px solid #606060",
                          borderRight: "1px solid #606060",
                        }}
                      >
                        {t("Weekly")}
                      </label>
                    </div>
                    <div className="col-4">
                      <input
                        type="radio"
                        class="btn-check"
                        value={"monthly"}
                        name="btnradioDMW"
                        onChange={(e) => handleChangePriceRadio(e)}
                        id="btnradioDMW3"
                        autocomplete="off"
                        checked={priceRadio === "monthly"}
                      />
                      <label
                        class={`btn rental-price-radio ${
                          priceRadio === "monthly" ? "active" : ""
                        }`}
                        for="btnradioDMW3"
                        style={{
                          height: "43px",
                          borderRadius:
                            i18n.language === "English"
                              ? "0px 5px 5px 0px"
                              : "5px 0px 0px 5px",
                          borderTop: "1px solid #606060",
                          borderBottom: "1px solid #606060",
                          borderLeft:
                            i18n.language === "English"
                              ? "0px solid #606060"
                              : "1px solid #606060",
                          borderRight:
                            i18n.language === "English"
                              ? "1px solid #606060"
                              : "0px solid #606060",
                        }}
                      >
                        {t("Monthly")}
                      </label>
                    </div>
                  </div>
                  <div class="col-6">
                    <label>{t("Min Price")}</label>
                    <SelectOptions
                      style={"form-control"}
                      selectedOption={allFilters?.price?.min || t("Any")}
                      name={data?.min_max_price?.min?.field}
                      values={showFilteredValues(
                        "min_max_price",
                        data?.min_max_price?.min?.[allFilters?.rentType],
                        allFilters?.price?.max,
                        "min"
                      )}
                      onChange={(e) =>
                        setAllFilters({
                          ...allFilters,
                          price: { ...allFilters.price, min: e.target.value },
                        })
                      }
                    />
                  </div>
                  <div class="col-6">
                    <label>{t("Max Price")}</label>
                    <SelectOptions
                      style={"form-control"}
                      selectedOption={allFilters?.price?.max || t("Any")}
                      name={data?.min_max_price?.max?.field}
                      values={showFilteredValues(
                        "min_max_price",
                        data?.min_max_price?.max?.[allFilters?.rentType],
                        allFilters?.price?.min,
                        "max"
                      )}
                      onChange={(e) =>
                        setAllFilters({
                          ...allFilters,
                          price: { ...allFilters.price, max: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {/* <div class="set fuel1">
                <h4 className="mt-2">{t("Number of seats")}</h4>
                <div className="row">
                  <div class="col-6">
                    <label>{t("Min seats")}</label>
                    <SelectOptions
                      style={"form-control"}
                      selectedOption={""}
                      placeholder={t('Any')}
                      onChange={handleChange}
                      name={"min_price"}
                      values={
                        data?.seats?.min?.data ? data?.seats?.min?.data : []
                      }
                    />
                  </div>
                  <div class="col-6">
                    <label>{t("Max seats")}</label>
                    <SelectOptions
                      style={"form-control"}
                      selectedOption={""}
                      onChange={handleChange}
                      name={"max_price"}
                      values={
                        data?.seats?.max?.data ? data?.seats?.max?.data : []
                      }
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div class="scrhdiv">
            <CarBody2
              title={t("Body Type")}
              handleClick={handleClickEvent}
              body_type={
                data?.body_type && data?.body_type?.length > 0
                  ? data?.body_type
                  : []
              }
            />
            <div class="set fuel1">
              <div class="row">
                <div class="col-sm-12">
                  <label class="mt-0">{t("Kilometers")}</label>
                  <div class="row">
                    <div class="col-6">
                      <label>
                        {t(data?.min_max_kilometer?.min?.name?.[i18n.language])}
                      </label>
                      <SelectOptions
                        style={"form-control"}
                        selectedOption={allFilters?.km?.min || t("Any")}
                        name={data?.min_max_kilometer?.min?.field}
                        values={showFilteredValues(
                          "min_max_kilometer",
                          data?.min_max_kilometer?.min?.data,
                          allFilters?.km?.max,
                          "min"
                        )}
                        namePrefix={t("At least") + " "}
                        nameSuffix={" " + t("KM's")}
                        onChange={(e) =>
                          setAllFilters({
                            ...allFilters,
                            km: { ...allFilters.km, min: e.target.value },
                          })
                        }
                      />
                    </div>
                    <div class="col-6">
                      <label>
                        {t(data?.min_max_kilometer?.max?.name?.[i18n.language])}
                      </label>
                      <SelectOptions
                        style={"form-control"}
                        selectedOption={allFilters?.km?.max || t("Any")}
                        name={data?.min_max_kilometer?.max?.field}
                        values={showFilteredValues(
                          "min_max_kilometer",
                          data?.min_max_kilometer?.max?.data,
                          allFilters?.km?.min,
                          "max"
                        )}
                        namePrefix={t("Up to") + " "}
                        nameSuffix={" " + t("KM's")}
                        onChange={(e) =>
                          setAllFilters({
                            ...allFilters,
                            km: { ...allFilters.km, max: e.target.value },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 mt-3">
                  <label class="mt-0">{t("Gear Box")}</label>
                  <SelectOptions
                    style={"form-control"}
                    selectedOption={allFilters?.gearbox || t("Any")}
                    onChange={(e) =>
                      setAllFilters({
                        ...allFilters,
                        gearbox: [e.target.value],
                      })
                    }
                    name={"gearbox"}
                    values={data?.transmission ? data?.transmission : []}
                  />
                </div>
              </div>
            </div>
            <Fuel
              title={t("Fuel Type")}
              fuel_type={data?.fuel && data?.fuel?.length > 0 ? data?.fuel : []}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
            />
            <div class="set fuel1">
              <h4>{t("Age")}</h4>
              {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <button
                    class="nav-link active"
                    id="syear"
                    onClick={() => {
                      $("#sbrand").removeClass("active");
                      $("#syear").addClass("active");
                      setAllFilters({ ...allFilters, brandNew: false });
                    }}
                  >
                    {t("Select year")}
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class="nav-link"
                    id="sbrand"
                    onClick={() => {
                      $("#syear").removeClass("active");
                      $("#sbrand").addClass("active");
                      setAllFilters({ ...allFilters, brandNew: true });
                    }}
                  >
                    {t("Brand new")}
                  </button>
                </li>
              </ul> */}
              <div class="row">
                {/* <div class="col-12">
                  <p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        name={"car_type"}
                      />
                      <span class="slider round"></span>
                    </label>{" "}
                    {t("Only show new car deals")}
                  </p>
                </div> */}
                {/* {!allFilters.brandNew && (
                  <> */}
                <div class="col-6">
                  <label>{t("Min Year")}</label>
                  <SelectOptions
                    style={"form-control"}
                    selectedOption={allFilters?.year?.min || t("Any")}
                    onChange={(e) =>
                      setAllFilters({
                        ...allFilters,
                        year: { ...allFilters.year, min: e.target.value },
                      })
                    }
                    name={"min_year"}
                    values={showFilteredValues(
                      "min_max_year",
                      data?.min_max_year?.min?.data,
                      allFilters?.year?.max,
                      "min"
                    )}
                  />
                </div>
                <div class="col-6">
                  <label>{t("Max Year")}</label>
                  <SelectOptions
                    style={"form-control"}
                    selectedOption={allFilters?.year?.max || t("Any")}
                    onChange={(e) =>
                      setAllFilters({
                        ...allFilters,
                        year: { ...allFilters.year, max: e.target.value },
                      })
                    }
                    name={"max_year"}
                    values={showFilteredValues(
                      "min_max_year",
                      data?.min_max_year?.max?.dataRental,
                      allFilters?.year?.min,
                      "max"
                    )}
                  />
                </div>
                {/* </>
                )} */}
              </div>
            </div>
            {/* {dataSet.specifications &&
              Object.keys(dataSet.specifications).length > 0 && ( */}
            <Specification
              data={data}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              showFilteredValues={showFilteredValues}
            />
            {/* // )} */}
            {/* {dataSet.perfomance && dataSet.perfomance.length > 0 && ( */}
            <Perfomance
              data={data}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              showFilteredValues={showFilteredValues}
            />
            {/* )} */}
            <div class="text-center stscrl">
              <Button
                onClick={handleSearch}
                style={"srcbtn"}
                type={"button"}
                title={t("Search cars")}
              />
              <br />
              <Button
                onClick={handleClick}
                style={"reset"}
                type={"button"}
                title={t("Reset Filters")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
