import React from 'react'
import PrivacyPolicies from '../../../../MyComponents/PrivacyPolicies';

export default function PrivacyPage() {

  return (
   // <></>
   <PrivacyPolicies />
  )
}
