import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { fetchPostByBrand } from "../../../Reducer/electricCarSlice";
import { ElectricDetail } from "../../../Reducer/electricCarSlice";
import SelectViewCar from "../../Tools/SelectViewCar";
import { getEntityTypes } from "../../../Reducer/api";
import { fetchSearched } from "../../../Reducer/searchedSlice";
import { useState } from "react";

export default function SearchRentalType({ rtl, page }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const searched = useSelector((state) => state.searched.searched);
  const loading = useSelector((state) => state.searched.loading);
  const auth = useSelector((state) => state.auth.auth);
  let data = { id: auth && auth.id ? auth.id : "", page: page };
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState(null);
  useEffect(() => {
    dispatch(fetchSearched(data));
  }, []);

  const selectBrand = (string, item) => {
    setSelectedCarType({ ...carTypes.filter((v) => v?.id == item?.id)[0] });
    $(".sl").removeClass("active");
    $(string + item?.id).addClass("active");
    let form = {
      bodyType: item?.name?.en,
      id: auth.id ? auth.id : "",
      page: page,
    };
    dispatch(fetchPostByBrand(form));
  };

  let responce =
    searched.car_types && searched.car_types.length > 0
      ? searched.car_types
      : [];
  let responceList =
    searched.default_active && searched.default_active.length > 0
      ? searched.default_active
      : [];

  const handleClick = (datalist) => {
    if (datalist) {
      dispatch(fetchSearched(data));
    }
  };

  const getCarTypes = async () => {
    const res = await getEntityTypes();
    setCarTypes(res?.data);
    // selectBrand("#btn-m", res?.data?.[0]?._id);
  };

  useEffect(() => {
    getCarTypes();
  }, []);

  // if (loading) {
  //   return (
  //     <div
  //       class="d-flex justify-content-center"
  //       style={{ marginBlock: "25vh" }}
  //     >
  //       <div class="spinner-grow" role="status">
  //         <span class="visually-hidden">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <SelectViewCar
      rtl={rtl}
      responce={carTypes}
      responceList={responceList}
      title={t("Most searched rental types")}
      path={"hatchback_cars"}
      carTypes={carTypes}
      selectBrand={selectBrand}
      selectedCarType={selectedCarType}
      handleClick={handleClick}
      page={page}
      adsArray={searched?.allAdIds}
    />
  );
}
