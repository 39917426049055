import React, { useEffect } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersActionBar } from "@mui/x-date-pickers/PickersActionBar";

import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
import i18n from "../../../i18n";
import { convertTime } from "../../../utils/constants";
import { t } from "i18next";

export default function CustomTimePicker({
  modal,
  handleCloseModal,
  modalData,
  onChange,
  onAccept,
}) {
  const createInputString = (time) => {
    if (time) {
      const date = new Date();
      const time24 = convertTime(time, "24-hour");
      date.setHours(time24.slice(0, 5).split(":")[0]);
      date.setMinutes(time24.slice(0, 5).split(":")[1]);
      return moment(date);
    }
  };
  return (
    <div>
      <Modal isOpen={modal} centered toggle={handleCloseModal}>
        <ModalHeader
          style={{ alignSelf: "end" }}
          toggle={handleCloseModal}
          className="d-flex justify-content-between border-0 w-100"
        >
          <span className="">{t(modalData?.day)}</span>
        </ModalHeader>
        <ModalBody>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticTimePicker
              className="myTimePicker"
              defaultValue={dayjs(createInputString(modalData?.time))}
              onChange={onChange}
            />
          </LocalizationProvider>
          <button
            className="themeButton w-100 mt-4"
            onClick={(e) => handleCloseModal()}
          >
            {t("Save")}
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
}
