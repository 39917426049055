import React, { useEffect, useState } from "react";
import PaymentForm from "./paymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { apiManager } from "../../../api/connectAPI";
import { dataInFormatCheck } from "../../Pages/CheckCar/dataFormatUtil";
import { paths } from "../../../utils/paths";

export default function GeneralPayment() {
  const language = useSelector((state) => state.auth.language);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const lang =
    language === "English"
      ? "en"
      : language === "Arabic"
      ? "ar"
      : language === "Kurdish"
      ? "ku"
      : "en";
  const [options, setOptions] = useState({
    locale: lang,
    // clientSecret: "pi_3PGzEiGhHAADrPHv1ChfVuXE_secret_xSmejqu6BsDyVY2xeW4QfNxcB"
    // appearance: {
    //   rules: {
    //     ".Input": {
    //       backgroundColor: 'orange',
    //       padding: '0px 0px 0px 0px',
    //       direction: 'rtl',
    //     }
    //   },
    // },
  });
  const publisableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE
  const stripePromise = loadStripe(publisableKey);
  useEffect(() => {
    setLoading(true);
    const data = { vin: location?.state?.data?.chasis };
    apiManager("POST", `opp/processCheckMyCar?lang=${lang}`, data)
      .then((res) => {
        if (res?.status) {
          if (res?.data?.status === "ok") {
            let updatedResult = dataInFormatCheck(res?.data?.result?.report);
            if (res?.data?.result) {
              navigate(paths.singleCarCheck, { state: { data: updatedResult } });
            }
            // setLoading(false)
            return;
          } else {
            const newOpt = {
              ...options,
              clientSecret: res?.data?.paymentIntent,
            };
            setOptions(newOpt);
          }
        } else {
          console.log(res);
          navigate(paths.singleCarCheck, { state: { data: { found: false } } });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        navigate(paths.singleCarCheck, { state: { data: { found: false } } });
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="min-vh-100 d-flex justify-content-center mt-5 pt-5">
        <div className="d-flex justify-content-center">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Elements stripe={stripePromise} options={options}>
          <PaymentForm data={location?.state?.data} />
        </Elements>
      </div>
    );
  }
}
