import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { social } from "../../../data/content";
import ListItem from "../../Tools/ListItem";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";

export default function Follow() {
  const { t, i18n } = useTranslation();
  const socialIcon = social;

  return (
    <div class="container">
      <section class="follow_us text-center">
        <h2 class="hed">{t("Follow us on social media")}</h2>
        <ul>
          {socialIcon.map((item, key) => {
            return (
              <ListItem
                key={key}
                innerhtmlType={"html"}
                value={
                  <a href={item.link} target="_blank">
                    {/* <i class={item.icon}></i> */}
                    <Icon icon={item.icon} height={item?.height} />
                    <br />
                    {t(item.name)}
                  </a>
                }
              ></ListItem>
            );
          })}
        </ul>
      </section>
    </div>
  );
}
