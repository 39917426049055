import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { contactFormSubmit,fetchcontactUsdetails} from './api';

export const saveContactUsForm = createAsyncThunk('detail/detailsForm', async (formData, { rejectWithValue }) => {
  try {
    const response = await contactFormSubmit(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const fetchdescribe = createAsyncThunk('detail/detailsFormdescribe', async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchcontactUsdetails(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });


const contactus = createSlice({
  name: 'contactus',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    detail:{},
    detailarr:[],
  },
  reducers: {},
  extraReducers: builder => {
    builder
    // detail form
      .addCase(saveContactUsForm.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(saveContactUsForm.fulfilled, (state,action) => {
        state.submitting = false;
        state.detail = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(saveContactUsForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
    // detail form
      .addCase(fetchdescribe.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchdescribe.fulfilled, (state,action) => {
        state.submitting = false;
        state.detailarr = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(fetchdescribe.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
  },
});
export default contactus.reducer;
