import React, { useEffect } from "react";
import ListItem from "../../Tools/ListItem";
import { footerData } from "../../../data/content";
import { useTranslation } from "react-i18next";
export default function Pages() {
  const { t, i18n } = useTranslation();
  const footer = footerData;

  return (
    <>
      {footer.length > 0
        ? footer.map((item) => {
            return (
              <div class="col-6 col-lg-3 set">
                <h3>{t(item.name)}</h3>
                <ul>
                  {item.sub_list.map((item, key) => {
                    return (
                      <ListItem
                        key={key}
                        value={t(item.name)}
                        style={"nav-link"}
                        segment={item.segment}
                        innerhtmlType={"link"}
                      />
                    );
                  })}
                </ul>
              </div>
            );
          })
        : ""}
    </>
  );
}
