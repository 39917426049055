import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchchat } from "./api";

export const messageFun = createAsyncThunk(
  "detail/fetchchat",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchchat(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const mychat = createSlice({
  name: "mychat",
  initialState: {
    submitting: true,
    error: null,
    success: false,
    chats: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // chat data
      .addCase(messageFun.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(messageFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.chats = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(messageFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export default mychat.reducer;
