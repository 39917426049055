import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Button from "../../Tools/Button";
import { KeyFect, Performance, Practicality } from "../../../data/content";
import Facts from "../../Tools/CarDetail/Facts";
import { useState } from "react";
import i18n from "../../../i18n";
import { getFeatures } from "../../../Reducer/api";

export default function Fact({ data, onChange }) {
  const { t } = useTranslation();
  const [highlight, toggleHighlight] = useState(false);
  const basicDetailsAllCars = data.map((v) => v?.properties?.basicDetails);
  const specificationsAllCars = data.map((v) => v?.properties?.specifications);
  const featuresAllCars = data.map((v) => v?.properties?.features);
  const [featureNamesData, setFeatureNamesData] = useState([]);
  useEffect(() => {
    getFeatures().then((res) => {
      if (res?.status) {
        const result = {};
        res?.data?.features.forEach((v) => {
          result[v?.value] = v?.name;
        });
        setFeatureNamesData(result);
      }
    });
  }, [data]);
  return (
    <>
      <div class="col-md-12 text-start">
        <div
          class={`Viewdet Viewdet2 text-end highDiff ${
            i18n.language !== "English" && "highDiffRtl"
          }`}
          style={{ width: "unset" }}
        >
          <label>{t("Highlight differences")}</label>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              onChange={(e) => toggleHighlight(e.target.checked)}
            />
          </div>
        </div>
      </div>
      <Tabs>
        <TabList className={"nav nav-tabs mb-4"}>
          <Tab>
            <Button
              style={"nav-link"}
              inlineStyle={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
              title={t("Basic Details")}
            />
          </Tab>
          <Tab>
            <Button
              style={"nav-link"}
              inlineStyle={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
              title={t("Specifications")}
            />
          </Tab>
          <Tab>
            <Button
              style={"nav-link"}
              inlineStyle={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
              title={t("Features")}
            />
          </Tab>
        </TabList>
        <div class="col-md-12 mt-4">
          <div class="tab-content dview_alcars" id="myTabContent2">
            <TabPanel id="facts-tab-pane">
              <div class="row compareCarousel thinScroll pb-4">
                {data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <Facts
                        data={item?.properties?.basicDetails}
                        compareData={basicDetailsAllCars}
                        fullData={item}
                        onChange={onChange}
                        keys={KeyFect}
                        ratting={true}
                        index={index}
                        highlight={highlight}
                      />
                    );
                  })}
              </div>
            </TabPanel>
            <TabPanel id="Performance-tab-pane">
              <div class="row compareCarousel pb-4">
                {data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <Facts
                        data={item?.properties?.specifications}
                        compareData={specificationsAllCars}
                        fullData={item}
                        onChange={onChange}
                        keys={Performance}
                        ratting={false}
                        index={index}
                        highlight={highlight}
                      />
                    );
                  })}
              </div>
            </TabPanel>
            <TabPanel id="Practicality-tab-pane">
              <div class="row compareCarousel pb-4">
                {data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <Facts
                        data={item?.properties?.features}
                        compareData={featuresAllCars}
                        fullData={item}
                        onChange={onChange}
                        keys={Practicality}
                        ratting={false}
                        index={index}
                        highlight={highlight}
                        featureNamesData={featureNamesData}
                      />
                    );
                  })}
              </div>
            </TabPanel>
          </div>
        </div>
      </Tabs>
    </>
  );
}
