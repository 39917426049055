import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBrandsData } from './api';

export const fetchBrands = createAsyncThunk('brands/fetchBrands', async (_, { rejectWithValue }) => {
  try {
    const brands = await fetchBrandsData();
    return brands;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    brands: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBrands.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.brands = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
  },
});

export default brandsSlice.reducer;
