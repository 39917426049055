import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSavedCar } from './api';

export const fetchSaved= createAsyncThunk('saved/fetchSaved', async (formData, { rejectWithValue }) => {
  try {
    const searched = await fetchSavedCar(formData);
    return searched;
  } catch (error) {
    console.log(error)
    return rejectWithValue(error.message);
  }
});

const savedSlice = createSlice({
  name: 'saved',
  initialState: {
    saved: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSaved.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSaved.fulfilled, (state, action) => {
        state.saved = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSaved.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default savedSlice.reducer;
