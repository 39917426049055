import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { submitLoginForm, registerFormSubmit, getGlobalSettings } from "./api";
import { apiManager } from "../api/connectAPI";
import { toast } from "react-toastify";

export const loginForm = createAsyncThunk(
  "login/loginForm",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await submitLoginForm(formData, rejectWithValue);
      // localStorage.setItem("user", JSON.stringify(response.data));
      // localStorage.setItem("token", response.token);
      getGlobalSettings();
      if(response.status) {
        return response
      } else {
        return rejectWithValue(response);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerForm = createAsyncThunk(
  "register/registerForm",
  async (formData, { rejectWithValue }) => {
    const data = formData.json;
    const phone = data.phone;
    if (data.type === "email") delete data.phone;
    if (data.type === "phone") {
      delete data.email;
      delete data.phone;
      data["phoneNumber"] = phone;
    }
    try {
      const response = await registerFormSubmit(data, formData.setLoading, rejectWithValue);
      return response;
    } catch(error) {
      toast.error(error.message)
    }


    // try {

    //   console.log(response)

    //   localStorage.setItem('user', JSON.stringify(response.data));
    //   localStorage.setItem('token', response.token);
    //   // localStorage.setItem('userType', formData.get('userType'));

    //   return response;
    // } catch (error) {
    //   return rejectWithValue(error.response);
    // }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language: "Arabic",
    auth: {},
    cars: [],
    token: "",
  },
  reducers: {
    setUser: (state, action) => {
      state.auth = action.payload;
    },
    setCars: (state, action) => {
      state.cars = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearUser: (state) => {
      state.auth = null;
      state.success = false;
      localStorage.removeItem("user");
      localStorage.removeItem("myProfile");
    },
    clearToken: (state) => {
      state.token = null;
      state.success = false;
      localStorage.removeItem("token");
    },
    changeLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("cl", action.payload);
    },
    clearStatus: (state) => {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginForm.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(loginForm.fulfilled, (state, action) => {
        state.submitting = false;
        state.auth = action.payload?.data;
        state.token = action.payload?.token;
        state.success = true;
        state.error = null;
      })
      .addCase(loginForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(registerForm.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(registerForm.fulfilled, (state, action) => {
        console.log("in case ", action);
        state.submitting = false;
        state.auth = action.payload?.data;
        state.token = action.payload?.token;
        state.success = true;
        state.error = null;
      })
      .addCase(registerForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export const {
  setUser,
  setToken,
  clearStatus,
  clearUser,
  setCars,
  clearToken,
  changeLanguage,
} = authSlice.actions;
export default authSlice.reducer;
