import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFormDetail,fetchCheapFormDetail ,fetchFeatureDetail,fetchNewcarsDetail} from './api';

export const fromDetail = createAsyncThunk('form/fetchDetail', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchFormDetail();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const CheapDetail = createAsyncThunk('form/fetchCheapDetail', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchCheapFormDetail();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const FeatureDetail = createAsyncThunk('form/fetchFeatureDetail', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchFeatureDetail();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const Newcars = createAsyncThunk('form/fetchNewcarsDetail', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchNewcarsDetail();
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


const formSlice = createSlice({
  name: 'fromsdata',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    fromsdata:[],
    cheapdata:[],
    newcarsdata:[],
    featuredata:{},

  },
  reducers: {},
  extraReducers: builder => {
    builder
          // fromDetail ............
      .addCase(fromDetail.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fromDetail.fulfilled, (state,action) => {
        state.submitting = false;
        state.fromsdata = action.payload.data;
        state.success=true;
        state.error = null;
      })
      .addCase(fromDetail.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      // CheapDetail ............
      .addCase(CheapDetail.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(CheapDetail.fulfilled, (state,action) => {
        state.submitting = false;
        state.cheapdata = action.payload.data;
        state.success=true;
        state.error = null;
      })
      .addCase(CheapDetail.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
    // FeatureDetail ............
     .addCase(FeatureDetail.pending, state => {
      state.submitting = true;
      state.error = null;
      state.success = false;
    })
    .addCase(FeatureDetail.fulfilled, (state,action) => {
      state.submitting = false;
      state.featuredata = action.payload.data;
      state.success=true;
      state.error = null;
    })
    .addCase(FeatureDetail.rejected, (state, action) => {
      state.submitting = false;
      state.error = action.payload;
      state.success = false;
    })
    // fetchNewcarsDetail
    .addCase(Newcars.pending, state => {
      state.submitting = true;
      state.error = null;
      state.success = false;
    })
    .addCase(Newcars.fulfilled, (state,action) => {
      state.submitting = false;
      state.newcarsdata = action.payload.data;
      state.success=true;
      state.error = null;
    })
    .addCase(Newcars.rejected, (state, action) => {
      state.submitting = false;
      state.error = action.payload;
      state.success = false;
    })


  },
});
export default formSlice.reducer;
