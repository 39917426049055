export const rentalContent = {
  sort: [
    {
      id: "1",
      name: "Relevance",
      value: "relevance",
      selected: true,
    },
    {
      id: "2",
      name: "Price (Lowest)",
      value: "priceLow",
    },
    {
      id: "3",
      name: "Price (Highest)",
      value: "priceHigh",
    },
    {
      id: "3",
      name: "Kilometers",
      value: "km",
    },
    {
      id: "3",
      name: "Age (Newest first)",
      value: "ageNew",
    },
    {
      id: "3",
      name: "Age (Oldest first)",
      value: "ageOld",
    },
    {
      id: "3",
      name: "Most recent",
      value: "recent",
    },
  ],
  cities: [
    {
      id: "1",
      name: {
        English: "Select city",
        Arabic: "اختر المدينة",
        Kurdish: "شارەکە هەڵبژێرە",
      },
      value: "",
      disabled: true,
      selected: true,
    },
    {
      id: "2",
      name: {
        English: "Chandigarh",
        Arabic: "Chandigarh",
        Kurdish: "Chandigarh",
      },
      value: "",
    },
    {
      id: "3",
      name: {
        English: "Delhi",
        Arabic: "Delhi",
        Kurdish: "Delhi",
      },
      value: "Delhi",
    },
  ],
  distance: [
    {
      id: "1",
      name: {
        English: "Select Distance",
        Arabic: "Delhi",
        Kurdish: "Delhi",
      },
      value: "",
      disabled: true,
      selected: true,
    },
    {
      id: "2",
      name: {
        English: "50 bhb",
        Arabic: "50 bhb",
        Kurdish: "50 bhb",
      },
      value: "50 bhb",
    },
    {
      id: "3",
      name: {
        English: "150 bhb",
        Arabic: "150 bhb",
        Kurdish: "150 bhb",
      },
      value: "150 bhb",
    },
  ],
  drop_down_filter: [
    {
      name: "Make",
      backend_column: "make",
      data_type: "on select",
    },
    {
      name: "Model",
      backend_column: "model",
      data_type: "on select",
    },
    // {
    //   name: "Model Variant",
    //   backend_column: "variant",
    //   data_type: "on select",
    // },
    {
      name: "Price",
      backend_column: "min_max_price",
      data_type: "D_W_M_Price",
    },
    {
      name: "Year",
      backend_column: "min_max_year",
      data_type: "sidebar",
    },
    {
      name: "Kilometers",
      backend_column: "min_max_kilometer",
      data_type: "sidebar",
    },
    {
      name: "Regional Specs",
      backend_column: "regional_space",
      data_type: "checkbox",
    },
    // {
    //   name: "Body Condition",
    //   backend_column: "body",
    //   data_type: "checkbox",
    // },
    // {
    //   name: "Paint",
    //   backend_column: "paint",
    //   data_type: "checkbox",
    // },
    {
      name: "Colour",
      backend_column: "colour",
      data_type: "checkbox",
    },
    {
      name: "Gear Box",
      backend_column: "gearbox",
      data_type: "checkbox",
    },
    {
      name: "Fuel Type",
      backend_column: "fuel",
      data_type: "checkbox",
    },
    {
      name: "Body Type",
      backend_column: "body_type",
      data_type: "checkbox",
    },
    {
      name: "Engine Size",
      backend_column: "min_max_engine",
      data_type: "input",
    },
    {
      name: "Battery size",
      backend_column: "min_max_battery",
      data_type: "input",
    },
    {
      name: "Engine Power",
      backend_column: "min_max_engine_power",
      data_type: "input",
    },
    {
      name: "Cylinders",
      backend_column: "cylinders",
      data_type: "input",
    },
    {
      name: "Doors",
      backend_column: "doors",
      data_type: "input",
    },
    {
      name: "Seats",
      backend_column: "seats",
      data_type: "input",
    },
  ],
};
