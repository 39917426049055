import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ContactBox from "../../Tools/ContactBox";
import { fetchdescribe } from "../../../Reducer/ConteactUsSilce";
import { useDispatch, useSelector } from "react-redux";

export default function Describes({ formData, setFormData }) {
  const { t, i18n } = useTranslation();
  const data = useSelector((state) => state.contactus.detailarr);
  const loading = useSelector((state) => state.contactus.loading);
  const error = useSelector((state) => state.contactus.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchdescribe());
  }, [dispatch]);
  return (
    <div className="">
      {data ? (
        <div class="set_best">
          <h3>{t("Which of these describes you best?")}</h3>
          <div class="row justify-content-start">
            <ContactBox
              data={data ? data : []}
              formData={formData}
              setFormData={setFormData}
            />
            <p className="helperText text-danger ms-2">{formData?.roleError}</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
