import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  findUploads,
  showTimeUnixTranslated,
} from "../../../../../utils/constants";
import i18n from "../../../../../i18n";
import { Badge } from "@mui/material";

export default function ChatUser({
  data,
  classs,
  setdata,
  selectedChat,
  setSelectedChat,
  setLoading,
  setSelectedChatData,
}) {
  const { t } = useTranslation();
  const udata = useSelector((state) => state.auth);

  const formattedTime = (timestamp) => {
    if (
      typeof timestamp !== "number" ||
      isNaN(timestamp) ||
      !isFinite(timestamp)
    ) {
      return "Invalid Time";
    }
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const formattedTimeString = `${hours}:${minutes}`;
    return formattedTimeString;
  };

  const [list, setList] = useState(data);
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (val) => {
    console.log(val);
    const searchTerm = val.toLowerCase() ? val.toLowerCase() : "";
    const filteredList = data.filter((item) => {
      const itemName = item?.sellerName.toLowerCase();
      return itemName.includes(searchTerm);
    });
    setList(filteredList);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const inputValue = event.target.value;
    setSearchValue(inputValue);
    handleSearch(inputValue);
  };

  useEffect(() => {
    setList(data);
    // setstate1('')
  }, [data]);

  const selectedStyle = {
    backgroundColor: "#D1E0FF",
    borderRadius: "16px",
    // border: '1px solid #4C7EE8'
  };

  const handleChangeRadio = (e, item) => {
    console.log(e.target.id);
    setSelectedChat(e.target.id);
    setSelectedChatData(item);
    setdata("");
  };

  const makeLastMessage = (message) => {
    switch (message?.messageType) {
      case "text":
        return message?.messageBody;
      // case 'location':
      //   return <img src="/assets/images/location.png" style={{maxHeight: '10px !important'}}/>
    }
  };

  return (
    <div>
      <div class={`chat_user ${classs}`} style={{ overflow: "hidden" }}>
        {data ? (
          <form class="form-inline mt-2 mb-3">
            <input
              class="form-control"
              type="text"
              placeholder={t("Search people")}
              aria-label="Search"
              value={searchValue}
              onChange={handleChange}
            />
            <button
              style={{ height: "100%" }}
              class="btn btn-outline-success my-2 my-sm-0"
              type="button"
            >
              <i class="fas fa-search"></i>
            </button>
          </form>
        ) : null}
        <ul className="thinScroll">
          {list?.map((item, key) => {
            let name =
              item?.sellerId === udata?.auth?.userId
                ? item?.buyerName
                : item?.buyerId === udata?.auth?.userId
                ? item?.sellerName
                : "--";
            let dp =
              item?.sellerId === udata?.auth?.userId
                ? item?.buyerAvatar
                : item?.buyerId === udata?.auth?.userId
                ? item?.sellerAvatar
                : "";
            const ad = item?.adData;
            if (!dp || dp === "N/A") dp = "/assets/images/ap2.png";
            return (
              <>
                <label
                  className=" d-block m-2 chatLabel position-relative"
                  id={`chat_item_${item?._id}`}
                  style={item?._id == selectedChat ? selectedStyle : null}
                  htmlFor={item?._id}
                >
                  <div
                    style={{ top: "2px", paddingInline: "5px" }}
                    className={` position-absolute ${
                      i18n.language === "English" ? "end" : "start"
                    }-0`}
                  >
                    <Badge
                      badgeContent={item?.unreadMessages}
                      showZero={false}
                      style={{
                        margin: "3px 12px 0px 0px",
                        width: "fit-content",
                      }}
                      color="error"
                      className="text-nowrap"
                      overlap="circular"
                    ></Badge>
                  </div>
                  <input
                    type="radio"
                    name="chatList"
                    id={item?._id}
                    onClick={(e) => handleChangeRadio(e, item)}
                    className="chatRadio"
                  />
                  <li key={key} style={{ top: "3px" }}>
                    <div class="chatuser d-flex align-items-center">
                      <img
                        src={dp}
                        class="img-fluid h-100 border rounded-5"
                        style={{ height: "100%" }}
                      />
                    </div>
                    <span className="d-flex row">
                      <h6
                        className="col-8 col-md-7 col-xl-9 mt-0"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {name}
                      </h6>
                      <span
                        className="col-4 col-md-5 col-xl-3 text-end text-nowrap"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {showTimeUnixTranslated(item?.createdAt)}
                      </span>
                    </span>
                    <span className="d-flex gap-2 align-items-end">
                      {/* <span>
                        <img
                          src={'/assets/images/dash_img/simple-car.png'}
                          style={{ height: "20px" }}
                        />
                      </span> */}
                      <span
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          fontSize: "12px",
                        }}
                      >
                        {/* {item?.lastMessages?.senderId === udata?.auth?.userId && "You: "} */}
                        {/* {makeLastMessage(item?.lastMessages)} */}
                        {t(ad?.properties?.basicDetails?.make?.en)}{" "}
                        {t(ad?.properties?.basicDetails?.model?.en)}{" "}
                        {t(ad?.properties?.basicDetails?.makeYear)}
                      </span>
                    </span>
                    <span
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        fontSize: "10px",
                      }}
                    >
                      {/* {item?.lastMessages?.senderId === udata?.auth?.userId && "You: "} */}
                      {/* {makeLastMessage(item?.lastMessages)} */}
                      {item?.lastMessages?.messageBody}
                    </span>
                  </li>
                </label>
              </>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
