import React, { useState } from "react";
import { useSelector } from "react-redux";

//Date Picker Imports
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { t } from "i18next";
import moment from "moment";
import { utils } from "react-modern-calendar-datepicker";
export default function CustomDatepicker({
  onChange,
  value,
  placeholder,
  maxDate,
  inputClassName,
}) {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const myCustomLocale = {
    // months list by order
    months: [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ],

    // week days by order
    weekDays: [
      {
        name: t("Sunday"), // used for accessibility
        short: "S", // displayed at the top of days' rows
        isWeekend: true, // is it a formal weekend or not?
      },
      {
        name: t("Monday"),
        short: "M",
      },
      {
        name: t("Tuesday"),
        short: "T",
      },
      {
        name: t("Wednesday"),
        short: "W",
      },
      {
        name: t("Thursday"),
        short: "T",
      },
      {
        name: t("Friday"),
        short: "F",
      },
      {
        name: t("Saturday"),
        short: "S",
        isWeekend: true,
      },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
      return digit;
    },

    // texts in the date picker
    nextMonth: "Next Month",
    previousMonth: "Previous Month",
    openMonthSelector: "Open Month Selector",
    openYearSelector: "Open Year Selector",
    closeMonthSelector: "Close Month Selector",
    closeYearSelector: "Close Year Selector",
    defaultPlaceholder: t("Select"),

    // for input range value
    from: "from",
    to: "to",

    // used for input value when multi dates are selected
    digitSeparator: ",",

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: lang === "en" ? false : true,
  };
  const today = utils().getToday();
  return (
    <DatePicker
      locale={myCustomLocale}
      value={value}
      onChange={onChange}
      // minimumDate={moment.subtract('year')}
      inputPlaceholder={placeholder}
      colorPrimary="#334660"
      maximumDate={{ ...today, year: today.year - 16 }}
      inputClassName={inputClassName}
      shouldHighlightWeekends
    />
  );
}
