import React, { useState } from "react";
import SelectOptions from "../../Tools/SelctOptions";
import { useTranslation } from "react-i18next";
import { cities } from "../../../data/content";
import SelectMultiple from "../../Tools/SelectMultiple";

export default function Basicsfilters({
  city,
  distance,
  filters,
  setFilters,
  dealerCities
}) {
  const { t, i18n } = useTranslation();
  const [selection, setValues] = useState({});
  const [keywords, setKeywords] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...selection,
      [name]: value,
    });
  };
  
  const handleClick = (e) => {
    console.log("under click");
    console.log("selection", selection);
  };

  const [searchKeyword, setSearch] = useState("");
  const setSearchKeyword = (data) => {
    setFilters({ ...filters, name: data });
    setSearch(data);
  };

  const handleChangeCity = (e) => {
    if(e.target.value === t('Any')) {
      setFilters({ ...filters, city: '' });
    } else {
      setFilters({ ...filters, city: e.target.value });
    }
  };

  // const handleChangeDistance = (e) => {
  //   setFilters({...filters, distance: e.target.distance})
  // }

  return (
    <div>
      {city && distance ? (
        <div class="sidebar">
          <div class="set">
            <h4>{t("Basics filters")}</h4>
            <div class="row mb-3">
              <div class="col-12">
                <label>{t("Cities")}</label>
                {/* <SelectOptions
                  style={"form-control"}
                  selectedOption={""}
                  onChange={handleChangeCity}
                  name={"gearbox"}
                  values={cities}
                /> */}
                <SelectMultiple
                selectedOption={
                  filters?.city !== t("National") ? filters?.city : null
                }
                placeholder={t("National")}
                onChange={(e) =>
                  setFilters({ ...filters, city: e?.value || "" })
                }
                values={dealerCities}
                showDropdownIcon
              />
              </div>
              {/* <div class="col-12">
                <label>{t("Distance")}</label>
                <SelectOptions
                  style={"form-control"}
                  selectedOption={""}
                  onChange={handleChangeDistance}
                  name={"kilometers"}
                  values={distance ? distance : []}
                />
              </div> */}
            </div>
            <form class="srch mb-4 mt-4">
              <input
                type="text"
                class="form-control"
                placeholder={t("Search")}
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <button type="submit">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </form>
            {/* <p>
              <a href="#">
                <img src="../assets/images/str.png" lang="icon" />{" "}
                {t("Save Search")}
              </a>
            </p>
            <p>
              <a href="#">{t("View your saved searches")}</a>
            </p> */}
          </div>
        </div>
      ) : (
        "Loading... "
      )}
    </div>
  );
}
