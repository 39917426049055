import React, { useState } from "react";
import ArticlesSection from "../../Tools/ArticleBlog/ArticlesSection";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ArticleItem from "../../Tools/ArticleItem";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchHomeArticleData } from "../../../Reducer/api";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";

export default function AllArticles() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.auth.language);
  const loading = useSelector((state) => state.home_article.loading);
  const error = useSelector((state) => state.home_article.error);
  const [articles, setArticles] = useState([]);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  useEffect(() => {
    // dispatch(fetchhomeArticle());
    fetchHomeArticleData({ pageSize: "25", sortBy: "desc" }).then((res) => {
      res
        .json()
        .then((r) => {
          if (r?.data) {
            setArticles(r.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, []);

  return (
    <>
      <section class="recent_articles min-vh-100">
        {articles.length > 0 && (
          <div class="container">
            <h6
              class="back mx-2"
              style={{ direction: language === "English" ? "ltr" : "rtl" }}
            >
              <NavLink onClick={() => navigate(-1)}>
                <img src="../assets/images/backbt.png" /> {t("Go back")}
              </NavLink>
            </h6>
            <h2 class="hed">{t("Recent articles from Ako Sayara")}</h2>
            <div
              class="row w-100"
              style={{ paddingRight: "unset", marginInline: "0px" }}
            >
              {articles.map((item, key) => {
                return (
                  <ArticleItem
                    key={key}
                    style={"col-sm-6 col-lg-3"}
                    title={item?.attributes?.name?.[lang]}
                    created_by={item?.attributes?.additionalData?.by?.[lang]}
                    time_ago={item?.attributes?.additionalData?.date}
                    image={item?.attributes?.additionalData?.thumbnail}
                    // description={item.description[language]}
                    data={item}
                    id={item.id}
                    lang={lang}
                  ></ArticleItem>
                );
              })}
            </div>
          </div>
        )}
      </section>

      <JsonLDSchemaHandler data={ldSchemas?.articles} />
    </>
  );
}
