import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithoutVinData } from './api';

export const fetchWithoutVinForm = createAsyncThunk('withoutVin/fetchWithoutVinForm', async (_, { rejectWithValue }) => {
  try {
    const searched = await fetchWithoutVinData();
    console.log('searched',searched);
    return searched?.data;
  } catch (error) {
    console.log('error',error);
    return rejectWithValue(error?.message);
  }
});

const withoutVinSlice = createSlice({
  name: 'withoutVin',
  initialState: {
    withoutVin: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchWithoutVinForm.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWithoutVinForm.fulfilled, (state, action) => {
        state.withoutVin = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchWithoutVinForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default withoutVinSlice.reducer;
