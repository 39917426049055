import {
  getEntityTypes,
  translateInTwoLanguages,
} from "../../../../Reducer/api";
import { closestValue } from "../../../../data/content";
import withoutVin from "../../../../data/withoutVin.json";
import i18n from "../../../../i18n";

const lang =
  i18n.language === "English" ? "en" : i18n.language === "Arabic" ? "ar" : "ku";

export const dataInFormat = async (data1, data2) => {
  if (data1 || data2) {
    data1 = {
      // uploads: data?.images,
      // tags: [],
      properties: {
        basicDetails: {
          // make: data1?.make
          //   ? { en: data1?.make, ar: data1?.make, ku: data1?.make }
          //   : data2?.decode?.Make
          //   ? {
          //       en: data2?.decode?.Make,
          //       ar: data2?.decode?.Make,
          //       ku: data2?.decode?.Make,
          //     }
          //   : "makeTemp",
          make: data2?.decode?.Make
            ? {
                en: data2?.decode?.Make,
                ar: data2?.decode?.Make,
                ku: data2?.decode?.Make,
              }
            : data1?.make
            ? { en: data1?.make, ar: data1?.make, ku: data1?.make }
            : "makeTemp",
          makeYear:
            parseInt(data1?.year) || parseInt(data2?.decode?.["Model Year"]),
          model: data1?.model
            ? { en: data1?.model, ar: data1?.model, ku: data1?.model }
            : data2?.decode?.Model
            ? {
                en: data2?.decode?.Model,
                ar: data2?.decode?.Model,
                ku: data2?.decode?.Model,
              }
            : "modelTemp",
          // regionalSpecs: {
          //   en: "European specs",
          //   ar: "مواصفات أوروبية",?
          //   ku: "تایبەتمەندییە ئەوروپی",
          // },
          // condition: {
          //   en: "Poor ( severe body damage )",
          //   ar: "ضعيف (ضرر شديد بالهيكل)",
          //   ku: "لاواز (زیانێکی توندی ناوپۆشی کەشتییەکە)",
          // },
          // color: data.color,
        },
        specifications: {
          // paint: {
          //   en: "Total repaint",
          //   ar: "معادة الطلاء",
          //   ku: "دووبارە بۆیاخکردنەوە",
          // },
          // km: "23434",
          // gearbox: {
          //   en: "Manual",
          //   ar: "عادي",
          //   ku: "عادی",
          // },
          // fuelType: {
          //   en: "Diesel Plug-in",
          //   ar: "ديزل كهربائي هجين",
          //   ku: "هایبرید گاز کارەبایی",
          // },
          bodyType: data1?.specs?.body_class
            ? await getBodyType(data1?.specs?.body_class)
            : data2?.decode?.Body
            ? await getBodyType(data2?.decode?.Body)
            : "",
          engineSize:
            data1?.specs?.displacement_l || data1?.specs?.displacement_cc
              ? getEngineSize(
                  data1?.specs?.displacement_l,
                  data1?.specs?.displacement_cc
                )
              : data2?.decode?.["Engine Displacement (ccm)"]
              ? getEngineSize(null, data2?.decode?.["Engine Displacement (ccm)"])
              : "",
          enginePower: data1?.specs?.engine_brake_hp_from
            ? getEnginePower(data1?.specs?.engine_brake_hp_from)
            : data2?.decode?.["Engine Power (HP)"]
            ? getEnginePower(data2?.decode?.["Engine Power (HP)"])
            : "",
          cylinders: data1?.specs?.engine_number_of_cylinders
            ? data1?.specs?.engine_number_of_cylinders
            : data2?.decode?.["Engine Cylinders"]
            ? data2?.decode?.["Engine Cylinders"]
            : "",
          doors: data1?.specs?.doors
            ? data1?.specs?.doors
            : data2?.decode?.["Number of Doors"]
            ? data2?.decode?.["Number of Doors"]
            : "",
          seats: data1?.specs?.number_of_seats
            ? data1?.specs?.number_of_seats
            : data2?.decode?.["Number of Seats"]
            ? parseInt(data2?.decode?.["Number of Seats"])
            : "",
        },
        features: {
          // fabricSeats: false,
          // leatherSeats: data?.leatherSeats,
          // mix: false,
          // rearCam: true,
          // electricTailgate: hasData(data?.lockingPickupTruckTailgate),
          // frontCam: false,
          // cam360: true,
          parkingSensors: hasData(
            data1?.specs?.parking_assist ? data1?.specs?.parking_assist : null
          ),
          // sunroof: data.sunroof,
          blindSpot:
            hasData(
              data1?.specs?.blind_spot_intervention_bsi
                ? data1?.specs?.blind_spot_intervention_bsi
                : null
            ) ||
            hasData(
              data1?.specs?.blind_spot_warning_bsw
                ? data1?.specs?.blind_spot_warning_bsw
                : null
            ),
          // ledHeadlights: false,
          remoteStart: hasData(
            data1?.specs?.keyless_ignition
              ? data1?.specs?.keyless_ignition
              : null
          ),
          // airSuspensionSystem: true,
          // headUpDisplay: true,
          abs: hasData(
            data1?.specs?.anti_lock_braking_system_abs
              ? data1?.specs?.anti_lock_braking_system_abs
              : null
          ),

          airbags:
            hasData(
              data1?.specs?.curtain_air_bag_locations
                ? data1?.specs?.curtain_air_bag_locations
                : null
            ) ||
            hasData(
              data1?.specs?.front_air_bag_locations
                ? data1?.specs?.front_air_bag_locations
                : null
            ) ||
            hasData(
              data1?.specs?.knee_air_bag_locations
                ? data1?.specs?.knee_air_bag_locations
                : null
            ) ||
            hasData(
              data1?.specs?.seat_cushion_air_bag_locations
                ? data1?.specs?.seat_cushion_air_bag_locations
                : null
            ) ||
            hasData(
              data1?.specs?.side_air_bag_locations
                ? data1?.specs?.side_air_bag_locations
                : null
            ),

          // ac: data.ac,
          // acSeats: hasData(data.frontCooledSeat),
          // xUsb: true,
          // bluetooth: true,
          // coolingFridge: false,
          cruiseControl: hasData(
            data1?.specs?.adaptive_cruise_control_acc
              ? data1?.specs?.adaptive_cruise_control_acc
              : null
          ),
          // electricDoors: true,
          // electricMirrors: false,
          // electricSeats: true,
          // dashboardProjector: true,
          // heatedSeats: hasData(data.frontHeatedSeat),
          // heatedSteringWheel: hasData(data.heatedSteeringWheel),
          // keylessEntry: true,
          // ledLights: false,
          // massgeSeats: true,
          // navigationSystem: hasData(data.navigationAid),
          // powerLocks: data.powerLocks,
          // smartEntry: true,
          // smartParking: true,
          // touchscreen: false,
          // usbTypeC: true,
          // wirelessCharging: false,
          // xenonLights: false,
          // anyThingElse: "swimming pool",
          // carDescription: {
          //   ar: "سيارة مذهلة",
          //   en: "amazing car",
          //   ku: "ئۆتۆمبێلێکی سەرسوڕهێنەر",
          // },
        },
        // price: {
        //   daily: "345",
        //   weekly: "567",
        //   monthly: "890",
        // },
        // city: {
        //   English: "Baghdadi",
        //   Arabic: "Baghdadi",
        //   Kurdish: "Baghdadi",
        // },
        // registrationNumber: "",
      },
    };
  }
  // sessionStorage.setItem('carByVin', JSON.stringify(data))
  return data1;
};

const falseValues = [null, "", "Optional", "Not Applicable"];

const hasData = (val) => {
  if (!val || falseValues.includes(val)) {
    return false;
  }
  return true;
};

const getBodyType = async (rawText) => {
  const bodyTypes = (await getEntityTypes()).data.map((v) => v.name);
  return bodyTypes.filter((v) => rawText.includes(v.en))?.[0];
};

const getEngineSize = (d_l, d_cc) => {
  console.log(d_l, d_cc);
  const sizeData = withoutVin.data.size;
  const sizeValues = withoutVin.data.size.map((v) => v.value);
  let res = "";
  if (!d_l && !d_cc) {
    return "";
  } else if (d_l) {
    res = closestValue(d_l, sizeValues);
  } else {
    res = closestValue(d_cc / 1000, sizeValues);
  }
  // return sizeData.filter((v) => v.value == res)?.[0]?.name;
  return res;
};

const getEnginePower = (val) => {
  const powerData = withoutVin.data.power;
  const res = closestValue(
    val,
    withoutVin.data.power.map((v) => v.value)
  );
  // return powerData.filter((v) => v.value === res)?.[0]?.name;
  return res;
};
