import React, { useEffect, useState } from "react";
import Describes from "./Describes";
import Inquireform from "./Inquireform";
import { useTranslation } from "react-i18next";
import { title } from "../../../data/content";
import { ldSchemas } from "../../../utils/json-schemas";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";

export default function Contactus() {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({});
  useEffect(() => {
    console.log(formData);
  }, [formData]);
  return (
    <div className="support_enquiry">
      <Describes formData={formData} setFormData={setFormData} />
      <Inquireform formData={formData} setFormData={setFormData} />

      <JsonLDSchemaHandler data={ldSchemas?.contact} />
    </div>
  );
}
