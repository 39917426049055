import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { cities, errorSms } from "../../../../data/content";
import {
  uploadWithoutVinForm,
  clearStatus,
} from "../../../../Reducer/saveCarWithouVinSlice";
import { fetchWithoutVinForm } from "../../../../Reducer/withoutVinSlice";
import Button from "../../Button";
import { useNavigate } from "react-router-dom";
import {
  getAdTypes,
  getModels,
  translateInTwoLanguages,
} from "../../../../Reducer/api";

import {
  camelToNormal,
  convertDigitsToEnglish,
  findUploads,
} from "../../../../utils/constants";
import Basic from "./Basic";
import Specifications from "./Specifications";
import Features from "./features";
import Price from "./price";
import { getFieldNames } from "./addFormUtils";
import { paths } from "../../../../utils/paths";

export default function AddCar({
  back,
  setPage,
  setData,
  data,
  page,
  adId,
  entityId,
  next,
  adUploaded
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitting = useSelector((state) => state.saveCarWithoutVin.submitting);
  const pageLoading = useSelector((state) => state.withoutVin.loading);
  const error = useSelector((state) => state.saveCarWithoutVin.error);
  const success = useSelector((state) => state.saveCarWithoutVin.success);
  const [loading, setLoading] = useState(false);
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const saveCar = useSelector(
    (state) => state.saveCarWithoutVin.saveCarWithoutVin
  );
  const upload_list = useSelector((state) => state.withoutVin.withoutVin);
  const [selected, setSelected] = useState(
    data?.properties?.features?.anyThingElse
      ? data?.properties?.features?.anyThingElse?.split(",")
      : []
  );
  const updateAdId = data?._id;
  const entityUploads = data?.uploads ? data?.uploads.map((v) => v?.s3URL) : [];
  const [uploadsInOrder, setUploadsInOrder] = useState([]);
  const [inputFile, setInputFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(entityUploads);
  const [InputFileError, setInputFileError] = useState();
  const require = t("This field is requried");
  const price = t("Must be more than 1$");
  const currData = data;

  useEffect(() => {
    if (data) {
      if (back == false) {
        document.title =
          t("Editing") +
          " - " +
          t(data?.properties?.basicDetails?.make?.[lang2]) +
          " " +
          t(data?.properties?.basicDetails?.model?.[lang2]) +
          " " +
          data?.properties?.basicDetails?.makeYear;
      }

      const all_list = findUploads(data);
      setUploadsInOrder(all_list?.map((v, i) => ({ ...v, id: i + 1 })));
    }
  }, [data]);

  useEffect(() => {
    dispatch(fetchWithoutVinForm());
  }, [dispatch]);

  useEffect(() => {
    // if (pageLoading) {
    setValue("make", data?.makeName ? t(data?.makeName) : t("Select"));
    setValue(
      "model",
      data?.properties?.basicDetails?.model?.[lang2]
        ? t(data?.properties?.basicDetails?.model?.[lang2])
        : t("Select")
    );
    setValue(
      "makeYear",
      data?.properties?.basicDetails?.makeYear
        ? t(data?.properties?.basicDetails?.makeYear)
        : t("Select")
    );
    setValue(
      "regional",
      data?.properties?.basicDetails?.regionalSpecs?.["en"]
        ? t(data?.properties?.basicDetails?.regionalSpecs?.["en"])
        : t("Select")
    );
    setValue(
      "condition",
      data?.properties?.basicDetails?.condition?.[lang2]
        ? t(data?.properties?.basicDetails?.condition?.[lang2])
        : t("Select")
    );
    setValue(
      "colour",
      data?.properties?.basicDetails?.color
        ? t(data?.properties?.basicDetails?.color)
        : t("Select")
    );
    setValue(
      "paint",
      data?.properties?.specifications?.paint?.[lang2]
        ? t(data?.properties?.specifications?.paint?.[lang2])
        : t("Select")
    );
    setValue(
      "transmission",
      data?.properties?.specifications?.gearbox?.[lang2]
        ? t(data?.properties?.specifications?.gearbox?.[lang2])
        : t("Select")
    );
    setValue(
      "fuel",
      data?.properties?.specifications?.fuelType?.[lang2]
        ? t(data?.properties?.specifications?.fuelType?.[lang2])
        : t("Select")
    );
    setValue(
      "body",
      data?.properties?.specifications?.bodyType?.["en"]
        ? t(data?.properties?.specifications?.bodyType?.["en"])
        : t("Select")
    );
    setValue(
      "kilometers",
      data?.properties?.specifications?.km == 0
        ? "0"
        : data?.properties?.specifications?.km
        ? t(data?.properties?.specifications?.km)
        : ""
    );

    setValue(
      "size",
      data?.properties?.specifications?.engineSize
        ? upload_list?.size?.filter(
            (v) => v.value == data?.properties?.specifications?.engineSize
          )?.[0]?.name?.[lang2]
        : t("Select")
    );
    // setValue(
    //   "size",
    //   data?.properties?.specifications?.engineSize
    //     ? data?.properties?.specifications?.engineSize
    //     : ""
    // );
    // setValue(
    //   "power",
    //   data?.properties?.specifications?.enginePower
    //     ? upload_list?.power?.filter(
    //         (v) => v.value == data?.properties?.specifications?.enginePower
    //       )?.[0]?.name?.[lang2]
    //     : t("Select")
    // );
    setValue(
      "power",
      data?.properties?.specifications?.enginePower
        ? data?.properties?.specifications?.enginePower
        : ""
    );
    setValue(
      "cylinders",
      data?.properties?.specifications?.cylinders
        ? t(data?.properties?.specifications?.cylinders)
        : t("Select")
    );
    setValue(
      "doors",
      data?.properties?.specifications?.doors
        ? t(data?.properties?.specifications?.doors)
        : t("Select")
    );
    setValue(
      "seats",
      data?.properties?.specifications?.seats
        ? t(data?.properties?.specifications?.seats)
        : t("Select")
    );
    // setValue(
    //   "feature",
    //   data?.properties?.features?.anyThingElse
    //     ? t(data?.properties?.features?.anyThingElse?.split(","))
    //     : []
    // );
    setValue(
      "description",
      data?.properties?.features?.carDescription?.[lang2]
        ? t(data?.properties?.features?.carDescription?.[lang2])
        : ""
    );
    // setValue(
    //   "city",
    //   data?.properties?.city?.[language]
    //     ? t(data?.properties?.city?.[language])
    //     : t("Select")
    // );
    setValue(
      "registrationNumber",
      data?.properties?.registrationNumber
        ? t(data?.properties?.registrationNumber)
        : ""
    );
    setValue(
      "finance",
      data?.properties?.finance == "Yes"
        ? t("Yes")
        : data?.properties?.finance == "No"
        ? t("No")
        : t("Select")
    );
    setValue(
      "adLabel",
      data?.properties?.adLabel ? data?.properties?.adLabel : ""
    );

    setValue(
      "brandNew",
      data?.brandNew == true
        ? t("Yes")
        : data?.brandNew == false
        ? t("No")
        : t("Select")
    );

    if (page === "RentYourCar") {
      setValue(
        "daily_price",
        data?.askPrice?.toString() == "0" ? "" : data?.askPrice?.toString()
      );
      setValue(
        "dollorDaily",
        data?.dollorAskPrice?.toString() == "0"
          ? ""
          : data?.dollorAskPrice?.toString()
      );
      setValue("asking_price", "");
      setValue("dollorPrice", "");

      setValue(
        "weekly_price",
        data?.askPrice1?.toString() == "0" ? "" : data?.askPrice1?.toString()
      );
      setValue(
        "monthly_price",
        data?.askPrice2?.toString() == "0" ? "" : data?.askPrice2?.toString()
      );
      setValue(
        "dollorWeekly",
        data?.dollorAskPrice1?.toString() == "0"
          ? ""
          : data?.dollorAskPrice1?.toString()
      );
      setValue(
        "dollorMonthly",
        data?.dollorAskPrice2?.toString() == "0"
          ? ""
          : data?.dollorAskPrice2?.toString()
      );
    } else {
      setValue(
        "asking_price",
        data?.askPrice?.toString() == "0" ? "" : data?.askPrice?.toString()
      );
      setValue(
        "dollorPrice",
        data?.dollorAskPrice?.toString() == "0"
          ? ""
          : data?.dollorAskPrice?.toString()
      );
      setValue("daily_price", "");
      setValue("dollorDaily", "");

      setValue("weekly_price", "");
      setValue("monthly_price", "");
      setValue("dollorWeekly", "");
      setValue("dollorMonthly", "");
    }

    // setValue(
    //   "dateOfRegistration",
    //   data?.properties?.dateOfRegistration
    //     ? new Date(moment.unix(data?.properties?.dateOfRegistration))
    //     : ""
    // );

    setValue(
      "make",
      data?.properties?.basicDetails?.make?.[lang2]
        ? t(data?.properties?.basicDetails?.make?.[lang2])
        : t("Select")
    );
    // }
  }, [pageLoading]);
  const schema = Yup.object().shape({
    make: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    model: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    makeYear: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    regional: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    condition: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    colour: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    paint: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    transmission: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    fuel: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    body: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    kilometers: Yup.string().required(require),
    size: Yup.string(),
    power: Yup.string(),
    cylinders: Yup.string(),

    batterySize: Yup.string(),

    doors: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    seats: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    brandNew: Yup.string()
      .test("notChoose", require, (v) => v !== t("Select"))
      .required(require),
    // feature: Yup.array().of(Yup.string().required(require)),
    description: Yup.string(),
    city: Yup.string()
      .required(require)
      .test("notChoose", require, (v) => v !== t("Select")),
    registrationNumber: Yup.string(),
    plateType: Yup.string(),
    dateOfRegistration: Yup.string(),
    finance: Yup.string(),
    adLabel: Yup.string(),

    dollorPrice: Yup.string(),
    dollorDaily: Yup.string(),
    dollorWeekly: Yup.string(),
    dollorMonthly: Yup.string(),

    asking_price:
      page == "RentYourCar"
        ? Yup.string()
        : Yup.string()
            .required(require)
            .test(
              "minPrice",
              price,
              (v) => parseInt(convertDigitsToEnglish(v)) > 1
            ),
    daily_price:
      page !== "RentYourCar"
        ? Yup.string()
        : Yup.string()
            .required(require)
            .test(
              "minPrice",
              price,
              (v) => parseInt(convertDigitsToEnglish(v)) > 1
            ),
    weekly_price:
      page !== "RentYourCar"
        ? Yup.string()
        : Yup.string()
            .required(require)
            .test(
              "minPrice",
              price,
              (v) => parseInt(convertDigitsToEnglish(v)) > 1
            ),
    monthly_price:
      page !== "RentYourCar"
        ? Yup.string()
        : Yup.string()
            .required(require)
            .test(
              "minPrice",
              price,
              (v) => parseInt(convertDigitsToEnglish(v)) > 1
            ),
  });

  const {
    register,
    getValues,
    trigger,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onError = async (errors) => {
    console.log(errors);
  };

  const getMultilingualObject = (objectName, value) => {
    const objData = upload_list[objectName];
    // const value = t(val, { lng: 'English', returnObjects })

    const selectedObject = objData?.filter(({ name }) => {
      return (
        t(name["en"]) === value ||
        t(name["English"]) === value ||
        t(name["ar"]) === value ||
        t(name["Arabic"]) === value ||
        t(name["ku"]) === value ||
        t(name["Kurdish"]) === value
      );
    })[0];
    return selectedObject?.name;
  };
  const onSubmit = async (data) => {
    data.make = camelToNormal(data?.make?.toLowerCase());

    if (!back && !uploadsInOrder?.length) {
      setInputFileError(t("File is required"));
      return;
    }

    if (!back && uploadsInOrder.length < 4) {
      setInputFileError(t("Please upload minimum four images"));
      return;
    }

    setLoading(true);

    if (!submitting) {
      if (!back) setInputFileError("");

      console.log(data, "data as in form");

      const allLangDescriptions = await translateInTwoLanguages(
        lang2,
        data.description
      );
      const bodyTypeData = upload_list.body;
      const selectedBodyType = bodyTypeData.filter(
        ({ name }) => name?.[lang2] === data?.body
      )[0];
      console.log(bodyTypeData);
      console.log(selectedBodyType);

      const selectedModel = models?.filter(
        (v) => t(v.value)?.toLowerCase() === t(data.model)?.toLowerCase()
      )?.[0];

      const makeInAllLang = {
        en: selectedMake?.name,
        ar: selectedMake?.name,
        ku: selectedMake?.name,
      };

      const modelInAllLang = {
        en: selectedModel?.label,
        ar: selectedModel?.label,
        ku: selectedModel?.label,
      };

      const adTypes = await getAdTypes();

      const ids = {
        categoryId: selectedBodyType?.categoryId,
        subcategoryId: selectedBodyType?.subcategoryId,
        entityTypeId: selectedBodyType?.id,
        adTypeId:
          page === "RentYourCar"
            ? adTypes?.data?.[1]?._id
            : adTypes?.data?.[0]?._id,
        makeId: selectedMake?._id,
      };

      const city = cities.filter(
        (v) => t(v?.name?.[lang2]) === t(getValues("city"))
      )?.[0]?.name;

      let features = {};
      upload_list.features.forEach((el) => {
        features[`${el?.value}`] = data?.[`${el?.value}`] || false;
      });

      let tags = [];
      Object.keys(features).forEach((key) => {
        if (data?.properties?.features[key] && key !== "anyThingElse") {
          tags.push(camelToNormal(key));
        }
      });
      tags = [...tags, ...selected];
      const fullData = {
        ...ids,
        makeName: selectedMake?.name,
        adTitle: {
          en: selectedMake?.name,
          ar: selectedMake?.name,
          ku: selectedMake?.name,
        },
        city: city?.en,
        country: {
          en: "Iraq",
          ar: "العراق",
          ku: "عێراق",
        },
        latitude: "32",
        longitude: "43",

        askPrice:
          page == "RentYourCar"
            ? parseInt(convertDigitsToEnglish(data?.daily_price))
            : parseInt(convertDigitsToEnglish(data?.asking_price)),
        askPrice1: data?.weekly_price
          ? parseInt(convertDigitsToEnglish(data?.weekly_price))
          : "",
        askPrice2: data?.monthly_price
          ? parseInt(convertDigitsToEnglish(data?.monthly_price))
          : "",

        dollorAskPrice:
          page == "RentYourCar"
            ? data?.dollorDaily
              ? parseInt(convertDigitsToEnglish(data?.dollorDaily))
              : "0"
            : data?.dollorPrice
            ? parseInt(convertDigitsToEnglish(data?.dollorPrice))
            : "0",
        dollorAskPrice1: data?.dollorWeekly
          ? parseInt(convertDigitsToEnglish(data?.dollorWeekly))
          : "0",
        dollorAskPrice2: data?.dollorMonthly
          ? parseInt(convertDigitsToEnglish(data?.dollorMonthly))
          : "0",

        currency: "IQD",
        brandNew: data?.brandNew === t("Yes") ? "ON" : "OFF",
        tags: tags,

        adDescription: {
          en: allLangDescriptions["en"],
          ar: allLangDescriptions["ar"],
          ku: allLangDescriptions["ku"],
        },
        // description: {
        //   en: allLangDescriptions["en"],
        //   ar: allLangDescriptions["ar"],
        //   ku: allLangDescriptions["ku"],
        // },

        isAdImageUploaded: uploadsInOrder.length > 0 ? "YES" : "NO",

        properties: {
          basicDetails: {
            make: makeInAllLang,
            makeYear: parseInt(data.makeYear),
            model: modelInAllLang,
            regionalSpecs: getMultilingualObject("regional", data.regional),
            condition: getMultilingualObject("condition", data.condition),
            color: getMultilingualObject("colour", data?.colour)?.["en"],
          },
          specifications: {
            paint: getMultilingualObject("paint", data.paint),
            km: parseInt(convertDigitsToEnglish(data.kilometers)),
            gearbox: getMultilingualObject("transmission", data.transmission),
            fuelType: getMultilingualObject("fuel", data.fuel),
            bodyType: getMultilingualObject("body", data.body),
            engineSize: upload_list["size"]?.filter(
              (v) => v.name?.[lang2] === data.size
            )[0]?.value,
            // engineSize:  parseFloat(parseFloat(convertDigitsToEnglish(data.size)).toFixed(1)),
            // enginePower: parseInt(upload_list["power"]?.filter(
            //   (v) => v.name?.[lang2] === data.power
            // )[0]?.value),
            enginePower: parseInt(convertDigitsToEnglish(data.power)),
            batterySize: parseInt(convertDigitsToEnglish(data.batterySize)),
            cylinders: parseInt(data.cylinders),
            doors: parseInt(data.doors),
            seats: parseInt(data.seats),
          },
          features: {
            ...features,
            carDescription: {
              en: allLangDescriptions["en"],
              ar: allLangDescriptions["ar"],
              ku: allLangDescriptions["ku"],
            },
          },

          price:
            page == "RentYourCar"
              ? {
                  daily: parseInt(convertDigitsToEnglish(data.daily_price)),
                  weekly: parseInt(convertDigitsToEnglish(data.weekly_price)),
                  monthly: parseInt(convertDigitsToEnglish(data.monthly_price)),
                }
              : parseInt(convertDigitsToEnglish(data.asking_price)),

          finance: data?.finance === t("Yes") ? "Yes" : "No",
          registrationNumber: data.registrationNumber,
          plateType: data.plateType === t("Select") ? "" : data.plateType,
          brandNew: data?.brandNew === t("Yes") ? "Yes" : "No",

          adLabel: data?.adLabel,
        },

        uploadsInOrder: {
          uploads: uploadsInOrder.map(({ id, ...v }) => v),
        },
      };

      if (page === "RentYourCar") delete fullData.properties.finance;

      console.log(fullData, "my formatted data");
      try {
        dispatch(
          uploadWithoutVinForm({
            formData: fullData,
            add: !back || back == "WithoutAdvert" || adUploaded ? false : true,
            adId: adId || updateAdId,
            entityId: entityId,
            setLoading: setLoading,
          })
        );
      } catch (e) {
        toast.error(e.message);
      }
      if (!back && !currData?.isAdCompleted) {
        navigate(paths.renew, {
          state: {
            page: page,
            item: currData,
            isUpdate: true,
            isRenew: true,

            // updateApiData: {
            //   formData: fullData,
            //   add: !back || back == "WithoutAdvert" ? false : true,
            //   adId: adId || updateAdId,
            //   entityId: entityId,
            // },
          },
        });
      }
    }
  };

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error(t(errorSms.first));
        break;
    }
  }
  if (success == true && back != false) {
    if (saveCar) {
      let saveData = { ...saveCar?.data };
      if (saveCar?.adId) {
        saveData._id = saveCar?.adId;
      }
      setData(saveData);
      setPage(next);
      dispatch(clearStatus());
    }
  }
  if (success == true && back == false) {
    // setPage("WithoutAdvert");
    // let saveData = { ...saveCar?.data}
    // saveData['base64'] = base64
    // setData(saveData);
    dispatch(clearStatus());
    navigate(paths.myVehicles);
  }
  console.log(back);
  const [selectedMake, setSelectedMake] = useState();
  const [models, setModels] = useState([]);
  useEffect(() => {
    if (upload_list?.make) {
      setSelectedMake(
        upload_list?.make?.filter((v) => {
          return (
            v?.name?.toLowerCase?.() ==
            data?.properties?.basicDetails?.make?.["en"]?.toLowerCase()
          );
        })?.[0]
      );
    }
  }, [upload_list]);

  useEffect(() => {
    if (selectedMake?.name) {
      setModels([]);
      getModels({ makeId: selectedMake?.name }).then((res) => {
        const hasModel = res?.includes(
          data?.properties?.basicDetails?.model?.["en"]
        );
        res = res?.map((v) => ({ label: v, value: v }));
        setModels(res);

        if (hasModel) {
          setValue(
            "model",
            data?.properties?.basicDetails?.model?.[lang2]
              ? t(data?.properties?.basicDetails?.model?.[lang2])
              : t("Select")
          );
        } else {
          console.log("nahi mila");
          setValue("model", t("Select"));
        }
      });
    }
  }, [selectedMake]);

  const [formStep, setFormStep] = useState(1); // add car form stepper (this stepper was an after thought)
  const getContent = () => {
    switch (formStep) {
      case 1:
        return (
          <Basic
            data={data}
            selectedMake={selectedMake}
            setSelectedMake={setSelectedMake}
            register={register}
            errors={errors}
            models={models}
            setValue={setValue}
            getValues={getValues}
          />
        );
      case 2:
        return (
          <Specifications
            data={data}
            register={register}
            errors={errors}
            watch={watch}
          />
        );
      case 3:
        return (
          <Features
            data={data}
            register={register}
            errors={errors}
            selected={selected}
            setSelected={setSelected}
          />
        );
      case 4:
        return (
          <Price
            data={data}
            register={register}
            errors={errors}
            page={page}
            back={back}
            inputFile={inputFile}
            setInputFile={setInputFile}
            InputFileError={InputFileError}
            setInputFileError={setInputFileError}
            uploadsInOrder={uploadsInOrder}
            setUploadsInOrder={setUploadsInOrder}
            uploading={uploading}
            setUploading={setUploading}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            watch={watch}
            setValue={setValue}
          />
        );
    }
  };

  const handleStepNext = (e) => {
    e.preventDefault();
    trigger(getFieldNames(formStep)).then((res) => {
      if (res) {
        setFormStep(formStep + 1);
      }
    });
  };

  if (pageLoading) {
    return (
      <div className="min-vh-100 d-flex justify-content-center mt-5 pt-5">
        <div className="d-flex justify-content-center">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h6 class="back">
        <a
          onClick={() => (back ? setPage(back) : navigate(paths.myVehicles))}
          className="text"
        >
          <img src="../assets/images/backbt.png" /> {t("Go back")}
        </a>
      </h6>
      {!back ? (
        <>
          <h2>{t("Edit your car details")}</h2>
          <div class="editing" style={{ minHeight: "68px", height: "unset" }}>
            <h6>
              {t("Editing")}{" "}
              <span>
                {t(data?.properties?.basicDetails?.make?.[lang2]) +
                  " " +
                  t(data?.properties?.basicDetails?.model?.[lang2]) +
                  " " +
                  data?.properties?.basicDetails?.makeYear}
              </span>
            </h6>
          </div>
        </>
      ) : (
        ""
      )}

      {back != false && data?.length == 0 ? (
        <h6 class="mt-5 withoutVinTitle">
          {t("We couldn’t find your car details, try adding details manually.")}
        </h6>
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {getContent()}

        {formStep < 4 ? (
          <div class="text-center">
            {formStep !== 1 && (
              <Button
                style="save_continue mx-1"
                type="button"
                disabled={loading}
                title={t("Previous")}
                onClick={() => setFormStep(formStep - 1)}
              />
            )}
            <Button
              style="save_continue mx-1"
              type="button"
              disabled={loading}
              title={t("Next")}
              onClick={(e) => handleStepNext(e)}
            />
          </div>
        ) : (
          <div class="text-center">
            {formStep !== 1 && (
              <Button
                style="save_continue mx-1"
                type="button"
                disabled={loading}
                title={t("Previous")}
                onClick={() => setFormStep(formStep - 1)}
              />
            )}
            <Button
              style="save_continue mx-1"
              type="submit"
              disabled={loading}
              title={
                loading
                  ? t("Please wait...")
                  : back == false
                  ? t("Save Changes")
                  : t("Save & Continue")
              }
            />
          </div>
        )}
      </form>
    </>
  );
}
