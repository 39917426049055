import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSingleCar } from './api';

export const fetchCarDetails = createAsyncThunk('singleCar/fetchCarDetails', async (formData, { rejectWithValue }) => {
  try {
    const searched = await fetchSingleCar(formData, rejectWithValue);
    return searched;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const singleCarSlice = createSlice({
  name: 'singleCar',
  initialState: {
    detail: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCarDetails.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCarDetails.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchCarDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default singleCarSlice.reducer;
