import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDefaultSearch, fetchFilteredSearchResults, fetchSingleSearching } from './api';

export const fetchDefaultFilterData = createAsyncThunk('searching/defaultSearch', async (formData, { rejectWithValue }) => {
  try {
    // const searched = await fetchDefaultSearch(formData);
    const searched = await fetchFilteredSearchResults(formData);
    return searched;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchSingleSearch = createAsyncThunk('searching/singleSearch', async (formData, { rejectWithValue }) => {
  try {
    const selectedSearch = await fetchSingleSearching(formData);
    return selectedSearch;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const searchFilterSlice = createSlice({
  name: 'searching',
  initialState: {
    search: [],
    allAdIds: [],
    sideBar:{},
    totalPage:1,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDefaultFilterData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDefaultFilterData.fulfilled, (state, action) => {
        // state.sideBar = action.payload.sideBar ? action.payload.sideBar :[];
        state.search=action.payload.data ? action.payload.data :[];
        state.totalPage=action.payload.totalPage ? action.payload.totalPage :1;
        state.allAdIds=action.payload.allAdIds ? action.payload.allAdIds :1;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchDefaultFilterData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleSearch.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleSearch.fulfilled, (state, action) => {
        state.search=action.payload.data ? action.payload.data :[];
        state.totalPage=action.payload.totalPage ? action.payload.totalPage :1;
        state.allAdIds=action.payload.allAdIds ? action.payload.allAdIds :1;
        state.loading = false;
        state.error = null;
      }).addCase(fetchSingleSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default searchFilterSlice.reducer;
