import React from "react";
import { getSiteContent } from "../../Reducer/api";
import i18n from "../../i18n";
import { useEffect } from "react";
import { useState } from "react";
import { marked } from "marked";
import { t } from "i18next";
import { useSelector } from "react-redux";

export default function CareersComponent() {
  const language = useSelector((state) => state.auth.language);

  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [data, setData] = useState(null);

  useEffect(() => {
    getSiteContent('careers').then((res) => {
      res.json().then((r) => {
        setData(r?.data?.[0]?.attributes);
      });
    });
  }, []);


  marked.setOptions({
    gfm: true,
    highlight: true,
    tables: false,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false
  });


  // const myString = data ? (data?.[lang].replace(/[\r\n]/g, "<br /> ")) : t("Please wait...")
  return (
    <div class="staticConditions container my-5 min-vh-100">
      {/* <p>
        <p style={{ textAlign: "center" }}>
          <strong style={{ fontSize: "30px" }}>Careers</strong>
        </p>
        <p>Powered by tech made possible by people.</p>
        <p>
          We're proud of our people-centred culture which, combined with our
          tech and data expertise, makes us one of Iraq’s leading companies.
        </p>
        <p> Get in touch.</p>
        <p>Contact the recruitment team,</p>
        <p>033933939</p>
        <p>Email us at: <a href="">recruitment@autotrader.co.uk</a></p>
        <p>
          89% of our people feel proud to work for Ako Sayara and 86% say they
          would recommend us as a great place to work.
        </p>
        <p>
          Ako Sayara site across the technology, automotive and advertising
          industries and is a major consumer brand. We are:
        </p>
        <p>
          Data-led: Data, and the insight derived from it, power everything that
          we do from new products and development to enhancements to search and
          advertising. Our colleagues are organised into high-performing,
          data-oriented squads to ensure we are agile and quick to respond to
          change.
        </p>
        <p>
          Inclusive: Ensuring Ako Sayara is a diverse, inclusive and conscious
          employer that contributes positively to its communities is a key
          strategic priority for our business.
        </p>
        <p>
          One team: We have built a simple, lean and relatively flat-structured
          organisation, and we value our people and their opinions. We host an
          annual employee conference to share ideas, bringing colleagues
          together from Baghdad, Erbil, Basra and those based in the field
          around Iraq.
        </p>
      </p> */}
      <p
        dangerouslySetInnerHTML={{
          __html: marked.parse(data?.[lang] || t("Please wait...")),
        }}
      ></p>

      {/* {data.map((item) => {
        return (
          <div className="container">
            <h4>{item.title[lang]}</h4>
            <p className="text-center">{item.description[lang]}</p>
          </div>
        );
      })} */}
    </div>
  );
}
