import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiManager } from "../../../api/connectAPI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { vehicleMy } from "../../../Reducer/myvehicleSlice";
import { Nav, NavItem } from "reactstrap";
export default function SoldModal({ handleCloseModal, modalData, refresh }) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const [buyers, setBuyers] = useState(null);
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [type, setType] = useState("ako");

  const handleChangeRadio = (e) => {
    setType(e.target.value);
  };

  useEffect(() => {
    getBuyerList(modalData?._id);
  }, []);

  const getBuyerList = (adId) => {
    apiManager("GET", `chat/list?lang=${lang}&adId=${adId}`)
      .then((res) => {
        if (res.status) {
          setBuyers(res?.chats?.docs);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  };

  const markSold = () => {
    setLoading(true);
    let data = {
      adId: modalData?._id,
      soldWithinPlatform: type === "ako" ? true : false,
    };

    if (type === "ako") {
      data = { ...data, soldToUserId: selectedBuyer };
    }
    // refresh();
    // handleCloseModal();
    apiManager("PUT", `opp/ad/sold?lang=${lang}`, data)
      .then((res) => {
        if (res.status) {
          // toast.success(res.message);
          console.log(res);
          refresh();
          handleCloseModal();
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        setLoading(false);
      });
  };

  return (
    <div className="p-2">
      <form class="mb-2">
        <div class="d-flex justify-content-between p-2">
          <h5 class="modal-title" id={`exampleModalLabel${modalData?._id}`}>
            {t("Mark")} {t(modalData?.properties?.basicDetails?.make?.[lang])}{" "}
            {t(modalData?.properties?.basicDetails?.model?.[lang])}{" "}
            {t("as sold")}
          </h5>
          <a
            onClick={() => handleCloseModal()}
            type="button"
            style={{ backgroundColor: "transparent" }}
            class="stclose"
            // data-bs-dismiss="modal"
            // aria-label="Close"
          >
            <img src="../assets/images/close_img.png" alt="img" height={18} />{" "}
          </a>
        </div>
        <div class="modal-body" style={{ minHeight: "170px" }}>
          {/* <div className=" d-flex gap-2" style={{ marginInline: "0px" }}>
            <div className={`col-sm-6 my-2 border rounded-2 py-2 ${type === 'ako' ? "bg-info text-white" : ""}`}>
              <label
                htmlFor="ako"
                className="d-flex justify-content-center"
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  checked={type == "ako"}
                  id="ako"
                  name="sellRadio"
                  value={"ako"}
                  onChange={handleChangeRadio}
                  hidden
                />
                {t("Within Ako Sayara")}
              </label>
            </div>
            <div className={`col-sm-6 my-2 border rounded-2 py-2 ${type === 'out' ? "bg-info text-white" : ""}`}>
              <label
                htmlFor="out"
                className="d-flex justify-content-center"
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  checked={type == "out"}
                  id="out"
                  name="sellRadio"
                  value={"out"}
                  onChange={handleChangeRadio}
                  hidden
                />
                {t("Outside Ako Sayara")}
              </label>
            </div>
          </div> */}

          <Nav
            className="custom-tabs row"
            style={{ marginInline: "unset" }}
            pills
            fill
          >
            <NavItem
              role="button"
              className="col-4"
              active={type == "ako"}
              onClick={() => setType("ako")}
            >
              {t("Within Ako Sayara")}
            </NavItem>
            <NavItem
              role="button"
              className="col-4"
              active={type == "out"}
              onClick={() => setType("out")}
            >
              {t("Outside Ako Sayara")}
            </NavItem>
          </Nav>

          {type == "ako" ? (
            <>
              <label>{t("Select Buyer")}*</label>
              <select
                class="form-control"
                onChange={(e) => setSelectedBuyer(e.target.value)}
              >
                <option selected disabled hidden>
                  {t("Select")}
                </option>
                <option  value={""}>{t("No Buyers")}</option>
                {buyers?.map((item, key) => {
                  return (
                    <option value={item?.buyerId}>{item?.buyerName}</option>
                  );
                })}
              </select>
              {/* <select
                class="form-control"
                onChange={(e) => setSelectedBuyer(e.target.value)}
              >
                <option selected disabled hidden>
                  {t("Select")}
                </option>
                {buyers?.length != 0 ? (
                  buyers?.map((item, key) => {
                    return (
                      <option value={item?.buyerId}>{item?.buyerName}</option>
                    );
                  })
                ) : (
                  <option disabled>{t("No buyers")}</option>
                )}
              </select> */}
            </>
          ) : (
            <h5
              className="mt-5 text-center"
              style={{ fontSize: "18px", fontWeight: 500, color: "#0c0c0c" }}
            >
              {t("Mark as Sold") + " " + t("Outside Ako Sayara")}
            </h5>
          )}
        </div>
        <div
          class="d-flex justify-content-end gap-3"
          style={{ padding: "16px" }}
        >
          <button
            type="button"
            class="btn btn-secondary"
            // data-bs-dismiss="modal"
            onClick={() => handleCloseModal()}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            disabled={loading}
            onClick={() => markSold()}
            class="btn btn-success border-0"
            style={{ backgroundColor: "#2da84f" }}
          >
            {loading
              ? t("Please wait...")
              : selectedBuyer?.length === 0 && type === "ako"
              ? t("Sell to anonymous buyer")
              : t("Mark as Sold")}
          </button>
        </div>
      </form>
    </div>
  );
}
