import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import { camelToNormal, getNameFromVal } from "../../../utils/constants";
import withoutVin from "../../../data/withoutVin.json";
import { apiManager } from "../../../api/connectAPI";

export default function Carview({ data }) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const [currMake, setCurrMake] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    apiManager(
      "GET",
      `general/makes?lang=en&name=${data?.properties?.basicDetails?.make?.["en"]}`
    )
      .then((res) => {
        if (res?.status) {
          setCurrMake(res?.data?.makes?.[0]);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, [data]);

  // console.log(data?.properties?.basicDetails?.make?.["en"]);

  return (
    <>
      <div className="row">
        <div class="col-lg-6  d-flex justify-content-center align-items-center">
          {loader ? (
            <div
              class="spinner-border"
              style={{ width: "5rem", height: "5rem", color: "#606060" }}
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <img
              class="viewimg rounded-1 sellbrandIcon"
              src={currMake?.image ? currMake?.image : "../assets/images/notFound.png"}
              alt="bg"
            />
          )}
        </div>
        <div class="col-lg-6 col-xxl-5">
          <h3>
            {t(currMake?.name) +
              " " +
              t(data?.properties?.basicDetails?.model?.["en"])}
          </h3>
          {data?.properties?.specifications?.bodyType?.[lang] && (
            <span className="row my-2">
              <span className="col-7">{t("Body Type")} </span>
              <span className="col-5 fw-semibold">
                {t(data?.properties?.specifications?.bodyType?.[lang])
                  ? t(data?.properties?.specifications?.bodyType?.[lang])
                  : "--"}
              </span>
            </span>
          )}
          <span className="row my-2">
            <span className="col-7">{t("Year")} </span>
            <span className="col-5 fw-semibold">
              {t(data?.properties?.basicDetails?.makeYear)
                ? t(data?.properties?.basicDetails?.makeYear)
                : "--"}
            </span>
          </span>

          {data?.properties?.specifications?.engineSize && (
            <span className="row my-2">
              <span className="col-7">{t("Engine Size")} </span>
              <span className="col-5 fw-semibold">
                {t(data?.properties?.specifications?.engineSize)
                  ? t(
                      data?.properties?.specifications?.engineSize
                        ? getNameFromVal(
                            "size",
                            data?.properties?.specifications?.engineSize,
                            lang
                          )
                        : "--"
                    )
                  : "--"}
              </span>
            </span>
          )}

          {data?.properties?.specifications?.cylinders && (
            <span className="row my-2">
              <span className="col-7">{t("Cylinders")} </span>
              <span className="col-5 fw-semibold">
                {t(data?.properties?.specifications?.cylinders)
                  ? t(data?.properties?.specifications?.cylinders)
                  : "--"}
              </span>
            </span>
          )}
          {data?.properties?.specifications?.seats && (
            <span className="row my-2">
              <span className="col-7">{t("Seats")} </span>
              <span className="col-5 fw-semibold">
                {t(data?.properties?.specifications?.seats)
                  ? t(data?.properties?.specifications?.seats)
                  : "--"}
              </span>
            </span>
          )}
          {/* <p>
            {t("Colour")} <span>{data?.color}</span>
          </p> */}
          {/* <p>
          {t("Date of first registration")}
          <span>{dateString}</span>
        </p> */}
          {/* <a className="text-primary" onClick={() => setModal(true)}>
            {t("View detail")}
          </a> */}
        </div>
      </div>
      {/* <Modal
        isOpen={modal}
        toggle={handleCloseModal}
        centered
        style={{ zIndex: 100 }}
      >
        <ModalHeader>
          <h4>{data?.make + " " + data?.model}</h4>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {Object.keys(data).map((item) => {
              if (!constructValue(data?.[item])) {
                return null;
              }

              return (
                <p className="row">
                  <span className="col">{t(camelToNormal(item))} </span>
                  <span className="col text-end fw-semibold">
                    {constructValue(data?.[item])}
                  </span>
                </p>
              );
            })}
          </div>
        </ModalBody>
      </Modal> */}
    </>
  );
}
