import { Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { paths } from './utils/paths';

const AuthRoute = ({ element }) => {
  const isAuthenticated = useSelector(state => state.auth.token);

  return isAuthenticated!="" ? (
    element
  ) : (
    <Navigate to={paths.login} replace />
  );
};

export default AuthRoute;

