import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function GeneralDetails({ details, planData }) {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  console.log(planData);
  const { t, i18n } = useTranslation();
  return (
    <div class="col-lg-12">
      <div class=" mb-3">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <td className="border-bottom-0">{t("Check my car price")}</td>
                <td
                  className={`border-bottom-0 ${
                    lang !== "en" ? "text-start" : ""
                  }`}
                >
                  {planData?.price ? (
                    <b>
                      ${(planData?.price - planData?.price * 0.15).toFixed(2)}
                    </b>
                  ) : (
                    <b>--</b>
                  )}
                </td>
              </tr>
              <tr>
                <td>{t("Tax") + " (15%) "}</td>
                <td className={`${lang !== "en" ? "text-start" : ""}`}>
                  {planData?.price ? (
                    <b>${(planData?.price * 0.15).toFixed(2)}</b>
                  ) : (
                    <b>--</b>
                  )}
                </td>
              </tr>
              <tr>
                <td>{t("TOTAL")}</td>
                <td className={`tb-cl ${lang !== "en" ? "text-start" : ""}`}>
                  {planData?.price ? <b>${planData?.price}</b> : <b>--</b>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div>
          <span>
            {t("check_your_car_helper_text_1")}
            <br />
            <br />
            {t("check_your_car_helper_text_2")}
          </span>
          {/* <span>
            Select your subscription plan confidently, with zero risks attached.
            Drive your listing towards success effortlessly.
          </span> */}
        </div>
      </div>
    </div>
  );
}
