import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Button from '../../../Tools/Button';
import FormInput from '../../../Tools/FormInput';
import TextAreaForm from '../../../Tools/TextAreaForm';
import SelectForm from '../../../Tools/SelectForm';
import { saveCarForm,clearStatus } from '../../../../Reducer/saveCarSlice';
import { fetchUploadFormDetails } from '../../../../Reducer/uploadFormSlice';
import SellPrice from '../../Forms/SellPrice';
import RentPrice from '../../Forms/RentPrice';
import Media from '../../Media';
import { errorSms } from '../../../../data/content';
import Carview from '../../CarView';

export default function UploadForm({back,data,setPage,setData,page}) {
   const { t, i18n } = useTranslation();
   const dispatch = useDispatch();
   const submitting = useSelector(state => state.saveCar.submitting);
   const error = useSelector(state => state.saveCar.error);
   const success = useSelector(state => state.saveCar.success);
   const saveCar = useSelector(state => state.saveCar.saveCar);
   const language = useSelector(state => state.auth.language);
   const upload_list = useSelector(state => state.upload_list.upload_list);
   const [submit, setSubmit] = useState(false);
   const [base64, setBase64] = useState(data.all_images);
   const [inputFile, setInputFile] = useState([]);
   const require=t("This field is requried");
   const price =t("Must be more than 1$");
   const [InputFileError, setInputFileError] = useState(t('File is required'));
   const schema = Yup.object().shape({
      body:Yup.string().required(require),
      paint:Yup.string().required(require),
      exchange:Yup.string(),
      colour:Yup.string().required(require),
      kilometers:Yup.string().required(require),
      city:Yup.string().required(require),
      description:Yup.string(),
      asking_price:Yup.number().min(1, price),
      dailly_price:Yup.number().min(1, price),
      weekly_price:Yup.number().min(1, price),
      monthly_price:Yup.number().min(1, price),
      finance:Yup.string(),
   });
   const [formData, setFormData] = useState(schema.getDefault());
 

   useEffect(() => {
      dispatch(fetchUploadFormDetails());
   }, [dispatch]);
  
   const { register, handleSubmit, formState: { errors } } = useForm({
      
      resolver: yupResolver(schema),
   });

  
   const onError = (errors) => {
      console.log(errors);
   };
  
   const onSubmit = async(data) => {
      setSubmit(true);
      if(submitting==false && base64.length>0)
      {
         setInputFileError('');
         let formDatas=new FormData();
         formDatas.append('body',data.body);
         formDatas.append('paint',data.paint);
         formDatas.append('colour',data.colour);
         formDatas.append('kilometers',data.kilometers);
         formDatas.append('city',data.city);
         formDatas.append('description',data.description);
         formDatas.append('asking_price',data.asking_price);
         formDatas.append('finance',data.finance);
         formDatas.append('type',page);
         for await (const num of inputFile) {
         formDatas.append('images[]',num);
         }
         try{
            dispatch(saveCarForm(formData));
         }catch(e)
         {
            toast.error(e.message);
         }
      }
   };

   if(error && error.status)
   {
      switch (error.status) {
      case 403:
         toast.error(error.data.message);
         break;
      default:
         toast.error(t(errorSms.first));
         break;
      }
   }


   if(success==true)
   {
      setPage('WithAdvert');
      setData(saveCar.data);
      dispatch(clearStatus());
   }
  return (
    <>
    <h6 class="back"><Button onClick={()=>{setPage(back)}} title={<><img src="assets/images/backbt.png"/> {t('Go back')}</>} /></h6>
    <h6 class="mt-5">{t('We found your car!')}</h6>
    {error && <div>{t('Error')}: {error}</div>}
    {data && Object.keys(data).length!==0 ?
    <form onSubmit={handleSubmit(onSubmit,onError)}>
        <div class="set">
          <div class="row">
            <Carview data={data} language={language}/>
          </div>
        </div>
       <div class="other_div other_div2">
         <h3>{t('Other Details')}</h3>
         <div class="row">
            <div class="col-sm-6">
               <div class="form-group">
                  <label>{t('Body Condition')}*</label>
                  <SelectForm style={'form-control'} selectedOption={data?.body?.[language]} event={register} name={'body'} values={upload_list.body ? upload_list.body : []}/>
                  {errors.body && <p>{require}</p>}
               </div>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label>{t('Paint')}*</label>
                  <SelectForm style={'form-control'} selectedOption={data?.paint?.[language]} event={register} name={'paint'}  values={upload_list.paint ? upload_list.paint : []}/>
                  {errors.paint && <p>{require}</p>}
               </div>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label>{t('Colour')}*</label>
                  <SelectForm style={'form-control'} selectedOption={data?.colour?.[language]} event={register} name={'colour'} values={upload_list.colour ? upload_list.colour : []}/>
                  {errors.colour && <p>{require}</p>}
               </div>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label>{t('Kilometers')}*</label>
                 <FormInput style={'form-control'} intype={'number'}  value={data?.kilometers} event={register} name={'kilometers'} placeholder="4000"/>
                 {errors.kilometers && <p>{require}</p>}
               </div>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label>{t('City')}*</label>
                  <SelectForm style={'form-control'} selectedOption={data?.cities?.[language]} event={register} name={'city'} values={upload_list.cities ? upload_list.cities : []}/>
                  {errors.city && <p>{require}</p>}
               </div>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label>{t('Part Exhange')}</label>
                  <SelectForm style={'form-control'} selectedOption={data?.exchange?.[language]}  event={register} name={'exchange'} values={upload_list.exchange ? upload_list.exchange : []}/>
                  {errors.exchange && <p>{require}</p>}
               </div>
            </div>
            <div class="col-sm-12">
               <div class="form-group">
                  <label>{t('Car Description')}</label>
                  <TextAreaForm style="form-control"  event={register} fnType="handleChange"  value={data?.description?.[language]} name={'description'} placeholder={t('Write your description')}/>
                  {errors.description && <p>{require}</p>}
               </div>
            </div> 
         </div>
      </div>
      <Media submit={submit} setInputFileError={setInputFileError} inputFile={inputFile} register={register} InputFileError={InputFileError} base64={base64} setBase64={setBase64} setInputFile={setBase64}/>
         {page=='SellYourCar' && 
         <SellPrice register={register} errors={errors} finance={upload_list.finance ? upload_list.finance : []} data={data} language={language}/>}
         {page=='RentYourCar' && 
         <RentPrice register={register} errors={errors} data={data} />
         }
      <div class="text-center">
         <Button style="save_continue" type="submit" title={t('Save & Continue')} />
         {success && <div>{t('Success')}: {saveCar.message}</div>}
      </div>
   </form>:""}
   </>);
}
