import { t } from "i18next";
import React from "react";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

export default function ShareIcons({ className, type }) {
  // if(type === 'h')
  return (
    <>
      {type === 'h' && <h6 className={` fw-semibold text-center d-block d-lg-none`}>{t("Share")}</h6>}

      <div className={className}>
        <h6 className=" d-none d-lg-block fw-semibold">{t("Share")}</h6>
        <div className="d-flex justify-content-center">
          <a
            style={{ padding: "0px 0px 20px 0px" }}
            // onClick={clickEvent}
            href={`mailto:?body=${window.location.href}`}
          >
            <div className="d-flex justify-content-center">
              <EmailIcon
                className="rounded-5"
                style={{ width: type === 'h' ? "25px" : '40', height: type === 'h' ? "25px" : '40' }}
              />
            </div>
            <span
              style={{
                fontSize: "12px",
                color: "black",
                textAlign: "center",
                display: "block",
                marginTop: "3px",
              }}
            >
              {t("Email")}
            </span>
          </a>
        </div>
        <WhatsappShareButton
          style={{ padding: "0px 0px 20px 0px" }}
          // onClick={clickEvent}
          url={`${window.location.href}`}
        >
          <WhatsappIcon
            className="rounded-5"
            style={{ width: type === 'h' ? "25px" : '40', height: type === 'h' ? "25px" : '40' }}
          />{" "}
          <br /> <span style={{ fontSize: "12px" }}>{"Whatsapp"}</span>
        </WhatsappShareButton>
        <FacebookMessengerShareButton
          style={{ padding: "0px 0px 20px 0px" }}
          // onClick={clickEvent}
          url={`${window.location.href}`}
        >
          <FacebookIcon
            className="rounded-5"
            style={{ width: type === 'h' ? "25px" : '40', height: type === 'h' ? "25px" : '40' }}
          />{" "}
          <br /> <span style={{ fontSize: "12px" }}>{t("Facebook")}</span>
        </FacebookMessengerShareButton>
        <TwitterShareButton
          style={{ padding: "0px 0px 20px 0px" }}
          // onClick={clickEvent}
          url={`${window.location.href}`}
        >
          <XIcon
            className="rounded-5"
            style={{ width: type === 'h' ? "25px" : '40', height: type === 'h' ? "25px" : '40' }}
          />{" "}
          <br /> <span style={{ fontSize: "12px" }}>{"Twitter"}</span>
        </TwitterShareButton>
      </div>
    </>
  );
}
